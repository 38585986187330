import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaCar,FaTruckFront } from "react-icons/fa6";
import { FaMotorcycle } from "react-icons/fa";
import Navbar from '../../Navbar/Navbar'
import '../Insurance_common_css.css'
import advVeh from '../../../../Images/Insurance/advantages-vehicle-insurance-banner.png'
import carInsur from '../../../../Images/Insurance/car-insurance-policies.png'
import bikeInsur from '../../../../Images/Insurance/bike-insurance-policies.png'
import bikeImg from '../../../../Images/Insurance/bike.png'
import Services from '../Insurance_Components/Services'
import CustomerServices from '../Insurance_Components/CustomerServices';
import InsurancePlan from '../Insurance_Components/InsurancePlan';
import Footer from '../../Footer/Footer';
import { apiget } from '../../../../service/api';
import AccordianComponent from '../../HelpCentre/AccordianComponent';


const VehicleInsurance = () => {
   

    const [vehicleInsurance, setVehicleInsurance] = useState([]);
    const [servicePlans, setServicePlans] = useState([]);
    const [faq, setFaq] = useState([]);


    const fetchVehiclePlans = async () => {
        const result = await apiget('health-insurance-plans/list');
        if (result && result.status === 200) {
            const filteredPlans = result.data.result.filter(plan =>
                plan.page && plan.page.toLowerCase().includes("vehicle")
            );
            setVehicleInsurance(filteredPlans);
        }
    }


    const fetchFaq = async () => {
        try {
            const result = await apiget('article/list');
            if (result && result.status === 200) {
                const filteredPlans = result.data.result.filter(plan =>
                    plan.page && plan.page.toLowerCase().includes("vehicle")
                );
                setFaq(filteredPlans);
            }
        } catch (error) {
            console.error("Error fetching service plans:", error);
        }
    }


    const fetchServicePlans = async () => {
        try {
            const result = await apiget('service-plans/list');
            if (result && result.status === 200) {
                const filteredPlans = result.data.result.filter(plan =>
                    plan.page && plan.page.toLowerCase().includes("vehicle")
                );
                setServicePlans(filteredPlans);
            }
        } catch (error) {
            console.error("Error fetching service plans:", error);
        }
    }

    useEffect(() => {
        fetchFaq();
        fetchServicePlans();
        fetchVehiclePlans();
    }, []);


    return (
        <>
            <div className="vehicle-insurance-background-banner">
                <div className="container">
                    <Navbar />
                    <div className="health-Insurance-home-banner-text">
                        <h3>A Life Full Of Care</h3>
                        <h2>Protect Your Ride: Vehicle Insurance Solutions</h2>
                        <p>Welcome to the world of insurance made easy. Compare, Contrast, and confidently select the plan that
                            suits your lifestyle.</p>
                        <div className="health-Insurance-home-banner-btnn">
                            <Link to="/signup">Get Started</Link>
                        </div>
                    </div>
                </div>
            </div>
            <CustomerServices />
            <div className="Vehicle-insurance-right">
                <div className="container">
                    <div className="Vehicle-insurance-background-1">
                        <h2>Vehicle insurance that is right for you.</h2>
                        <div className="car-bike-two">
                            <Link href=""><FaCar /> Car</Link>
                            <Link href=""><FaMotorcycle /> Bike</Link>
                            <Link href=""><FaTruckFront />Commercial</Link>

                        </div>
                        <div className="vehicle-registration-2">
                            <div className="mb-3 text-title-input">
                                <input type="text" className="form-control vehicle-registration-input" id="formGroupExampleInput"
                                    placeholder="Example input placeholder" />
                            </div>
                            <div className="renew-existing-policy-get-price">
                                <Link className="renew-existing-policy" href="">Renew Existing Policy</Link>
                                <Link className="get-price" href="">Get Price</Link>
                            </div>
                            <div className="insure-brand-new-car">
                                <Link href=""><i className="fa fa-car" /> Insure your brand new car <i
                                    className='fa fa-arrow-right' /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Services data={servicePlans} />

            <div className="advantages-vehicle-insurance-background">
                <div className="container">
                    <h2>Advantages of Vehicle Insurance</h2>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="advantages-vehicle-insurance-title">
                                <p>Vehicle insurance offers a multitude of advantages that extend far beyond just financial
                                    protection. Firstly, it provides peace of mind by alleviating the worries associated with
                                    potential accidents or damage to your vehicle. This assurance allows drivers to focus on the
                                    road without the constant fear of unforeseen expenses looming over them. Moreover, vehicle
                                    insurance ensures legal compliance, meeting the mandatory requirements in many
                                    jurisdictions. By adhering to these regulations, drivers avoid hefty fines and legal
                                    consequences. Additionally, insurance extends coverage beyond collisions to include theft,
                                    vandalism, fire, and natural disasters, safeguarding your investment in your vehicle. In the
                                    unfortunate event of an accident, insurance also provides medical coverage for injuries
                                    sustained by you and your passengers, irrespective of fault. Furthermore, customizable
                                    coverage options allow drivers to tailor their policies to suit their specific needs and
                                    budget. With the support of insurance, drivers receive assistance in legal proceedings,
                                    should disputes arise from accidents. Overall, vehicle insurance not only protects against
                                    financial losses but also ensures safety, legal compliance, and peace of mind for drivers
                                    and their vehicles.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="advantages-vehicle-insurance-banner">
                                <img src={advVeh} alt="Advantages of Vehicle Insurance" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="right-plans-protect">
                <div className="container">
                    <h2>We Have the Right Plans to Protect Your Vehicle from All Kinds of Risk</h2>
                </div>
            </div>
            <InsurancePlan data={vehicleInsurance} />

            <div className="zero-documents">
                <div className="container">
                    <div className="seamless-process-1">
                        <div className="zero-documents-apply-text">
                            <h3>Health Insurance</h3>
                            <h2>Zero Documents required for our seamless process</h2>
                            <h4>T&C Apply*</h4>
                        </div>

                        <div className="zero-documents-apply-banner">
                            <img src={bikeImg} alt="Health Insurance Bike" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="help_popular_articles">
                <div className="container">
                    <h6 className="faq-choose-health-insurance">FAQ</h6>
                    <AccordianComponent data={faq} />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default VehicleInsurance