

import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiput, apiget } from '../../../../service/api';

const EditPolicyCompany = ({ fetchdata, setOpen, open, editId }) => {
  const [initialValues, setInitialValues] = useState({
    policyCompany: '',
    sourceName: '',
    companyPortalUrl: '',
  });

  const validationSchema = Yup.object({
    policyCompany: Yup.string()
      .required('Policy Company Name is required')
      .min(2, 'Name should be at least 2 characters long'),
    sourceName: Yup.string()
      .required('Source Name is required')
      .min(2, 'Source Name should be at least 2 characters long'),
    companyPortalUrl: Yup.string().url('Invalid URL format'),
  });

  const fetchPolicyCompany = async (id) => {
    const result = await apiget(`policy-company/view/${id}`);
    if (result && result.status === 200) {
      setInitialValues({
        policyCompany: result?.data?.policyCompany || '',
        sourceName: result?.data?.sourceName || '',
        companyPortalUrl: result?.data?.companyPortalUrl || '',
      });
    }
  };

  useEffect(() => {
    if (open && editId) {
      fetchPolicyCompany(editId);
    } else {
      fetchdata();
    }
  }, [open, editId]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      const result = await apiput(`policy-company/edit/${editId}`, values);
      if (result && result.status === 200) {
        fetchdata();
        formik.resetForm();
        setOpen(false);
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle variant="h5">Edit Policy Company Details</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <TextField
            label="Policy Company Name"
            variant="outlined"
            fullWidth
            id="policyCompany"
            name="policyCompany"
            value={formik.values.policyCompany}
            onChange={formik.handleChange}
            error={formik.touched.policyCompany && Boolean(formik.errors.policyCompany)}
            helperText={formik.touched.policyCompany && formik.errors.policyCompany}
            sx={{ marginTop: '10px', marginBottom: '10px' }}
          />
          <TextField
            label="Source Name"
            variant="outlined"
            fullWidth
            id="sourceName"
            name="sourceName"
            value={formik.values.sourceName}
            onChange={formik.handleChange}
            error={formik.touched.sourceName && Boolean(formik.errors.sourceName)}
            helperText={formik.touched.sourceName && formik.errors.sourceName}
            sx={{ marginTop: '10px', marginBottom: '10px' }}
          />
          <TextField
            label="Company Portal URL"
            variant="outlined"
            fullWidth
            id="companyPortalUrl"
            name="companyPortalUrl"
            value={formik.values.companyPortalUrl}
            onChange={formik.handleChange}
            error={formik.touched.companyPortalUrl && Boolean(formik.errors.companyPortalUrl)}
            helperText={formik.touched.companyPortalUrl && formik.errors.companyPortalUrl}
            sx={{ marginTop: '10px', marginBottom: '10px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Save
          </Button>
          <Button variant="outlined" color='error' onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditPolicyCompany;


