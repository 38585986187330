import React from 'react'
import { Link } from 'react-router-dom'
import '../BlogDetail.css'
import popularImg1 from '../../../../../Images/PopularPost/pizza.png'
import popularImg2 from '../../../../../Images/PopularPost/pizza-1.png'
import popularImg3 from '../../../../../Images/PopularPost/pizza-2.png'
import popularImg4 from '../../../../../Images/PopularPost/pizza-3.png'

const PopularPost = () => {
    const popularPostData = [
        {
            img: popularImg1,
            title: "Product",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            img: popularImg2,
            title: "PRODUCT | ENTERPRISE",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            img: popularImg3,
            title: "PRODUCTIVITY",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            img: popularImg4,
            title: "PRODUCTIVITY",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
    ]
    return (
        <div className="col-md-4">
            <h6>Popular Posts</h6>
            {
                popularPostData.map((item, index) => (
                    <div key={index} className="Popular_Posts">
                        <div className="pizza_banner">
                            <img src={item.img} alt='' />
                        </div>
                        <div className="Product">
                            <h4>{item.title}</h4>
                            <h5>{item.text}</h5>
                        </div>
                    </div>
                ))
            }



            <div className="done_together">
                <h3>Get More Done Together With US</h3>
                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                <div className="blog_detail_get_started">
                    <Link to="">Get Started</Link>
                </div>
            </div>
        </div>
    )
}

export default PopularPost