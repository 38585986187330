/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel } from "@mui/material";
import { apiput } from "../../../../service/api";

const Edit = ({ open, handleClose, setUserAction, id, singleCommitmentData }) => {
    const CommitmentData = singleCommitmentData;


    const initialValues = {
        title: CommitmentData?.title || "",
        desc: CommitmentData?.desc || "",
        createdBy: CommitmentData?.createdBy || "",
    };

    const validationSchema = yup.object({
        title: yup.string().required("Title is required"),
        desc: yup.string().required("Description is required"),
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });



    const fileUpload = async (values) => {
        try {

            const result = await apiput(`commitment/edit/${id}`, values);
            setUserAction(result);

            if (result && result.status === 200) {
                formik.resetForm();
                handleClose();
                // toast.success(result.data.message)
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Title</FormLabel>
                                <TextField
                                    id="title"
                                    name="title"
                                    size="small"
                                    fullWidth
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.title && Boolean(formik.errors.title)
                                    }
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Description</FormLabel>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    value={formik.values.desc}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.desc && Boolean(formik.errors.desc)
                                    }
                                    helperText={formik.touched.desc && formik.errors.desc}
                                />
                            </Grid>


                        </Grid>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ textTransform: "capitalize" }}
                            >
                                Save
                            </Button>
                            <Button
                                type="reset"
                                variant="outlined"
                                style={{ textTransform: "capitalize" }}
                                color="error"
                                onClick={() => {
                                    formik.resetForm();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Edit;
