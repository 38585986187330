import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const AccordianComponent = ({ data }) => {

    const [expanded, setExpanded] = useState(false);

    const toggleAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (

        <div className="accordion">
            {data &&
                data.length > 0 &&
                data.map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={toggleAccordion(index)}
                    >
                        <AccordionSummary
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <strong>{item.answerTitle}</strong>
                            </Typography>
                            <Typography component="div" variant="body1">
                                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
        </div>

    )
}

export default AccordianComponent