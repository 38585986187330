import React from 'react'
import {Link} from 'react-router-dom'
import {constant} from '../../../../constant'

const InsurancePlan = ({ data }) => {
    return (
        <div className="right-plans-insurance-policies">
            <div className="container">
                <div className="row">

                    {
                       data && data.length >0 &&  data.map((item,index) => (
                            <div  key={index} className="col-md-4">
                                <div className="right-plans-insurance-policies-title">
                                    <img src={`${constant.baseUrl}uploads/healthInsurancePlans/${item.file}`} alt="Car Insurance Policies" />
                                    <h2>{item.title}</h2>
                                    <p>{item.content}</p>
                                    <Link to="link-to-car-insurance">Know More</Link>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default InsurancePlan