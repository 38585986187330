import React from 'react'
import { FaHistory} from "react-icons/fa";

const CustomerServices = () => {
    return (
        <>
            <div className="health-Insurance-Trusted-Service">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="Fast-Reliable-bac">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="Fast-Reliable-icen">
                                            <FaHistory />
                                        </div>
                                    </div>
                                    <div className="col-md-9 tr-hea">
                                        <div className="Fast-Reliable-text">
                                            <h2>Trusted Service</h2>
                                            <p>Lorem Ipsum</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="Fast-Reliable-bac">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="Fast-Reliable-icen">
                                            <FaHistory />
                                        </div>
                                    </div>
                                    <div className="col-md-9 tr-hea">
                                        <div className="Fast-Reliable-text">
                                            <h2>24/7 Support</h2>
                                            <p>Lorem Ipsum</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="Fast-Reliable-bac">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="Fast-Reliable-icen">
                                            <FaHistory />
                                        </div>
                                    </div>
                                    <div className="col-md-9 tr-hea">
                                        <div className="Fast-Reliable-text">
                                            <h2>Fast and Reliable</h2>
                                            <p>Lorem Ipsum</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerServices