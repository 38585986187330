import React, { useEffect, useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaEdit } from "react-icons/fa";
import { apiget } from "../../../../service/api";





const AchievementBottomSlider = () => {
    const [commitmentData, setCommitmentData] = useState([]);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false // Hide arrows on mobile screens
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false // Hide arrows on mobile screens
                }
            }
        ]
    };


    const fetchCommitmentData = async () => {
        const result = await apiget('commitment/list');
        if (result && result.status === 200) {
            setCommitmentData(result?.data?.result)
        }
    }


    useEffect(() => {
        fetchCommitmentData();
    }, [])
    return (
        <div className="achievements_about_background">
            <div className="container">
                <div className="achievements_about_background_titel">
                    <p>At Vikas Mahipal we take immense pride in our accomplishments and the milestones we've achieved
                        throughout our journey. Here are some of the noteworthy achievements that define our commitment to
                        excellence and customer satisfaction:</p>
                </div>
                <Slider {...settings}>
                    {commitmentData && commitmentData.map((item,index) =>(
                        <div key={index}>
                            <li className="card">
                                <div className="slider_icen">
                                    <a href=""><FaEdit /></a>
                                </div>
                                <h2>{item.title}</h2>
                                <span>{item.desc.length >45 ? item.desc.slice(0,45):item.desc}</span>
                            </li>
                        </div>
                    ))}

                </Slider>
            </div>
        </div>
    )
}

export default AchievementBottomSlider