/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TextField, MenuItem, Select,FormLabel, FormControl } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiput } from "../../service/api";

const Edit = ({ open, handleClose, setUserAction, id, singleOurTeamData }) => {
    const OurTeamData = singleOurTeamData;


    const initialValues = {
        file: OurTeamData?.file || "",
        title: OurTeamData?.title || "",
        content: OurTeamData?.content || "",
        createdBy: OurTeamData?.createdBy || "",
    };

    const validationSchema = yup.object({
        file: yup.string().required("Image is required"),
        title: yup.string().required("title is required"),
        content: yup.string().required("content is required"),
       
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });

    


    const fileUpload = async (values) => {
        try {
            const data = new FormData();
            data.append("file", values.file);
            data.append("title", values.title);
            data.append("content", values.content);
            data.append("createdBy", values.createdBy);

            const result = await apiput(`help-center/edit/${id}`, data);
            setUserAction(result);

            if (result && result.status === 200) {
                formik.resetForm();
                handleClose();
                // toast.success(result.data.message)
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Upload Image</FormLabel>
                                <TextField
                                    id="file"
                                    name="file"
                                    size="small"
                                    maxRows={10}
                                    fullWidth
                                    type="file"
                                    multiple
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => {
                                        formik.setFieldValue("file", event.currentTarget.files[0]);
                                    }}
                                    error={
                                        formik.touched.file &&
                                        Boolean(formik.errors.file)
                                    }
                                    helperText={
                                        formik.touched.file && formik.errors.file
                                    }
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Title</FormLabel>
                                <TextField
                                    id="title"
                                    name="title"
                                    size="small"
                                    fullWidth
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.title && Boolean(formik.errors.title)
                                    }
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Content</FormLabel>
                                <TextField
                                    id="content"
                                    name="content"
                                    size="small"
                                    fullWidth
                                    value={formik.values.content}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.content && Boolean(formik.errors.content)
                                    }
                                    helperText={formik.touched.content && formik.errors.content}
                                />
                            </Grid>

                        </Grid>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ textTransform: "capitalize" }}
                            >
                                Save
                            </Button>
                            <Button
                                type="reset"
                                variant="outlined"
                                style={{ textTransform: "capitalize" }}
                                color="error"
                                onClick={() => {
                                    formik.resetForm();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Edit;
