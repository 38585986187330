/* eslint-disable react/prop-types */
import { Box, Card, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import Palette from '../../theme/palette'
import Addemail from '../../components/email/Addemail'

const Moreinformation = ({ data }) => {

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    // const habits = data?.habit ? data.habit.split(',') : [];


    // const habitQuantities = {
    //     "Alcohol": data?.alcoholQuantity,
    //     "Smoking": data?.smokingQuantity,
    //     "Tobacco": data?.tobaccoQuantity
    // };

    // dfgdfg
    return (
        <>
            {/* Add Email Model */}
            <Addemail open={open} handleClose={handleClose} _id={data?._id} receiver={data} />

            <Card style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                <Box p={3}>
                    <Grid container display="flex" spacing={4}>
                        <Grid item xs={12} sm={6} >
                            {data?.policyType && !data?.policyType.toLowerCase().includes('vehicle') && !data?.policyType.toLowerCase().includes('auto') && <>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="h4" pb={2} >Proposer Details :-</Typography>
                                    <Typography variant="body1">Proposer name :</Typography>
                                    {
                                        <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{
                                            data?.policyHolder ? data?.policyHolder : data?.insuredPersonName
                                            }</Typography>
                                    }
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Proposer date of birth :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>
                                        {
                                            data?.insuredPersonDateOfBirth ? dayjs(data?.insuredPersonDateOfBirth).format('MM/DD/YYYY') : "--"
                                        }
                                    </Typography>
                                </Grid>
                                {data?.relationshipToTheInsured &&
                                    <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                        <Typography variant="body1"> Relationship to the insured (if applicable) :</Typography>
                                        <Typography variant="body2" color={Palette.grey[600]}>
                                            {data?.relationshipToTheInsured === "Others" ? `${data?.relationshipToTheInsured} : ${data?.others}` : data?.relationshipToTheInsured ? data?.relationshipToTheInsured : "--"}
                                        </Typography>

                                    </Grid>}
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1"> Aadhaar:</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.insuredPersonNameAadhaar ? data?.insuredPersonNameAadhaar : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1"> PAN Card :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.insuredPersonNamePan ? data?.insuredPersonNamePan : "--"}</Typography>
                                </Grid>


                                <Grid mt={5} style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="h4" pb={2} >Insured person's contact information :-</Typography>
                                    <Typography variant="body1"> Phone Number :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.phoneNumber ? data?.phoneNumber : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Email :</Typography>
                                    <Typography variant="body2" color={Palette.primary.main} onClick={handleOpen} style={{ cursor: "pointer" }}>{data?.emailAddress ? data?.emailAddress : "--"}</Typography>
                                </Grid>
                                {data?.riskLocation && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Risk Location :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.riskLocation ? data?.riskLocation : "--"}</Typography>
                                </Grid>}

                                {/* <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="h4" py={2}>Habits:</Typography>
                                    {habits.length > 0 ? (
                                        habits.map((habit, index) => {
                                            const trimmedHabit = habit.trim();
                                            const quantity = habitQuantities[trimmedHabit];

                                            return (
                                                <Typography key={index} variant="body2" color={Palette.grey[600]}>
                                                    {trimmedHabit}
                                                    {quantity && (
                                                        <span> (Quantity: {quantity})</span>
                                                    )}
                                                </Typography>
                                            );
                                        })
                                    ) : (
                                        <Typography variant="body2" color={Palette.grey[600]}>--</Typography>
                                    )}
                                </Grid> */}
                                <Grid mt={4} style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="h4" py={2} >Policy Premiums and Payments :-</Typography>
                                    <Typography variant="body1">Premium amount :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.premiumAmount ? data?.premiumAmount : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Payment Method :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.paymentMethod ? data?.paymentMethod : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Payment Source :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.paymentSource ? data?.paymentSource : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Payment Period :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.paymentPeriod ? data?.paymentPeriod : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Claim Status :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.claimStatus ? data?.claimStatus : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Frequency of premium payments :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.FrequencyOfPremiumPayments ? data?.FrequencyOfPremiumPayments : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="h4" py={2} >Policy Remarks :-</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{data?.policyRemarks ? data?.policyRemarks : "--"}</Typography>
                                </Grid>
                            </>}


                            {(data?.policyType.toLowerCase().includes('vehicle') || data?.policyType.toLowerCase().includes('auto')) && (
                                <>
                                    <Grid py={2}>
                                        <Typography variant="h4" py={2}>Vehicle Details</Typography>
                                    </Grid>
                                    <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                        <Typography variant="body1">Vehicle Type:</Typography>
                                        <Typography variant="body2" color={Palette.grey[600]}>
                                            {data?.vehicleType ? data?.vehicleType : "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                        <Typography variant="body1">Vehicle Make:</Typography>
                                        <Typography variant="body2" color={Palette.grey[600]}>
                                            {data?.vehicleMake ? data?.vehicleMake : "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                        <Typography variant="body1">Vehicle Number:</Typography>
                                        <Typography variant="body2" color={Palette.grey[600]} textTransform={"uppercase"}>
                                            {data?.vehicleNumber ? data?.vehicleNumber : "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                        <Typography variant="body1">Vehicle Model:</Typography>
                                        <Typography variant="body2" color={Palette.grey[600]}>
                                            {data?.vehicleModel ? data?.vehicleModel : "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                        <Typography variant="body1">Year of Manufacture:</Typography>
                                        <Typography variant="body2" color={Palette.grey[600]}>
                                            {data?.yearOfManufacture ? data?.yearOfManufacture : "--"}
                                        </Typography>
                                    </Grid>


                                </>

                            )}


                        </Grid>



                        {data?.policyType && !data?.policyType.toLowerCase().includes('vehicle') && !data?.policyType.toLowerCase().includes('auto') && <>
                            <Grid item xs={12} sm={6} >
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="h4" py={2} > Insured Persons:-</Typography>
                                </Grid>

                                {
                                    data.additionalInsureds && data.additionalInsureds.map((insured, index) => (
                                        <>
                                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="h6" py={2} > Insured Person {index + 1}:-</Typography>
                                            </Grid>
                                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="body1"> Insured Person's Name :</Typography>
                                                <Typography variant="body2" color={Palette.grey[600]}>{insured.additionalInsuredPersonName ? insured.additionalInsuredPersonName : "--"}</Typography>
                                            </Grid>
                                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="body1"> Insured Person's date of birth :</Typography>
                                                <Typography variant="body2" color={Palette.grey[600]}>
                                                    {
                                                        insured?.additionalInsuredDateOfBirth ? dayjs(insured?.additionalInsuredDateOfBirth).format('MM/DD/YYYY') : "--"
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="body1"> Relationship to the insured:</Typography>
                                                <Typography variant="body2" color={Palette.grey[600]}>
                                                    {/* {insured?.additionalRelationshipToTheInsured ? insured?.additionalRelationshipToTheInsured : "--"} */}
                                                    {insured?.additionalRelationshipToTheInsured === "Others" ? `${insured?.additionalRelationshipToTheInsured} : ${insured?.others}` : insured?.additionalRelationshipToTheInsured ? insured?.additionalRelationshipToTheInsured : "--"}
                                                    </Typography>
                                            </Grid>
                                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="body1">Phone :</Typography>
                                                <Typography variant="body2" color={Palette.grey[600]}>{insured?.additionalPhoneNumber ? insured?.additionalPhoneNumber : "--"}</Typography>
                                            </Grid>
                                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="body1"> Insured Person's Email :</Typography>
                                                <Typography variant="body2" color={Palette.grey[600]}>{insured?.additionalEmailAddress ? insured?.additionalEmailAddress : "--"}</Typography>
                                            </Grid>
                                            {data?.policyType?.toLowerCase().includes('accidental') && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="body1"> Bonus :</Typography>
                                                <Typography variant="body2" color={Palette.grey[600]}>{insured?.bonus ? insured?.bonus : "--"}</Typography>
                                            </Grid>}
                                            {insured?.coverageAmounts && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="body1"> Coverage Amounts :</Typography>
                                                <Typography variant="body2" color={Palette.grey[600]}>{insured?.coverageAmounts ? insured?.coverageAmounts : "--"}</Typography>
                                            </Grid>}
                                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                                <Typography variant="body1">Additonal Insured Person's Habit :</Typography>
                                                {insured.additionalInsuredPersonHabbit.split(',').map((habit, index) => {
                                                    const trimmedHabit = habit.trim();
                                                    let quantity = '';

                                                    // Get the corresponding quantity for the habit
                                                    if (trimmedHabit === "Alcohol") {
                                                        quantity = insured.alcoholQuantity;
                                                    } else if (trimmedHabit === "Smoking") {
                                                        quantity = insured.smokingQuantity;
                                                    } else if (trimmedHabit === "Tobacco") {
                                                        quantity = insured.tobaccoQuantity;
                                                    }

                                                    return (
                                                        <Typography key={index} variant="body2" color={Palette.grey[600]}>
                                                            {trimmedHabit}
                                                            {quantity && (
                                                                <span> (Quantity: {quantity})</span>
                                                            )}
                                                        </Typography>
                                                    );
                                                })}

                                            </Grid>
                                        </>
                                    ))
                                }

                            </Grid>


                        </>}




                    </Grid>

                </Box>
            </Card >
        </ >
    )
}

export default Moreinformation
