import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete"
import Visibility from '@mui/icons-material/Visibility';
import { Box, Typography, Container, Paper, Stack, Button, Grid } from '@mui/material';
import Palette from '../../theme/palette';
import Iconify from '../../components/iconify';
import Add from './Add';
import Edit from './Edit'
import DeleteModel from '../../components/Deletemodle'
import { apidelete, apiget, deleteManyApi } from '../../service/api';
import View from '../HelpCentreManagement/view'


const ViewPayment = () => {

    const [paymentMethod, setPaymentMethod] = useState([]);
    const [contactDetail, setContactDetail] = useState({});
    const [data, setData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [recievedPayment, setRecievedPayment] = useState([]);
    const [userAction, setUserAction] = useState('');
    const [openPayment, setOpenPayment] = useState(false);
    const [openDiscount, setOpenDiscount] = useState(false);
    const [opendelete, setOpendelete] = useState(false);
    const [total, setTotal] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [due, setDue] = useState(0);
    const [editId, setEditId] = useState(null);
    const [openEditDiscount, setOpenEditDiscount] = useState(false);
    const [openEditPayment, setOpenEditPayment] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [updateDueId, setUpdateDueId] = useState(null);
    const [updateDueAmount, setUpdateDueAmount] = useState(0);
    const userRole = localStorage.getItem("userRole");



    const { id } = useParams();

    const navigate = useNavigate();

    const handleOpenPayment = () => {
        setOpenPayment(true);
    };

    const handleClosePayment = () => {
        setOpenPayment(false);
    };

    const handleOpenDiscount = () => {
        setOpenDiscount(true);
    };

    const handleCloseDiscount = () => {
        setOpenDiscount(false);
    };
    const handleOpenEditDiscount = () => {
        setOpenEditDiscount(true);
    }
    const handleCloseEditDiscount = () => {
        setOpenEditDiscount(false);
    }
    const handleOpenEditPayment = () => {
        setOpenEditPayment(true);
    }
    const handleCloseEditPayment = () => {
        setOpenEditPayment(false);
    }
    const handleOpenDelete = () => {
        setOpendelete(true);
    }
    const handleCloseDelete = () => {
        setOpendelete(false);
    }


    const fetchPaymentMethod = async () => {
        const result = await apiget('payment-method/list');
        if (result && result.status === 200) {
            const filterPaymentMethod = result?.data?.result.filter(item => item.type.toLowerCase().includes('payment recieve'));
            setPaymentMethod(filterPaymentMethod);
        }
    };

    const fetchRecievedPayment = async () => {
        const result = await apiget(`recieved-payment/list?id=${id}`);
        if (result && result.status === 200) {
            setUserAction(result)

            const payments = result?.data?.result;
            setRecievedPayment(payments);

        }
    };

    const fethContactNameById = async () => {

        const result = await apiget(`contact/fetch-contact-name?id=${id}`);
        if (result && result.status === 200) {
            setContactDetail(result?.data[0])
            setUserAction(result?.data)
           
        }
    }

    const makeAssociateData = async () => {

        const processedPolicies = (contactDetail.policies || []).map((item) => {

            return {
                id: item._id || null,
                insuredPersonName: item.policyHolder
                    ? item.policyHolder
                    : item.additionalInsureds && item.additionalInsureds.length > 0
                        ? item.additionalInsureds[0].additionalInsuredPersonName
                        : '',
                premiumAmount: item.premiumAmount || 0,
                paymentMethod: item.paymentMethod || '',
                amount: item.amount || 0,
                date: item.date || '',
                dueDate: item.dueDate || '',
                discount: item.discount || 0,
                total: 0,
                status: item.status || '',
                type: item.type || '',
                createdOn: item.createdOn
            };
        });


        setData(processedPolicies);

    }


    const makePaymentPlanData = async () => {
        //     // Initialize an empty array to hold all processed payment plans
        const allProcessedPlans = [];

        // Loop through each policy in contactDetail.policies
        (contactDetail.policies || []).forEach(policy => {
            // Ensure the policy has payment plans to process
            const processedPlans = (policy.paymentPlans || []).map(item => {
                return {
                    id: item._id || null,
                    insuredPersonName: item.policyHolder
                        ? item.policyHolder
                        : item.additionalInsureds && item.additionalInsureds.length > 0
                            ? item.additionalInsureds[0].additionalInsuredPersonName
                            : '',
                    premiumAmount: item.premiumAmount || 0,
                    paymentMethod: item.paymentMethod || '',
                    amount: item.amount || 0,
                    date: item.date || '',
                    dueDate: item.dueDate || '',
                    dueAmount: item.dueAmount || '',
                    discount: item.discount || 0,
                    total: 0, // Calculating total  
                    type: item.type || '',
                    status: item.status || '',
                    createdOn: item.createdOn || ''
                };
            });

            // Add the processed payment plans for this policy to the allProcessedPlans array
            allProcessedPlans.push(...processedPlans);
        });

        setData(prevData => [
            ...prevData,
            ...allProcessedPlans
        ]);
    };


    const makeAssociateData2 = async () => {

        const processedPolicies = (recievedPayment || []).map((item) => {

            return {
                id: item._id || null,
                insuredPersonName: item.policyHolder ? item.policyHolder : '',
                premiumAmount: item.premiumAmount || 0,
                paymentMethod: item.paymentMethod || '',
                amount: item.type === 'payment' ? item.amount : 0,
                date: item.date || '',
                dueDate: item.dueDate || '',
                dueAmount: item.dueAmount || '',
                discount: item.type === 'discount' ? item.amount : 0,
                total: 0,
                status: item.status || '',
                type: item.type || '',
                createdOn: item.createdOn
            }

        });


        setData(prevData => {
            // Combine existing and new data
            const newData = [...prevData, ...processedPolicies];

            // Sort combined data
            const sortedData = newData.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));

            // Initialize a variable to keep track of the cumulative sum
            let cumulativeSum = 0;
            let totalPremium = 0;
            let PaidAmount = 0;
            let discount = 0;
            // Map over the sorted data to compute totals and create the final data structure
            const sortedListingData = sortedData.map(item => {
                // Calculate the sum of the current item
                const currentSum = (Number(item.premiumAmount) || 0) - (Number(item.amount) || 0) - (Number(item.discount) || 0);

                totalPremium += Number(item.premiumAmount);
                setTotal(totalPremium)
                PaidAmount += Number(item.amount);
                setPaidAmount(PaidAmount)
                discount += Number(item.discount);
                setDiscount(discount)
                // Update the cumulative sum
                cumulativeSum += currentSum;
                setDue(cumulativeSum);

                // Return the processed item with the computed total
                return {
                    id: item.id || null,
                    insuredPersonName: item.insuredPersonName || '',
                    premiumAmount: item.premiumAmount || 0,
                    paymentMethod: item.paymentMethod || '',
                    amount: item.amount || 0,
                    discount: item.discount || 0,
                    date: item.date || '',
                    dueDate: item.dueDate || '',
                    dueAmount: item.dueAmount || '',
                    total: cumulativeSum,
                    type: item.type || '',
                    status: item.status || '',
                    createdOn: item.createdOn
                };
            });

            // Return the final data for state update
            return sortedListingData;
        });

    }


    const editPaymentData = async (id) => {
        try {
            const result = await apiget(`recieved-payment/view/${id}`);

            if (result && result.status === 200) {
                setPaymentData(result?.data);
            }
        } catch (error) {
            console.error("Error fetching Article data:", error);
        }
    };




    useEffect(() => {

        const callingThreefuction = async () => {
            await makeAssociateData();
            await makePaymentPlanData();
            await makeAssociateData2();
        }

        callingThreefuction();
    }, [userAction])

    useEffect(() => {


        fetchPaymentMethod();
        fethContactNameById();
        fetchRecievedPayment();
    }, []);


    const deleteData = async (deleteId) => {
        const result = await deleteManyApi(`recieved-payment/deletemany`, deleteId);

        if (result && result.status === 200) {
            handleCloseDelete();
            fetchRecievedPayment();
        }
    }

    const columns = [
        {
            field: 'customerName',

            headerName: 'Customer Name',
            flex: 1,
            minWidth: 200,
            cellClassName: "name-column--cell name-column--cell--capitalize",

            renderCell: (params) => {

                const handleFirstNameClick = () => {

                    if (userRole === 'admin') {
                        navigate(`/dashboard/contact/view/${id}`);
                    }
                };

                return (

                    <Box sx={{ cursor: 'pointer', color: '#165ff2', fontWeight: '600', textDecoration: 'underline' }} onClick={() => handleFirstNameClick}  >
                        {`${contactDetail.firstName} ${contactDetail.lastName}`}
                    </Box>

                );
            }
        },
        {
            field: 'insuredPersonName',
            headerName: 'Insured Person Name',
            flex: 1,

            minWidth: 200,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {
                const handleFirstNameClick = () => {
                    if (userRole === 'admin') {
                        navigate(`/dashboard/policy/view/${params.row.id}`);
                    }
                };

                return (
                    <Box sx={{ cursor: 'pointer', color: '#165ff2', fontWeight: '600', textDecoration: 'underline' }} onClick={handleFirstNameClick} >
                        {
                            params.row.insuredPersonName
                        }
                    </Box>
                );
            }


        },

        {
            field: 'premiumAmount',
            headerAlign: 'center',
            headerName: 'Premium Amount',
            type: 'number',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {


                return (
                    <Box sx={{ textAlign: 'center', width: '100%' }}>
                        {params.row.premiumAmount ? params.row.premiumAmount : '--'}
                    </Box>
                );
            },
        },
        {
            field: 'amount',
            headerAlign: 'center',
            headerName: 'Paid Amount',
            type: 'number',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ textAlign: 'center', width: '100%' }}>
                    {params.row.amount ? params.row.amount : '--'}
                </Box>
            ),
        },
        {
            field: 'paymentMethod',
            headerAlign: 'center',
            headerName: 'Method',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ textAlign: 'center', width: '100%' }}>
                    {params.row.paymentMethod ? params.row.paymentMethod : '--'}
                </Box>
            ),
        },
        {
            field: 'date',
            headerAlign: 'center',
            headerName: 'Paid Date',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ textAlign: 'center', width: '100%' }}>

                    {params.row.date ? params.row.date : '--'}
                </Box>
            ),
        },
        {
            field: 'discount',
            headerAlign: 'center',
            headerName: 'Discount',
            type: 'number',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ textAlign: 'center', width: '100%' }}>
                    {params.row.discount ? params.row.discount : '--'}
                </Box>
            ),
        },
        {
            field: 'advance',
            headerAlign: 'center',
            headerName: 'Advance',
            type: 'number',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                let sum = 0;
                sum = sum + (Number(params.row.premiumAmount) || 0) - (Number(params.row.amount) || 0) - (Number(params.row.discount) || 0);
                return (

                    <Box sx={{ textAlign: 'center', width: '100%' }}>
                        {params.row.total < 0 ? Math.abs(params.row.total) : '--'}
                    </Box>
                )
            },
        },
        {
            field: 'dueDate',
            headerAlign: 'center',
            headerName: 'Due Date',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ textAlign: 'center', width: '100%' }}>

                    {params.row.dueDate ? params.row.dueDate : '--'}
                </Box>
            ),
        },
        {
            field: 'dueAmount',
            headerAlign: 'center',
            headerName: 'Due Amount',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ textAlign: 'center', width: '100%' }}>

                    {params.row.dueAmount ? params.row.dueAmount : '--'}
                </Box>
            ),
        },
        {
            field: 'total',
            headerAlign: 'center',
            headerName: 'Total',
            type: 'number',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {

                // const sum =  (Number(params.row.premiumAmount) || 0) - (Number(params.row.amount) || 0) - (Number(params.row.discount) || 0);
                // setDue(sum);
                return (
                    <Box sx={{ textAlign: 'center', width: '100%' }}>
                        {params.row.total < 0 ? 0 : params.row.total}
                    </Box>
                );
            },
        },
        userRole === 'admin' && {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 250,
            renderCell: (params) => {


                const handleOpenEditDialog = async (userId) => {

                    if (params.row.type === 'payment') {
                        handleOpenEditPayment();
                    }
                    if (params.row.type === 'discount') {
                        handleOpenEditDiscount();
                    }
                    editPaymentData(userId);
                    setEditId(userId)
                }
                const isDisabled = !params.row.type;

                const updateStatus = (ID,DUE) => {

                    setUpdateDueId(ID);
                    setUpdateDueAmount(DUE);
                    handleOpenPayment();

                }

                return (

                    <Stack direction={"row"} spacing={3} >
                        <EditIcon
                            sx={{
                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                opacity: isDisabled ? 0.5 : 1,
                                color: isDisabled ? 'action.disabled' : 'primary.main',
                            }}
                            variant='outlined'
                            size='small'
                            color='primary'
                            onClick={() => {
                                if (!isDisabled) {
                                    handleOpenEditDialog(params?.row?.id);
                                }
                            }}
                        />

                        <Visibility
                            sx={{
                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                opacity: isDisabled ? 0.5 : 1,
                                color: isDisabled ? 'action.disabled' : 'primary.main',
                            }}
                            variant='outlined' size='small' color='primary'
                            onClick={() => {
                                if (!isDisabled) {
                                    editPaymentData(params?.row?.id);

                                    setOpenView(true);
                                }
                            }}
                        />

                        <DeleteIcon
                            sx={{
                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                opacity: isDisabled ? 0.5 : 1,
                                color: isDisabled ? 'action.disabled' : 'error.main',
                            }}
                            variant='outlined' size='small'
                            onClick={() => {
                                if (!isDisabled) {

                                    handleOpenDelete();
                                }
                            }} />
                        <Button
                            variant="outlined"
                            onClick={() => {

                                updateStatus(params.row.id,params.row.dueAmount);
                            }}
                            sx={{
                                padding: 0,
                                cursor: params.row.status !== 'Pending' ? 'not-allowed' : 'pointer',
                            }}
                            color="primary"
                            disabled={params.row.status !== 'Pending'} // Disable button when status is pending
                        >
                            Pay
                        </Button>
                        <Add fetchRecievedPayment={fetchRecievedPayment} method={paymentMethod} open={openPayment} dueAmount={updateDueAmount} dueId={updateDueId} handleClose={handleClosePayment} setUserAction={setUserAction} _id={id} type="payment" />

                        <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteData} id={[params.row.id]} />
                    </Stack>


                );
            }
        },

    ];

    return (
        <>


            <Add fetchRecievedPayment={fetchRecievedPayment} method={paymentMethod} open={openDiscount} handleClose={handleCloseDiscount} setUserAction={setUserAction} _id={id} type="discount" />
            <Edit fetchRecievedPayment={fetchRecievedPayment} method={paymentMethod} open={openEditPayment} data={paymentData} handleClose={handleCloseEditPayment} setUserAction={setUserAction} id={editId} type="payment" />
            <Edit fetchRecievedPayment={fetchRecievedPayment} method={paymentMethod} open={openEditDiscount} data={paymentData} handleClose={handleCloseEditDiscount} setUserAction={setUserAction} id={editId} type="discount" />
            <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={paymentData} header="Description" />
            <Container component={Paper} sx={{ padding: 2 }}>
                <Box p={2}>
                    <Typography style={{ marginBottom: "10px" }} variant="h4">{`Payment Details  ( ${contactDetail.firstName} ${contactDetail.lastName} )`}</Typography>
                    <Grid container display="flex" spacing={4}>
                        <Grid item xs={12} sm={2}>

                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                                <Typography variant="body1">Total :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]} >{total}</Typography>
                            </Grid>



                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                                <Typography variant="body1">Amount Paid:</Typography>
                                <Typography variant="body2" color={Palette.grey[600]} >{paidAmount}</Typography>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                                <Typography variant="body1">Due Amount:</Typography>
                                <Typography variant="body2" color={Palette.grey[600]} >{due < 0 ? 0 : due}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                                <Typography variant="body1">Advance:</Typography>
                                <Typography variant="body2" color={Palette.grey[600]} >{due < 0 ? Math.abs(due) : 0}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                                <Typography variant="body1">Discount:</Typography>
                                <Typography variant="body2" color={Palette.grey[600]} >{discount}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Stack direction="column" alignItems="center" mb={1}>
                    <Stack direction="row" alignItems="center" justifyContent="end" width="100%" mb={1} mt={5} >

                        {userRole === 'admin' && <Stack direction={"row"} spacing={2} >
                            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenDiscount}>
                                Discount
                            </Button>
                            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenPayment}>
                                Payment
                            </Button>
                        </Stack>}
                    </Stack>
                </Stack>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={data || []}
                        columns={columns || []}
                        pageSize={5}
                        rowsPerPageOptions={[5]}

                        getRowId={(row) => row.uniqueField || Math.random()}
                    />
                </Box>

            </Container >
        </>
    );

};

export default ViewPayment;
