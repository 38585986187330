import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography'
import ClearIcon from "@mui/icons-material/Clear";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import './Home.css'
import { constant } from '../../../constant';

const ListedCompanyDialog = ({ open, setOpen, data }) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">Listed Companies</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={{ xs: 0, sm: 5, md: 2 }}

                    >

                        {
                            data && data.length > 0 && data.map((item, index) => (
                                <Grid item xs={6} sm={6} md={6}>
                                    <img className='listed_company_dialog_imgaes' key={index} src={`${constant.baseUrl}uploads/listedCompany/${item.file}`} alt="" />
                                </Grid>
                            ))
                        }



                    </Grid>
                </DialogContent>

            </Dialog>
        </div>
    );
};

export default ListedCompanyDialog