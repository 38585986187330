/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit'
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import PolicyIcon from '@mui/icons-material/Policy';
import AddIcon from "@mui/icons-material/Add";
import TableStyleTwo from "../../components/TableStyleTwo";
import AddPolicy from '../policy/Add'


const Policy = ({ rows, toggleVisibilityPolicy, isVisiblePolicy, _id, contact, familyData, setUserAction }) => {

  const userRole = localStorage.getItem('userRole');
  const [openAdd, setOpenAdd] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);


  // open add model
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);




  const navigate = useNavigate();

  useEffect(() => {
    const validFamilyData = Array.isArray(familyData) && familyData.length > 0 ? familyData : [];
    const validContact = contact && contact._id ? [contact] : [];

    // Merge and remove duplicates based on _id
    const combinedData = [...validFamilyData, ...validContact];
    const uniqueData = Array.from(new Set(combinedData.map(item => item._id)))
      .map(id => combinedData.find(item => item._id === id));

    setDropDownData(uniqueData);
  }, [contact, familyData]);






  const columns = [

    {
      field: 'insuredPersonName',
      headerName: 'Proposer Name',
      flex: 1,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      minWidth: 200,
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`);
        };
        return (
          <Grid key={`insured-${params.row._id}`} onClick={handleFirstNameClick} >
            {params?.row?.policyHolder ?
              params?.row?.policyHolder :
              params?.row?.insuredPersonName
            }
          </Grid>
        );
      }
    },
    {
      field: '',
      headerName: 'Insured Person Name',
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`);
        };
        return (
          <Grid key={`insuredPerson-${params.row._id}`} onClick={handleFirstNameClick} >
            {params?.row?.additionalInsureds[0]?.additionalInsuredPersonName
              ? params?.row?.additionalInsureds[0]?.additionalInsuredPersonName
              : params?.row?.vehicleNumber?.toUpperCase()}
          </Grid>
        );
      }
    },

    {
      field: 'vehicleModel',
      headerName: 'Vehicle Model',
      flex: 1,
      headerAlign: 'center',
      minWidth: 200,
      renderCell: (params) => {

        return (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: '100%' }}
            >
              {params?.row?.vehicleModel ? params.row.vehicleModel : '--'}
            </Grid>
          </>
        );
      }
    },
    {
      field: "policyType",
      headerName: "Policy Type",
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'policyCompanyName',
      headerName: 'Policy Company',
      flex: 1,
      minWidth: 250,

    },
    {
      field: 'policySource',
      headerName: 'Source',
      flex: 1,
      headerAlign: 'center',
      minWidth: 200,
      renderCell: (params) => {

        return (
          <>
            <Grid
              container
              justifyContent="center" // Center horizontally
              alignItems="center"     // Center vertically
              style={{ height: '100%' }} // Ensure the Grid takes full height of the cell
            >
              {params?.row?.policySource}
            </Grid>
          </>
        );
      }

    },
    {
      field: "policyStartDate",
      headerName: "Policy start date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toDateString();
      },
    },
    {
      field: "policyEndDate",
      headerName: "Policy end date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toDateString();
      },
    },
    {
      field: "policyStatus",
      headerName: "Policy status",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`)
        };

        return (
          <Stack direction={'row'} >
            <Button
              key={`button-${params.row._id}`}
              variant='text'
              size='small'
              color='primary'
              onClick={handleFirstNameClick}
            >
              <VisibilityIcon />
            </Button>
            {/* <Button
              key={`button-${params.row._id}`}
              variant='text'
              size='small'
              color='primary'
              onClick={handleFirstNameClick}
            >
              <EditIcon />
            </Button> */}
          </Stack>
        );
      }
    },
  ];


  return (
    <>

      {/* Add Claim */}
      <AddPolicy open={openAdd} handleClose={handleCloseAdd} _id={_id} setUserAction={setUserAction} allPolicyData={dropDownData} />

      <Box style={{ cursor: "pointer" }} p={2}>
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Button
                onClick={toggleVisibilityPolicy}
                color="secondary"
                variant="contained"
                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
              >
                {isVisiblePolicy ? <RemoveIcon /> : <AddIcon />}
              </Button>
              <PolicyIcon />
              <Typography variant="h5">Policy</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
              {isVisiblePolicy && userRole === 'admin' && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpenAdd}
                >
                  Create Policy
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Box >
      {
        isVisiblePolicy &&
        <TableStyleTwo>
          <Box width="100%" height="30vh">
            <DataGrid
              rows={rows}
              columns={columns}
              getRowId={row => row._id}
              columnHeaderHeight={40}
            />
          </Box>
        </TableStyleTwo>
      }
    </>
  );
};

export default Policy;
