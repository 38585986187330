import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa6";
import '../Home.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apiget } from "../../../../service/api";
import { constant } from "../../../../constant";

const InsuranceSlider = () => {
  const [insurancePackageData,setInsurancePackageData]=useState([]);

const fetchInsurancePackageData=async()=>{
    const result= await apiget(`insurance_package/list`)
    if(result && result.status===200){
        setInsurancePackageData(result?.data?.result);
    }
}


useEffect(()=>{
    fetchInsurancePackageData();
},[])
    

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                   
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="health_insurance">
            <div className="container">
                <div className="Vehicle_insurance">
                    <div className="slider-top-heading-btn">
                        <h3>Insurance Packages.</h3>
                        <div className="slider-btn-top">
                            <button className="arrowButton" onClick={previous} >
                                <MdArrowBackIos />
                            </button>
                            <button className="arrowButton" onClick={next}>
                                <MdArrowForwardIos />
                            </button>
                        </div>
                    </div>

                    <p>An insurance package is a comprehensive solution designed to provide financial protection and peace of mind
                        to
                        individuals, families, and businesses against unforeseen risks and losses. It typically combines various types
                        of insurance coverage tailored to meet specific needs and circumstances.</p>
                </div>
                <div className="slider mySwiper">
                    <div className="image-items swiper-wrapper items_slider_padding">
                        <Slider
                            ref={slider => {
                                sliderRef = slider;
                            }}
                            {...settings}
                        >

                            {
                              insurancePackageData && insurancePackageData.length>0 &&  insurancePackageData.map((item, index) => (
                                    <div key={index}>
                                        <div className="image swiper-slide" >
                                            <div className="home_insurance_slider_text">
                                                <div className="container">
                                                    <img src={`${constant.baseUrl}uploads/insurancePackage/${item.file}`} alt='' />
                                                    <h3>{item.title}</h3>
                                                    <p>{item.content.length > 135 ? `${item.content.substr(0, 135)}...` : item.content}</p>
                                                    <hr />
                                                    <Link to="">Learn More <FaArrowRight /></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </Slider>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default InsuranceSlider