import React from 'react'
import { Link } from 'react-router-dom'
import '../BLog/Blog.css'
import { LuClock8 } from "react-icons/lu";


const BlogComponent = (props) => {

    const content = props.content.replace(/<[^>]+>/g, '');
    const hashtags = props.hastags.split(' ').slice(0, 4);
    

    const colors = ["#9BB848", "#48B8AA", "#B89F48"];
    const colorIndex = 0;


    const maxLength = 160;
    const truncateContent = (content) => {
        if (content.length > maxLength) {
            return `${content.substring(0, maxLength)}...`;
        }
        return content;
    };

  
    const date = new Date(props.date);
    const day = date.getDate(); // Get the day (1-31)
    const month = date.toLocaleString('default', { month: 'long' }); // Get the month name
    const year = date.getFullYear();


    return (

        <div className="col-md-4">
            <div className="javascript_nextjs_hover">
                <div className="javascript_nextjs_title">
                    <img src={props.img} alt='' />
                    <h3 className="ellipsis">{props.title}</h3>
                    <div className="blog_next_ds">
                        {hashtags.map((item, index) => {

                            const color = colors[index % colors.length];
                            return (
                                <h5 key={index} style={{ color }}>{item}</h5>
                            );
                        })}
                    </div>
                    <Link to={`/blog-detail/${props.id}`}><p>{truncateContent(content)}</p> </Link>
                </div>
                <div className="minutes_clock">
                    <div className="minutes_read">
                        <Link href=""><LuClock8 /></Link>
                        <span>5-7 minutes read</span>
                    </div>
                    <div className="minutes_read">
                        <h6>{day} {month} {year}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogComponent
