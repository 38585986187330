// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Lead Management',
    path: '/dashboard/lead',
    icon: icon('ic_lead'),
  },
  {
    title: 'Contact Management',
    path: '/dashboard/contact',
    icon: icon('ic_contact'),
  },
  {
    title: 'Policy Management',
    path: '/dashboard/policy',
    icon: icon('ic_policy'),
  },

  {
    title: 'Tasks',
    path: '/dashboard/task',
    icon: icon('ic_task'),
  },
  {
    title: 'Meetings',
    path: '/dashboard/meeting',
    icon: icon('ic_meeting'),
  },
  {
    title: 'Calls',
    path: '/dashboard/call',
    icon: icon('ic_call'),
  },
  {
    title: 'Emails',
    path: '/dashboard/email',
    icon: icon('ic_email'),
  },
  {
    title: 'Calendar',
    path: '/dashboard/calendar',
    icon: icon('ic_calendar'),
  },
  {
    title: 'Document Management',
    path: '/dashboard/document',
    icon: icon('ic_document'),
  },
  {
    title: 'Email Template',
    path: '/dashboard/emailtemplate',
    icon: icon('ic_emailTemplate'),
  },
  {
    title: 'User Management',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Add Policy Type',
    path: '/dashboard/policy-types',
    icon: icon('ic_addPolicy'),
  },
  {
    title: 'Add Policy Company',
    path: '/dashboard/policy-company',
    icon: icon('ic_addPolicyCompany'),
  },
  {
    title: 'Frontend Management',
    icon: icon('ic_meeting'),
    children: [
      {
        title: 'Blog Management',
        path: '/dashboard/blog-management',
        icon: icon('ic_blog'),
      },
      {
        title: 'Achievement Management',
        path: '/dashboard/achievement-management',
        icon: icon('ic_achievement'),
      },
      {
        title: 'Commitment Managemnt',
        path: '/dashboard/commitment',
        icon: icon('ic_achievement'),
      },
      {
        title: 'Enquiry',
        path: '/dashboard/enquiry',
        icon: icon('ic_enquiry'),
      },
      {
        title: 'Testimonials',
        path: '/dashboard/testimonial',
        icon: icon('ic_testimonial'),
      },
      {
        title: 'About Us',
        path: '/dashboard/about',
        icon: icon('ic_aboutus'),
      },
      {
        title: 'Insurance Packages',
        path: '/dashboard/insurance_package',
        icon: icon('ic_insurance_package'),
      },
      {
        title: 'Listed Companies',
        path: '/dashboard/listed_company',
        icon: icon('ic_listed_company'),
      },
      {
        title: 'Articles',
        path: '/dashboard/article',
        icon: icon('ic_faq'),
      },
      {
        title: 'Our Team',
        path: '/dashboard/team',
        icon: icon('ic_our_team'),
      },
      {
        title: 'Insurance Plans Management',
        path: '/dashboard/insurance-plans',
        icon: icon('ic_insurance_management'),
      },
      {
        title: 'Services Plans Management',
        path: '/dashboard/service-plans',
        icon: icon('ic_service_plans'),
      },
      {
        title: 'Help Center Management',
        path: '/dashboard/help-center',
        icon: icon('ic_help_center_management'),
      },
    ]
  },
  {
    title: 'Ledger',
    path: '/dashboard/recieved-payment',
    icon: icon('ic_ledger'),
  },
  // {
  //   title: 'Payment Source',
  //   path: '/dashboard/payment-source',
  //   icon: icon('ic_payment_source'),
  // },
  {
    title: 'Payment Option',
    path: '/dashboard/payment-option',
    icon: icon('ic_payment_source'),
  },
  {
    title: 'Payment Method',
    path: '/dashboard/payment-method',
    icon: icon('ic_payment_method'),
  },
  {
    title: 'Ranking',
    path: '/dashboard/ranking',
    icon: icon('ic_ranking'),
  },
  {
    title: 'Referral Source',
    path: '/dashboard/referral-source',
    icon: icon('ic_referral_source'),
  },
  {
    title: 'RelationShip Managment',
    path: '/dashboard/relationship',
    icon: icon('ic_relationship'),
  },
 

  // {
  //   title: 'History',
  //   path: '/dashboard/history',
  //   icon: icon('ic_history'),
  // },

];

export default navConfig;
