/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as yup from "yup";
import { FormLabel, Rating, FormControl, Select, MenuItem } from "@mui/material";
import { apiput, apiget } from "../../service/api";

const Edit = ({ open, handleClose, setUserAction, id, singleArticleData }) => {
    const ArticleData = singleArticleData;


    const initialValues = {
        question: ArticleData?.question || "",
        answer: ArticleData?.answer || "",
        page: ArticleData?.page || "",
        createdBy: ArticleData?.createdBy || "",
    };

    const validationSchema = yup.object({
        question: yup.string().required("question is required"),
        answer: yup.string().required("answer is required"),
        page: yup.string().required("page is required"),
        createdBy: yup.string(),
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });

    const handleChange = (event) => {
        formik.handleChange(event);
    };


    const fileUpload = async (values) => {
        try {
            const data = new FormData()
            data.append("question", values.question)
            data.append("answer", values.answer)
            data.append("createdBy", values.createdBy)
            data.append("page", values.page)
            const result = await apiput(`article/edit/${id}`, values);
            setUserAction(result);

            if (result && result.status === 200) {
                formik.resetForm();
                handleClose();
                // toast.success(result.data.message)
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Question</FormLabel>
                                <TextField
                                    id="question"
                                    name="question"
                                    size="small"
                                    fullWidth
                                    value={formik.values.question}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.question && Boolean(formik.errors.question)
                                    }
                                    helperText={formik.touched.question && formik.errors.question}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <FormLabel>Page</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="page"
                                        name="page"
                                        label=""
                                        size='small'
                                        value={formik.values.page}
                                        onChange={formik.handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="Health">Health</MenuItem>
                                        <MenuItem value="Vehicle">Vehicle </MenuItem>
                                        <MenuItem value="Property">Property </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Answer</FormLabel>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formik.values.answer}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue('answer', data);
                                    }}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading',
                                                '|',
                                                'bold',
                                                'italic',
                                                'underline',
                                                'link',
                                                'bulletedList',
                                                'numberedList',
                                                '|',
                                                'undo',
                                                'redo'
                                            ]
                                        },
                                        heading: {
                                            options: [
                                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                                            ]
                                        }
                                    }}
                                />
                            </Grid>

                        </Grid>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ textTransform: "capitalize" }}
                            >
                                Save
                            </Button>
                            <Button
                                type="reset"
                                variant="outlined"
                                style={{ textTransform: "capitalize" }}
                                color="error"
                                onClick={() => {
                                    formik.resetForm();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Edit;
