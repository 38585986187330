import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    Typography,
    DialogActions,
} from '@mui/material';

import { apiput, apiget } from '../../service/api';

const EditPaymentSource = (props) => {
    const { fetchdata, setOpen, open, editId } = props;
    const [newPaymentSource, setNewPaymentSource] = useState('');

    const onhandleChange = async (id) => {

        const updatedPaymentSource = newPaymentSource;
        const result = await apiput(`payment-source/edit/${id}`, { paymentSource: updatedPaymentSource });
     
        if (result && result.status === 200) {
          fetchdata();
          fetchPaymentSource(id);
        }
    };

    const fetchPaymentSource = async (id) => {
        const result = await apiget(`payment-source/view/${id}`);
        if (result && result.status === 200) {
          setNewPaymentSource(result?.data?.paymentSource);
        }
    };

    useEffect(() => {
        if (open && editId) {
            fetchPaymentSource(editId);
        } else {
            fetchdata();
        }

    }, [open, editId]);

    const onhandleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={onhandleClose}>
            <DialogTitle variant="h5">Payment Source </DialogTitle>
                <DialogContent dividers>
                  
                    <TextField
                        label="Edit Source Name"
                        variant="outlined"
                        fullWidth
                        value={newPaymentSource}
                        onChange={(e) => setNewPaymentSource(e.target.value)}
                        sx={{ marginBottom: '16px' }}
                    />
                    <Button variant="contained" onClick={() => {
                        onhandleChange(editId);
                        onhandleClose();
                        // fetchdata();
                    }}>
                        Save
                    </Button>
                </DialogContent>

            </Dialog>
        </>
    );
};

export default EditPaymentSource;
