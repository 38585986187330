/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useState } from 'react';
// @mui
import { Stack, Button, Typography, Box, Grid } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from '@mui/icons-material/Download';
import Delete from '@mui/icons-material/Delete';
import Share from '@mui/icons-material/Share';
import RemoveIcon from "@mui/icons-material/Remove";
import AttachmentIcon from '@mui/icons-material/Attachment';
// sections
import AddDocument from './Add'
import Edit from './Edit';
import DeleteModel from '../../../components/Deletemodle'
import { apiget, deleteManyApi } from '../../../service/api';
import TableStyleTwo from '../../../components/TableStyleTwo';
import { constant } from '../../../constant';
import View from '../../HelpCentreManagement/view'
// ----------------------------------------------------------------------


const userRole = localStorage.getItem('userRole');

const ContactDocumentPage = ({ rows, toggleVisibilityContactDoc, isVisibleContactDoc, setUserAction, _id }) => {

    const [openAdd, setOpenAdd] = useState(false);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const [openView, setOpenView] = useState(false);
    const handleOpenView = () => setOpenView(true);

    const [opendelete, setOpendelete] = useState(false);

    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    const deleteManyContact = async (data) => {
        const result = await deleteManyApi('contact-document/deletemany', data)
        if (result && result.status === 200) {
            setUserAction(result);
            handleCloseDelete();
        }

    }

    const [contactDocumentData, setContactDocumentData] = useState([]);


    const downloadFile = async (id) => {
        const result = await apiget(`contact-document/file/${id}`)
        setUserAction(result)
    }


    const fetchContactDocument = async (id) => {
        const result = await apiget(`contact-document/view/${id}`);
        if (result && result.status === 200) {
            setContactDocumentData(result?.data?.result);
        }
    }

    const EditFileName = async (id) => {
        fetchContactDocument(id)
        handleOpenEdit();
        // const result = await apiput(`contact-document/edit/${id}`);
    }



    const columns = [
        {
            field: "file",
            headerName: "File",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {

                const ViewPolicyDocument = () => {
                    fetchContactDocument(params.row._id)
                    handleOpenView();
                }

                return (
                    <Box onClick={ViewPolicyDocument}>
                        {params.value}
                    </Box>
                )
            }
        },

        {
            field: "fileName",
            headerName: "File Name",
            flex: 1,
            cellClassName: "name-column--cell--capitalize"
        },
        {
            field: "createdOn",
            headerName: "CreateOn",
            flex: 1,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toDateString();
            },

        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                const handleFirstNameClick = async () => { downloadFile(params.row._id) };
                const downloadUrl = `${constant.baseUrl}contact-document/file/${params.row._id}`;

                const handleShareClick = () => {
                    const message = `Click this link to Download Policy Document : ${downloadUrl}`;
                    const encodedMessage = encodeURIComponent(message);
                    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
                    window.open(whatsappUrl, '_blank');
                };

                return (
                    <Box >
                        <Stack direction={"row"} spacing={2}>
                            <a href={downloadUrl}><DownloadIcon variant='contained' size='small' onClick={handleFirstNameClick} /></a>
                            {userRole === 'admin' && <>
                                <Delete variant='outlined' size='small' color='error' onClick={handleOpenDelete} />
                                <DeleteModel opendelete={opendelete} sx={{ cursor: 'pointer' }} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={[params.row._id]} />
                                <EditIcon variant='outlined' sx={{ cursor: 'pointer' }} size='small' color='primary' onClick={() => EditFileName(params.row._id)} />
                            </>}
                            <Share variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='primary'
                                onClick={handleShareClick}
                            />
                        </Stack>
                    </Box>
                );
            }
        },

    ];

    return (
        <>
            {/* Add Document Model */}
            <AddDocument open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} />
            <Edit open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} _id={_id} data={contactDocumentData} />
            <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={contactDocumentData} header="Contact Document" imageFolder="contactDocuments" />
            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityContactDoc}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisibleContactDoc ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5">Contact Documents</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisibleContactDoc && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Add Document
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisibleContactDoc &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                        />
                    </Box>
                </TableStyleTwo>

            }
        </>
    );
}

export default ContactDocumentPage