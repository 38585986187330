import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box, Grid } from '@mui/material';
// components
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../../components/iconify';
import AddRanking from './Add'
import { apidelete, apiget, deleteManyApi } from '../../service/api';
import TableStyle from '../../components/TableStyle';
import DeleteModel from '../../components/Deletemodle'
import EditRanking from './Edit'



function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyContact = async (data) => {
        const result = await deleteManyApi('ranking/deletemany', data)
        if (result && result.status === 200) {
            fetchdata()
            handleCloseDelete();
        }

    }

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}

const RankingManager = () => {
    const [userAction, setUserAction] = useState(null);
    const [RankingList, setRankingList] = useState([]);
    const [RankingData, setRankingData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState("");

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyRanking = async (data) => {
        const result = await deleteManyApi('ranking/deletemany', data)
        if (result && result.status === 200) {
            fetchdata()
            handleCloseDelete();
        }

    }


    const handleOpenAdd = () => {
        setOpenAdd(true)
    }
    const handleCloseAdd = () => {
        setOpenAdd(false)
    }
    const handleCloseEdit = () => {
        setOpenEdit(false);
    }

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };


    // file delete api
    const deleteFile = async (id) => {
        const result = await apidelete(`ranking/delete/${id}`)
        setUserAction(result)
    }
    const editRankingData = async (id) => {
        try {
            const result = await apiget(`ranking/view/${id}`);
            if (result && result.status === 200) {
                setRankingData(result?.data);
            }
        } catch (error) {
            console.error("Error fetching ranking data:", error);
        }
    };

    const columns = [
        {
            field: "rank",
            headerName: "Rank Name",
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {params.value}
                </Box>
            ),
        },

        {
            field: "from",
            headerName: "Amount From",
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {params.value}
                </Box>
            ),

        },
        {
            field: "to",
            headerName: "Amount To",
            flex: 1,
            headerAlign: "center",
            renderCell: (params) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {params.value}
                </Box>
            ),
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,

            headerAlign: 'center',
            renderCell: (params) => {

                const handleOpenEditDialog = async (data) => {
                    setOpenEdit(true);
                    editRankingData(data);
                    setEditId(data)
                }

                return (

                    <Grid container spacing={1} justifyContent="center">
                        <Grid item>
                            <Button
                                variant='outlined'
                                sx={{ cursor: 'pointer' }}
                                color="primary"
                                onClick={() => handleOpenEditDialog(params?.row?._id)}
                            ><EditIcon /></Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='outlined'
                                sx={{ cursor: 'pointer' }}
                                color="error"
                                onClick={handleOpenDelete}
                            ><DeleteIcon /></Button>
                        </Grid>
                        <DeleteModel
                            opendelete={opendelete}
                            handleClosedelete={handleCloseDelete}
                            deletedata={deleteManyRanking}
                            id={[params.row._id]}
                        />
                    </Grid>

                );
            }
        },

    ];

    // fetch rankings list
    const fetchdata = async () => {

        const result = await apiget(userRole === "admin" ? `ranking/list` : `ranking/list/?createdBy=${userid}`)
        if (result && result.status === 200) {
            setRankingList(result?.data?.result)
        }
    }



    useEffect(() => {
        fetchdata();
    }, [userAction])
    return (
        <>
            {/* Add Ranking Model */}

            <AddRanking open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
            <EditRanking open={openEdit} RankingData={RankingData} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Ranking
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                        Ranking
                    </Button>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={RankingList}
                                columns={columns}
                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                            />
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default RankingManager