import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import { LuClock8 } from "react-icons/lu";
import { apiget } from '../../../../service/api';
import BlogImg from '../../../../Images/Blog/blog-banner.png'
import './Blog.css'
import BlogComponent from '../BlogComponent/BlogComponent'
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import { constant } from '../../../../constant'





const Blog = () => {

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
    const [blogData, setBlogData] = useState([]);



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = blogData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    const fetchBlogData = async () => {

        const result = await apiget(`blog-management/list`)
        if (result && result.status === 200) {
            setBlogData(result?.data?.result);
        }

    }

    const fetchDate = (data) => {
        const date = new Date(data);
        const day = date.getDate(); // Get the day (1-31)
        const month = date.toLocaleString('default', { month: 'long' }); // Get the month name
        const year = date.getFullYear();
        return `${day} ${month} ${year}`
    }


    const hashtags = blogData && blogData[0] && blogData[0].hastags && (
        blogData[0].hastags.split(' ').slice(0, 4)
    )

    const colors = ["#9BB848", "#48B8AA", "#B89F48"];
    const colorIndex = 0;


    useEffect(() => {
        fetchBlogData();

    }, [])


    return (<div className='blog-main-container'>
        <div className="container">
            <Navbar />
            <div className="Blog_title">
                <h3>Blog</h3>
                <p className="blog_pad">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. ullamcorper accumsan nisl
                </p>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className='blog-header-img'>
                        <img src={blogData && blogData[0] && blogData[0].file && (`${constant.baseUrl}uploads/blogs/${blogData[0].file}`) ||BlogImg } alt='' />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="Blog_title_text">
                        {blogData && blogData[0] && (
                            <>
                                <h4>
                                    {blogData[0].title.length > 56
                                        ? `${blogData[0].title.substring(0, 56)}...`
                                        : blogData[0].title}
                                </h4>
                                <span>{blogData && blogData[0] && blogData[0].content && (
                                    fetchDate(blogData[0].createdOn)
                                )}</span>
                            </>
                        )}
                        <div className="blog_next_ds">
                            {hashtags && hashtags.length > 0 && hashtags.map((item, index) => {

                                const color = colors[index % colors.length];
                                return (
                                    <h5 key={index} style={{ color }}>{item}</h5>
                                );
                            })}
                        </div>
                        <p>
                            {blogData && blogData[0] && blogData[0].content && (
                                blogData[0].content
                                    .replace(/<[^>]+>/g, '')
                                    .slice(0, 235)
                            )}
                        </p>
                        <div className="blog_next_ds">
                            <Link to={`/blog-detail`}><LuClock8 /></Link>
                            <span>5-7 minutes read</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="javascript_nextjs">
                <div className="row">
                    {blogData.map((item, index) => (

                        <BlogComponent key={index} img={`${constant.baseUrl}uploads/blogs/${item.file}`} id={item._id} title={item.title}
                            content={item.content} hastags={item.hastags} date={item.createdOn} />

                    ))}
                </div>
            </div>

            <div className="blog_pagination">
                <nav aria-label="Page navigation example ">
                    <ul className="pagination">
                        <li className="page-item"><Link className="page-link"  >Previous</Link></li>
                        {Array(Math.ceil(blogData.length / itemsPerPage))
                            .fill()
                            .map((_, index) => (
                                <li key={index} className="page-item" >
                                    <Link to="#" className="page-link" onClick={() => paginate(index + 1)}>
                                        {index + 1}
                                    </Link>
                                </li>
                            ))}
                        <li className="page-item"><Link className="page-link"  >Next</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
        <Footer />
    </div>
    )
}

export default Blog