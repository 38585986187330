/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box, Grid, Switch } from '@mui/material';
// components
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Add, Edit, Delete, Download, Share } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import AttachmentIcon from '@mui/icons-material/Attachment';
import User from '../../user/User';
// sections
import AddDocument from './Add'
import EditDocument from './Edit';
import { apidelete, deleteManyApi, apiget, apiput } from '../../../service/api';
import TableStyleTwo from '../../../components/TableStyleTwo';
import DeleteModel from '../../../components/Deletemodle'
import { constant } from '../../../constant';
import View from '../../HelpCentreManagement/view'

// ----------------------------------------------------------------------

const PolicyDocumentPage = ({ rows, toggleVisibilityPolicyDoc, fetchdata, userAction, isVisiblePolicyDoc, setUserAction, _id }) => {

    const userRole = localStorage.getItem("userRole");
    const [openAdds, setOpenAdds] = useState(false);
    const handleOpenAdds = () => setOpenAdds(true);
    const handleCloseAdds = () => setOpenAdds(false);

    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const [openDelete, setOpenDelete] = useState(false)
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    const [openView, setOpenView] = useState(false);
    const handleOpenView = () => setOpenView(true);
  


    const [policyDocumentData, setPolicyDocumentData] = useState([]);


    let filteredPolicies = '';

    if (rows && rows.length > 0) {
        filteredPolicies = rows.filter(item => item.expiryStatus === "active");

    }

    const deleteMany = async (deleteId) => {
        const result = await deleteManyApi('policydocument/deletemany', deleteId)
        if (result && result.status === 200) {

            fetchdata();
            handleCloseDelete();
        }
    }


    const downloadFile = async (id) => {
        const result = await apiget(`policydocument/file/${id}`)
        setUserAction(result);

    }


    const EditFileName = async (id) => {
        fetchPolicyDocument(id)
        handleOpenEdit();
        //   const result= await apiput(`policydocument/edit/${id}`);


    }

    const fetchPolicyDocument = async (id) => {
        const result = await apiget(`policydocument/view/${id}`);
        if (result && result.status === 200) {
            setPolicyDocumentData(result?.data?.result)
            setUserAction(result?.data?.result)
        }
    }

    const changePolicyStatus = async (id, newStatus) => {

        const data = { status: newStatus };
        try {
            const result = await apiput(`policydocument/expired-policy-document/${id}`, data);
            if (result && result.status === 200) {

                setUserAction(result);
            }

        } catch (error) {
            console.error("Error updating note status:", error);
        }

    }

    useEffect(() => {

    }, [userAction])


    const columns = [
        {
            field: "file",
            headerName: "File",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {

                const ViewPolicyDocument=()=>{
                    fetchPolicyDocument(params.row._id)
                    handleOpenView();
                }

                return (
                    <Box onClick={ViewPolicyDocument}>
                        {params.value}
                    </Box>
                )
            }
        },

        {
            field: "fileName",
            headerName: "File Name",
            flex: 1,
            cellClassName: "name-column--cell--capitalize"
        },
        {
            field: "createdOn",
            headerName: "CreateOn",
            flex: 1,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toDateString();
            },

        },
        userRole === 'admin' ?
            {
                field: "status",
                headerName: "Status",
                flex: 0,
                renderCell: (params) => {

                    const handleStatusChange = (event) => {
                        const newStatus = event.target.checked ? 'expired' : 'active';

                        changePolicyStatus(params.row._id, newStatus);
                        return newStatus;
                    };

                    return (
                        <Switch
                            checked={params.row.expiryStatus === 'expired'}
                            onChange={handleStatusChange}
                            name="status"
                            color="primary"
                        />
                    );
                }
            } : '',
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            headerAlign: 'center', // This aligns the header text to the center
            renderCell: (params) => {


                const handleFirstNameClick = async () => {
                    await downloadFile(params.row._id);
                };
                const downloadUrl = `${constant.baseUrl}policydocument/file/${params.row._id}`;

                const handleShareClick = () => {
                    const message = `Click this link to Download Contact Document : ${downloadUrl}`;
                    const encodedMessage = encodeURIComponent(message);
                    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
                    window.open(whatsappUrl, '_blank');
                };

                return (
                    <Stack direction={"row"} spacing={3} justifyContent={'center'}>

                        <a href={downloadUrl} download style={{ color: 'inherit', textDecoration: 'none' }}>
                            <Download variant='contained' color='primary' sx={{ cursor: 'pointer' }} size='small' onClick={handleFirstNameClick} />
                        </a>

                        {userRole === 'admin' && (<>
                            <Delete variant='outlined' size='small' color='error' sx={{ cursor: 'pointer' }} onClick={handleOpenDelete} />
                            <DeleteModel opendelete={openDelete} handleClosedelete={handleCloseDelete} deletedata={deleteMany} id={[params.row._id]} />
                        </>)}

                        {userRole === 'admin' && <Edit variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='primary'
                            onClick={() => EditFileName(params.row._id)} />}

                        <Share variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='primary'
                            onClick={handleShareClick}
                        />
                    </Stack>
                );
            }
        }


    ];

    return (
        <>
            {/* Add Document Model */}
            <AddDocument open={openAdds} handleClose={handleCloseAdds} setUserAction={setUserAction} _id={_id} />
            <EditDocument open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} _id={_id} data={policyDocumentData} />
            <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={policyDocumentData} header="Policy Document" imageFolder="policyDocuments" />
            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityPolicyDoc}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisiblePolicyDoc ? <RemoveIcon /> : <Add />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5">Policy Documents</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisiblePolicyDoc && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<Add />}
                                    onClick={handleOpenAdds}
                                >
                                    Add Document
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisiblePolicyDoc &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={filteredPolicies}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                        />
                    </Box>
                </TableStyleTwo>

            }
        </>
    );
}

export default PolicyDocumentPage