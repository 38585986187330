
import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Grid, Typography, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// components
import Iconify from '../../components/iconify';
import AddAchievements from './Add'
import { apiget } from '../../service/api';
import TableStyle from '../../components/TableStyle';
import { constant } from '../../constant';
import EditAchievements from './Edit'
import Palette from '../../theme/palette'



const PaymentOptions = () => {
    const [userAction, setUserAction] = useState(null);
    const [viewData, setViewData] = useState([]);
    const [data, setData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editId, setEditId] = useState("");

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")

    const handleOpenAdd = () => {
        setOpenAdd(true)
    }

    const handleCloseAdd = () => {
        setOpenAdd(false)
    }
    const handleCloseEdit = () => {
        setOpenEdit(false);
    }
    const handleOpenEdit = () => {
        setOpenEdit(true)
    }

    const fetchPaymentOption = async () => {
        const result = await apiget('payment-option/list');
        if (result && result.status === 200) {
            setData(result?.data?.result[0])
            setEditId(result?.data?.result[0]?._id)
        }
    }

    const handleCopyClick = () => {
        if (data?.upiId) {
            navigator.clipboard.writeText(data.upiId)
                .then(() => {
                    alert('UPI ID copied to clipboard!');
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        }
    };

    const editAchievementsData = async (id) => {

        try {
            const result = await apiget(`payment-option/view/${id}`);

            if (result && result.status === 200) {

                setViewData(result?.data?.result)
            }
        } catch (error) {
            console.error("Error fetching Achievements data:", error);
        }
    };





    useEffect(() => {
        fetchPaymentOption();
    }, [userAction])
    return (
        <>

            <AddAchievements open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
            <EditAchievements open={openEdit} paymentOptionData={viewData} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} />

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Payment Options
                    </Typography>
                 { userRole=== 'admin' && <> {!data && !data?.file && <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                        Add
                    </Button>}
                        {data && data?.file && <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
                            editAchievementsData(editId);
                            handleOpenEdit();
                        }}>
                            Edit
                        </Button>}</>}
                </Stack>
                <Card style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                    <Box p={3}>
                        <Grid container display="flex" spacing={4}>
                            <Grid item xs={12} md={6} sm={12}>
                                <Typography variant="h5" textAlign={"center"}>
                                    UPI   Details
                                </Typography>
                                <Grid style={{ margin: "0 auto 20px", borderBottom: "1.5px dashed", height: "200px", width: "200px", borderBottomColor: Palette.grey[400] }} py={2}>

                                    <img src={`${constant.baseUrl}uploads/paymentOption/${data?.file ? data?.file : ''}`} alt='' />
                                </Grid>
                                <Grid py={2}>

                                    <Typography variant="h6" textAlign={"center"} color={Palette.grey[600]} >
                                        {data?.upiId ? `UPI ID : ${data?.upiId}` : "--"} <ContentCopyIcon onClick={handleCopyClick} />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6} sm={12}>
                                <Typography variant="h5">
                                    Bank Details
                                </Typography>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Account Holder Name :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} >{data?.accountHolder ? data?.accountHolder : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Account Number  :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} >{data?.accountNumber ? data?.accountNumber : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">IFSC Code  :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} >{data?.ifsc ? data?.ifsc : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Bank Name  :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} >{data?.bankName ? data?.bankName : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Branch Name  :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} >{data?.branchName ? data?.branchName : "--"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box >
                </Card >
            </Container >
        </>
    );
}

export default PaymentOptions