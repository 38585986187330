/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel,FormHelperText,FormControl,Select,MenuItem } from "@mui/material";
import { apiput } from "../../../service/api";

const Edit = ({ open, handleClose, fetchdata, id, data }) => {
    const PaymentPlanData = data;


    const initialValues = {
        dueDate: PaymentPlanData?.dueDate || "",
        dueAmount: PaymentPlanData?.dueAmount || "",
        desc: PaymentPlanData?.desc || "",
        status: PaymentPlanData?.status || "",
        createdBy: PaymentPlanData?.createdBy,
        policy_id: PaymentPlanData?.policy_id,
    };

    const validationSchema = yup.object({
        dueDate: yup.date().required("Due Date is required"),
        dueAmount: yup.string().required("Due Amount is required"),
        status: yup.string().required("Status is required"),
        desc: yup.string(),
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });




    const fileUpload = async (values) => {

        try {

            const result = await apiput(`payment-plan/edit/${id}`, values);


            if (result && result.status === 200) {
                fetchdata();
                formik.resetForm();
                handleClose();

            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Due Amount<Typography component={'span'} color={'red'}>*</Typography></FormLabel>
                                <TextField
                                    id="dueAmount"
                                    name="dueAmount"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    value={formik.values.dueAmount}
                                    onChange={(e) => {
                                        const value = e.target.value < 1 ? '' : e.target.value;
                                        formik.setFieldValue('dueAmount', value);
                                    }}
                                    error={
                                        formik.touched.dueAmount && Boolean(formik.errors.dueAmount)
                                    }
                                    helperText={formik.touched.dueAmount && formik.errors.dueAmount}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Due Date<Typography component={'span'} color={'red'}>*</Typography></FormLabel>
                                <TextField
                                    id="dueDate"
                                    name="dueDate"
                                    size="small"
                                    type="date"
                                    fullWidth
                                    value={formik.values.dueDate}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.dueDate && Boolean(formik.errors.dueDate)
                                    }
                                    helperText={formik.touched.dueDate && formik.errors.dueDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>
                                    Payment Status<Typography component={'span'} color={'red'}>*</Typography>
                                </FormLabel>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    error={formik.touched.status && Boolean(formik.errors.status)}
                                >
                                    <Select
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} // To trigger the touched state
                                    >
                                        <MenuItem key={'pending'} value={'Pending'}>
                                            Pending
                                        </MenuItem>
                                        <MenuItem key={'paid'} value={'Paid'}>
                                            Paid
                                        </MenuItem>
                                    </Select>

                                    {/* Show helper text if there is an error */}
                                    {formik.touched.status && formik.errors.status && (
                                        <FormHelperText>{formik.errors.status}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Description</FormLabel>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={formik.values.desc}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.desc && Boolean(formik.errors.desc)
                                    }
                                    helperText={formik.touched.desc && formik.errors.desc}
                                />
                            </Grid>

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.handleSubmit();

                        }}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm();
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Edit;
