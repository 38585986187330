/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { FormLabel, Rating } from "@mui/material";
import { apipost } from "../../service/api";


const Add = (props) => {
    const { open, handleClose, setUserAction, _id } = props


    const userid = localStorage.getItem('user_id')
    const userRole = localStorage.getItem("userRole")

    // -----------  validationSchema
    const validationSchema = yup.object({
        // file: yup.string(),
        // name: yup.string().required("name is required"),
        title: yup.string().required("Title is required"),
        content: yup.string().required("Content is required"),
        rating: yup.string().required("Rating is required"),
        status: yup.string(),
    });



    // add contact api
    const fileUpload = async (values) => {

        const result = await apipost('testimonial/upload', values)
        setUserAction(result)

        if (result && result.status === 200) {
            formik.resetForm();
            handleClose();
            // toast.success(result.data.message)
        }
    }

    // formik
    const formik = useFormik({

        initialValues: {
            title: "",
            content: "",
            rating: 0,
            status: 'On',
            contact_id: _id,
            createdBy: userid
        },
        validationSchema,
        onSubmit: async (values) => {
            fileUpload(values)
        },
    });

    useEffect(() => {
        if (_id) {
            formik.setFieldValue('contact_id', _id);

        }
    }, [_id]);



    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                onClose={()=>{
                    handleClose();
                    formik.resetForm();
                }}
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Add New </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={()=>{
                                handleClose();
                                formik.resetForm();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data">
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Upload Image</FormLabel>
                                <TextField
                                    id="file"
                                    name="file"
                                    size="small"
                                    maxRows={10}
                                    fullWidth
                                    type="file"
                                    multiple
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => {
                                        formik.setFieldValue("file", event.currentTarget.files[0]);
                                    }}
                                    error={
                                        formik.touched.file &&
                                        Boolean(formik.errors.file)
                                    }
                                    helperText={
                                        formik.touched.file && formik.errors.file
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Name</FormLabel>
                                <TextField
                                    id="name"
                                    name="name"
                                    size="small"
                                    fullWidth
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    aria-label="name"
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Title</FormLabel>
                                <TextField
                                    id="title"
                                    name="title"
                                    size="small"
                                    fullWidth
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.title && Boolean(formik.errors.title)
                                    }
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel component="legend">Rating</FormLabel>
                                <Rating
                                    id="rating"
                                    name="rating"
                                    value={formik.values.rating}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue('rating', newValue);
                                    }}
                                    error={
                                        formik.touched.rating && Boolean(formik.errors.rating)
                                    }
                                    helpertext={formik.touched.rating && formik.errors.rating}
                                    size="large"
                                    aria-label="rating"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Descirption</FormLabel>
                                <TextField
                                    id="content"
                                    name="content"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    value={formik.values.content}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.content && Boolean(formik.errors.content)
                                    }
                                    helperText={formik.touched.content && formik.errors.content}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Add