import React from 'react'
import '../BlogDetail.css'
import { Link } from 'react-router-dom'
import PopularPost from '../PopularPosts/PopularPost'

const BlogDetailMiddle = ({content}) => {
   
    return (
        <div className="blog_detail_titel">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                    <div dangerouslySetInnerHTML={{ __html: content }} />

                    </div>
                    <PopularPost />

                </div>
            </div>
        </div>
    )
}

export default BlogDetailMiddle