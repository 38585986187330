

import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Grid,
  DialogActions,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { apiput, apiget } from '../../../../service/api';

const EditPolicyType = (props) => {
  const { fetchdata, setOpen, open, editId } = props;
  const [initialValues, setInitialValues] = useState({ policyTypes: '' });

  const validationSchema = yup.object({
    policyTypes: yup.string().required('Policy Type is required'),
  });

  const fetchPolicyType = async (id) => {
    const result = await apiget(`policy-types/view/${id}`);
    if (result && result.status === 200) {
      setInitialValues({ policyTypes: result?.data?.policyTypes });
    }
  };

  useEffect(() => {
    if (open && editId) {
      fetchPolicyType(editId);
    }
  }, [open, editId]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true, // This allows the form to update when initialValues changes
    validationSchema,
    onSubmit: async (values) => {
      const result = await apiput(`policy-types/edit/${editId}`, { type: values.policyTypes });
      if (result && result.status === 200) {
        formik.resetForm();
        fetchdata();
        
        onhandleClose();
      }
    },
  });

  const onhandleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onhandleClose}>
      <DialogTitle variant="h5">Edit Policy Type</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 5, md: 4 }}>
            <Grid item xs={12}>
              <TextField
                label="Edit Policy Type"
                variant="outlined"
                fullWidth
                name="policyTypes"
                value={formik.values.policyTypes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.policyTypes && Boolean(formik.errors.policyTypes)}
                helperText={formik.touched.policyTypes && formik.errors.policyTypes}
                sx={{ marginTop: '16px', marginBottom: '16px' }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPolicyType;
