import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Stack, Button, Container, TextField, Typography, Box } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbar } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import { apiget } from "../../service/api";
import TableStyle from '../../components/TableStyle';

const userRole = localStorage.getItem("userRole");
const userDataString = localStorage.getItem('user');
const userData = JSON.parse(userDataString);
const email = userData?.emailAddress || '';


function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [searchText, setSearchText] = useState('');

    // Use debounce to limit API calls
    const debouncedSearch = useCallback(
        debounce((text) => fetchdata(text), 1000), // 300ms debounce time
        []
    );

    const handleChange = (e) => {
        const newValue = e.target.value;
        setSearchText(newValue);
        debouncedSearch(newValue);
    };

    return (
        <GridToolbarContainer style={{ display: 'flex', alignItems: 'center' }}>
            <GridToolbar />
            {userRole === 'admin' && (
                <TextField
                    value={searchText}
                   
                    onChange={handleChange}
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    style={{marginBottom:'10px'}}
                    
                />
            )}
        </GridToolbarContainer>

    );
}



const RecievedPayments = () => {
    const [contactList, setContactList] = useState([]);
    const [userContact, setUserContact] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);

    const navigate = useNavigate();

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };

    // Fetch data initially and handle search
    useEffect(() => {
        fetchdata();
        checkContactByEmail();
    }, []); // Empty dependency array to fetch data on mount

    const fetchdata = async (searchText = '') => {
        try {
            const result = await apiget(`contact/list?search=${searchText}`);
            if (result && result.status === 200) {

                const sortedData = result?.data?.result.sort((a, b) => {
                    const nameA = a.firstName || '';
                    const nameB = b.firstName || '';
                    return nameA.localeCompare(nameB);
                });
                setContactList(sortedData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const checkContactByEmail = async () => {
        const result = await apiget(`contact/user-contact?email=${email}`);
        if (result && result.status === 200) {
            setUserContact(result?.data?.result);
            console.log(userContact)
        }
    }

    const columns = [
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {
                const handleFirstNameClick = () => {
                    navigate(`/dashboard/recieved-payment/view/${params.id}`)
                };

                return (
                    <Box onClick={handleFirstNameClick}>
                        {params.value}
                    </Box>
                );
            }
        },
        { field: "lastName", headerName: "Last Name", flex: 1 },
        { field: "gender", headerName: "Gender", flex: 1 },
        { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
        { field: "emailAddress", headerName: "Email Address", flex: 1 },
    ];

    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">
                    {userRole === 'admin' ? "Received Payments" : 'Policy Payments'}
                </Typography>
            </Stack>
            <TableStyle>
                <Box width="100%">
                    <Card style={{ height: "600px", paddingTop: "15px" }}>
                        <DataGrid
                            rows={userRole === 'admin' ? contactList : userContact}
                            columns={columns}
                            components={{ Toolbar: () => <CustomToolbar fetchdata={fetchdata} /> }}
                            checkboxSelection
                            onRowSelectionModelChange={handleSelectionChange}
                            rowSelectionModel={selectedRowIds}
                            getRowId={(row) => row._id}
                        />
                    </Card>
                </Box>
            </TableStyle>
        </Container>
    );
};

export default RecievedPayments;

