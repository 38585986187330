import React from 'react'
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import '../Home.css'
import HandShakeIMg from '../../../../Images/Home/HandShake.png'
import insur from '../../../../Images/Home/insurance-services-1.png'
import insur2 from '../../../../Images/Home/insurance-services.png'


const Consultation = () => {
    return (
        <div className="home_why_choose_us">
            <div className="container">
                <h6>WHY CHOOSE US?</h6>
                <h4>Find best insurance agent or consultations?</h4>
                <div className="row">
                    <div className="col-md-2">
                        <div className="trusted_partners_box">
                            <img src={HandShakeIMg} alt='' />
                            <h3>24 X 7 <br/>Claim Process</h3>
                           
                        </div>
                        <div className="trusted_partners_box">
                            <img src={HandShakeIMg} alt='' />
                            <h3>Seemless <br/>Documentation</h3>
                            
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="trusted_partners_box">
                            <img src={HandShakeIMg} alt='' />
                            <h3>Pan India<br/> Service</h3>
                          
                        </div>
                        <div className="trusted_partners_box ">
                            <img src={HandShakeIMg} alt='' />
                            <h3>More than<br/> 5 exp. Advisor</h3>
                            
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="insurance_services">
                            <div className="ins-banner-one">
                                <img src={insur2} alt='' />
                            </div>
                            <div className="ins-banner-two">
                                <img src={insur} alt='' />
                            </div>
                            <div className="ins-title-three">
                                <p><span>5 years of experience in INsurance Services</span> CEO & FOUNDER</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consultation