import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../Home.css'
import NaviInsur from '../../../../Images/Home/Navigating-Insurance.png'
import homeCategory from '../../../../Images/Home/home-category.png'
import { apiget } from '../../../../service/api'
import { constant } from '../../../../constant'

const HomeVlog = () => {
    const [blogData, setBlogData] = useState([]);
    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem('userRole');


    const fetchBlogData = async () => {
        const result = await apiget(
            `blog-management/list`
        );
        if (result && result.status === 200) {
            const cleanedData = result?.data?.result.map((item) => ({
                ...item,
                content: item.content.replace(/<[^>]*>/g, '')
            }));
            setBlogData(cleanedData);
        }
    };

    const dateFormate=(StringDate)=>{
        const date = new Date(StringDate);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
        return formattedDate.replace(/\//g, '-');;
    }


    useEffect(() => {
        fetchBlogData();
    }, [])
    return (
        <div className="home_our_blogs_background">
            <div className="container">
                <div className="row">

                    {blogData.slice(0, 2).map((item, index) => {

                        const titleLimit = item.title.length > 80 ? `${item.title.substring(0, 80)}...` : item.title;
                        const limitedContent = item.content.length > 100 ? `${item.content.substring(0, 100)}...` : item.content;

                        return (
                            <div key={index} className="col-md-4">
                                <div className="home_our_blogs">
                                    <img src={`${constant.baseUrl}uploads/blogs/${item.file}`} alt='' />
                                    <div className="home_blog_sec">
                                        <h6>{dateFormate(item.createdOn)}</h6>
                                        <h6>Category</h6>
                                    </div>
                                    <h3>{titleLimit}</h3>
                                    <p>{limitedContent}</p>
                                </div>
                            </div>
                        );
                    })
                    }



                    <div className="col-md-4">
                        <div className="titel_our_blogs">
                            <h2>Our blogs</h2>
                            <p>Read our Latest News and Blogs</p>
                        </div>
                        {
                            blogData.slice(0, 2).map((item, index) => {

                                const title = item.title.length > 50 ? `${item.title.substring(0, 50)}...` : item.title;

                                return (
                                    <div className="boder" key={index}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="navigating_insurance">
                                                    <img src={`${constant.baseUrl}uploads/blogs/${item.file}`} alt='' />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="navigating_insurance">
                                                    <div className="home_blog_sec">
                                                        <h6>{dateFormate(item.createdOn)}</h6>
                                                        <h6>Category</h6>
                                                    </div>
                                                    <h4>{title}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="home_view_more">
                            <Link to="/blog">View More</Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HomeVlog