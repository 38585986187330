/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { DialogActions, DialogContent, FormLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { apidelete, apiget, apiput } from "../../service/api";
import { constant } from '../../constant';



const ViewNote = (props) => {

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem('userRole');

    const { open, handleClose, setUserAction, id } = props
    const [audio, setAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [notesData, setNotesData] = useState({})
    

    // -----------  validationSchema
    const validationSchema = yup.object({
        file: yup.string(),
        status: yup.string(),
        subject: yup.string().required("Subject is required"),
        note: yup.string().required("Note is required"),
    });


    // -----------   initialValues
    const initialValues = {
        subject: notesData?.subject,
        file: notesData?.file,
        note: notesData?.note,
        status: notesData?.status,
        modifiedOn: "",
    };



    // delete api
    const deletedata = async () => {
        const result = await apidelete(`note/delete/${id}`)
        setUserAction(result)
        handleClose();
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {

            const formData = new FormData();

            formData.append("file", values.file);
            formData.append("status", values.status);
            formData.append("subject", values.subject);
            formData.append("note", values.note);
            formData.append("modifiedOn", new Date());

            try {
                const result = await apiput(`note/edit/${id}`, formData);

                setUserAction(result)
                if (result && result.status === 200) {
                    handleClose();
                }

            } catch (error) {
                console.error("An error occurred while adding the note.");
            }

        },
    });


    // fetch api
    const fetchdata = async () => {
        if (id) {
            const result = await apiget(`note/view/${id}`)
            if (result && result.status === 200) {
                setNotesData(result?.data)
            }
        }
    }

    const handlePlayAudio = (path) => {
        const audioUrl = `${constant.baseUrl}${path}`;
       
        if (audio) {
            if (isPlaying) {
                audio.pause();
                setIsPlaying(false);
            } else {
                audio.play().catch(error => {
                    console.error('Error playing audio:', error);
                });
                setIsPlaying(true);
            }
        } else {
            const newAudio = new Audio(audioUrl);

            // Error handling
            newAudio.onerror = (e) => {
                console.error('Audio failed to load or play:', e);
            };

            setAudio(newAudio);
            newAudio.play().catch(error => {
                console.error('Error playing audio:', error);
            });
            setIsPlaying(true);
        }
    };


    useEffect(() => {
        if (open === true && id) {
            fetchdata();
        }

    }, [open])

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">View & Update Note</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >

                                <Grid item xs={12} sm={12}>
                                    <FormLabel  >Upload MP3 File
                                    </FormLabel>
                                    <TextField
                                        id="file"
                                        type="file"
                                        name="file"
                                        InputProps={{
                                            inputProps: {
                                                accept: "audio/mp3",
                                            },
                                        }}
                                        onChange={(event) => {
                                            formik.setFieldValue("file", event.currentTarget.files[0]);
                                        }}
                                        error={
                                            formik.touched.file &&
                                            Boolean(formik.errors.file)
                                        }
                                        helperText={
                                            formik.touched.file && formik.errors.file
                                        }
                                        fullWidth

                                    />
                                </Grid>

                                {notesData.file && <Grid item container xs={12} sm={12} md={12} alignItems="center" justifyContent="space-between" >
                                    <TextField
                                        value={notesData.file}
                                        readOnly

                                    />
                                    {notesData?.status === 'On' || userRole === 'admin' ? <Button
                                        variant="outlined"
                                        size="small"
                                        color={isPlaying ? "secondary" : "primary"}
                                        onClick={() => handlePlayAudio(notesData.path)}
                                    >

                                        {isPlaying ? "Pause" : "Play "}
                                    </Button> : ''
                                    }
                                </Grid>}


                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel  >Subject<Typography component="span" sx={{ color: 'red' }} >*</Typography>
                                    </FormLabel>
                                    <TextField
                                        id="subject"
                                        name="subject"
                                        size="small"
                                        maxRows={10}
                                        fullWidth
                                        value={formik.values.subject}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.subject &&
                                            Boolean(formik.errors.subject)
                                        }
                                        helperText={
                                            formik.touched.subject && formik.errors.subject
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <FormLabel  >Note<Typography component="span" sx={{ color: 'red' }} >*</Typography>
                                    </FormLabel>
                                    <TextField
                                        id="note"
                                        name="note"
                                        size="small"
                                        rows={5}
                                        multiline
                                        fullWidth
                                        value={formik.values.note}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.note &&
                                            Boolean(formik.errors.note)
                                        }
                                        helperText={
                                            formik.touched.note && formik.errors.note
                                        }
                                    />

                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    {userRole === 'admin' ?
                        <> <Button
                            type="submit"
                            variant="contained"
                            onClick={formik.handleSubmit}
                            style={{ textTransform: "capitalize" }}
                            color="secondary"
                        >
                            Update
                        </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={deletedata}
                                style={{ textTransform: "capitalize" }}
                                color="error"
                            >
                                Delete
                            </Button>
                        </> : ''
                    }

                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        cancel
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}

export default ViewNote