import React from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import '../Insurance_common_css.css'
import { FaLongArrowAltRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Services = ({ data }) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
    
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows:false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows:false,
                }
            }
        ]

    };
    return (

        <div className="health-Insurance-services">
            <div className="container">
                <h4>SERVICES</h4>
                <h2>Experienced in Multiple Medical Practices</h2>
                <p>Lorem ipsum dolor sit amet consectetur. Eget commodo sit phasellus vel. Aliquet ac viverra arcu adipiscing tempor fermentum massa.</p>
                <div className="row">
                    {
                        data && data.length > 0 && (
                            data.length > 3 ? (
                                <Slider {...settings}>
                                    {data.map((item, index) => (

                                        <div className="col-md-3 my-custom-slide" key={index}>
                                            <div className="services-card-title">
                                                <h3>{item.title}</h3>
                                                <p>{item.content}</p>
                                                <Link to=""><FaLongArrowAltRight /></Link>
                                            </div>
                                        </div>

                                    ))}
                                </Slider>
                            ) : (
                                data.map((item, index) => (
                                    <div className="col-md-4" key={index}>
                                        <div className="services-card-title">
                                            <h3>{item.title}</h3>
                                            <p>{item.content}</p>
                                            <Link to=""><FaLongArrowAltRight /></Link>
                                        </div>
                                    </div>
                                ))
                            )
                        )
                    }
                </div>
            </div>
        </div>

    )
}

export default Services