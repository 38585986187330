/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TextField, MenuItem, Select, FormLabel, FormControl ,FormHelperText} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import Palette from '../../theme/palette'
import { apiput, apiget } from "../../service/api";

const Edit = ({ open, handleClose, setUserAction, id,fetchdata, singlePaymentMethodData }) => {
    const PaymentMethodData = singlePaymentMethodData;


    const initialValues = {
        paymentMethod: PaymentMethodData?.paymentMethod || "",
        type: PaymentMethodData?.type || '',
        createdBy: PaymentMethodData?.createdBy || "",
    };

    const validationSchema = yup.object({
        paymentMethod: yup.string().required("payment Method is required"),
        type: yup.string().required("Type is required"),

    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });



    const fileUpload = async (values) => {
        try {
            const data = new FormData()
            data.append("paymentMethod", values.paymentMethod)
            data.append("type", values.type)
            data.append("createdBy", values.createdBy)


            const result = await apiput(`payment-method/edit/${id}`, values);
            setUserAction(result);

            if (result && result.status === 200) {
                fetchdata();
                formik.resetForm();
                handleClose();
                
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data">
                        <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Payment Method</FormLabel>
                                <TextField
                                    id="paymentMethod"
                                    type="text"
                                    name="paymentMethod"
                                    size="small"
                                    fullWidth
                                    className="custom-textfield"
                                    value={formik.values.paymentMethod}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)
                                    }
                                    helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} mb={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Type</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"

                                        id="type"
                                        name="type"
                                        size='small'
                                        fullWidth
                                        value={formik.values.type}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.policyType &&
                                            Boolean(formik.errors.policyType)
                                        }
                                        helpertext={
                                            formik.touched.policyType && formik.errors.policyType
                                        }
                                    >

                                        <MenuItem key={'For Payment Pay'} value={'For Payment Pay'}>
                                            {'For Payment Pay'}
                                        </MenuItem>
                                        <MenuItem key={'For Payment Recieve'} value={'For Payment Recieve'}>
                                            {'For Payment Recieve'}
                                        </MenuItem>

                                    </Select>
                                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.policyType && formik.errors.policyType}</FormHelperText>
                                </FormControl>
                            </Grid>

                        </Grid>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </ >
    );
};

export default Edit;
