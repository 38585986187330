import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { FaStar, FaRegStar } from "react-icons/fa6";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import '../Home.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Laura from '../../../../Images/Home/Laura-Headly.png'
import David from '../../../../Images/Home/David-Mosses.png'
import Cristin from '../../../../Images/Home/Cristine -Mile.png'
import { apiget } from '../../../../service/api';
import { constant } from '../../../../constant';

const Testimonials = () => {
    const [testimonialData, setTestimonialData] = useState([]);


    const fetchTestimonialData = async () => {

        const result = await apiget(`testimonial/list`);
        if (result && result.status === 200) {
            const filterTestimonial =result?.data?.result.filter(item=>item.status==='On');
            setTestimonialData(filterTestimonial)
        }

    }

    useEffect(() => {
        fetchTestimonialData();
    }, [])


    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="testimonials">
            <div className="container">
                <div className="testimonials_insurance">
                    <span className="slider-top-heading-btn">
                        <h3>TESTIMONIALS</h3>
                        <div className="slider-btn-top">
                            <button className="arrowButton" onClick={previous} >
                                <MdArrowBackIos />
                            </button>
                            <button className="arrowButton" onClick={next}>
                                <MdArrowForwardIos />
                            </button>
                        </div>
                    </span>
                    <p>See what our customers have to say</p>
                </div>
                <div className="slider mySwiper">
                    <div className="image-items swiper-wrapper items_slider_padding">
                        <Slider
                            ref={slider => {
                                sliderRef = slider;
                            }}
                            {...settings}
                        >
                            {
                                testimonialData && testimonialData.length > 0 && testimonialData.map((item, index) => {

                                    const renderStars = (rating) => {
                                        const stars = [];
                                        for (let i = 1; i <= 5; i += 1) {
                                            stars.push(
                                                i <= rating ? <FaStar style={{ color: 'F1C000' }} key={i} className="slider_star" /> : <FaRegStar key={i} className="slider_star" />
                                            );
                                        }
                                        return stars;
                                    };
                                    return (
                                        <div>
                                            <div className="image swiper-slide" key={index}>
                                                <div className="home_insurance_slider_text">
                                                    <div className="container">
                                                        <span>{renderStars(item.rating)}</span>
                                                        <h3>{item.title}</h3>
                                                        <p>{item.content.length > 117 ? `${item.content.substring(0, 117)}...` : item.content}</p>
                                                        <hr />
                                                        <div className="testimonials_home">
                                                            <img className="testimonials_laura" src={`${constant.baseUrl}uploads/testimonials/${item.file}`} alt='' />
                                                            <h6>{item.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )

                                })
                            }


                        </Slider>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Testimonials