import React from 'react'
import relatedImg from '../../../../../Images/RelatedArticles/Minute-Read.png'
import relatedImg1 from '../../../../../Images/RelatedArticles/Product-Minute-Read.png'

const RelatedArticles = () => {
    const relatedArticlesData = [
        {
            img: relatedImg,
        },
        {
            img: relatedImg1,
        },
        {
            img: relatedImg,
        },
        {
            img: relatedImg1,
        },

    ]
    return (
        <div className="find_interesting">
            <div className="container">
                <h2>Here are some related articles you may find interesting:</h2>
                <div className="row">

                    {
                        relatedArticlesData.map((item, index) => (
                            <div key={index} className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="find_interesting_titel">
                                            <img src={item.img} alt='' />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="find_interesting_titel">
                                            <h4>Product 6 Minute Read</h4>
                                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie,
                                                dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default RelatedArticles