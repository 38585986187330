

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel, FormControl, FormHelperText, Select, MenuItem } from "@mui/material";
import { apipost } from "../../service/api";

const Add = (props) => {
    const { open, handleClose, setUserAction } = props;
    const userid = localStorage.getItem('user_id');

    // -----------  validationSchema
    const validationSchema = yup.object({
        referralSource: yup.string().required("Referral Source is required"),
        sourceType: yup.string().required("Source Type is required"),
    });

    // -----------   initialValues
    const initialValues = {
        referralSource: "",
        sourceType: "",
        createdBy: userid
    };

    // add contact api
    const fileUpload = async (values) => {
        const result = await apipost('referral-source/upload', values);
        setUserAction(result);

        if (result && result.status === 200) {
            formik.resetForm();
            handleClose();
            // toast.success(result.data.message);
        }
    };

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={()=>{
                    handleClose();
                    formik.resetForm();
                }}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle
                    id="dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Add New Referral Source</Typography>
                    <ClearIcon
                        onClick={()=>{
                            handleClose();
                            formik.resetForm();
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Referral Source<Typography component={'span'} color={'error'} >*</Typography></FormLabel>
                                <TextField
                                    id="referralSource"
                                    name="referralSource"
                                    size="small"
                                    fullWidth
                                    value={formik.values.referralSource}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.referralSource && Boolean(formik.errors.referralSource)
                                    }
                                    helperText={formik.touched.referralSource && formik.errors.referralSource}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>
                                    Referral Source Type<Typography component={'span'} color={'error'} >*</Typography>
                                </FormLabel>
                                <FormControl fullWidth error={formik.touched.sourceType && Boolean(formik.errors.sourceType)}>

                                    <Select
                                        labelId="sourceType"
                                        id="sourceType"
                                        name="sourceType"
                                        size="small"
                                        value={formik.values.sourceType}
                                        onChange={formik.handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="Lead">Lead</MenuItem>
                                        <MenuItem value="Contact">Contact</MenuItem>
                                    </Select>
                                    {formik.touched.sourceType && formik.errors.sourceType && (
                                        <FormHelperText>{formik.errors.sourceType}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>



                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm();
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Add;

