import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaArrowCircleRight } from "react-icons/fa";
import logo from '../../../Images/Navbar/logo.png'
import './Navbar.css'

const userid = localStorage.getItem('user_id');
const userRole = localStorage.getItem("userRole");

const Navbar = () => {
    
    return (
        <div className="home_navbar_top">
            <nav className="navbar navbar-expand-lg nav_bac">
                <div className="container">
                    <Link className="navbar-brand" to="/"><img src={logo} alt='' /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar_header">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" href="blog.html" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    Insurance
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/vehicle-insurance">Vehicle Insurance</Link></li>
                                    <li><Link className="dropdown-item" to="/health-insurance">Health Insurance</Link></li>
                                    <li><Link className="dropdown-item" to="/home-insurance">Property Insurance</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/blog" role="button">Blogs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/achievements">Achievements</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/help-centre">Help Center</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact-us">Contact</Link>
                            </li>
                        </ul>
                        <div className="home_btn">
                            {
                                userid ? <Link  className="login_btnn" to="/dashboard/policy">Dashboard <FaArrowCircleRight /></Link> : <Link  className="login_btnn" to="/login">Login <FaArrowCircleRight /></Link>
                            }
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar