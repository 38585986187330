import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Grid,
  DialogActions,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { apipost } from '../../../../service/api';

const AddPolicyType = ({ fetchdata, open, setOpen }) => {
  const userid = localStorage.getItem('user_id');

  const validationSchema = yup.object({
    policyTypes: yup.string().required('Policy Type is required'),
  });

  const initialValues = {
    policyTypes: '',
    createdBy: userid,
  };

  const fileUpload = async (values) => {
    const result = await apipost('policy-types/add', values);
 
    if (result && result.status === 200) {
      formik.resetForm();
      handleClose();
      fetchdata(); 
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      fileUpload(values);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle variant="h5">Add Policy Type</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 5, md: 4 }}>
            <Grid item xs={12}>
              <TextField
                label="Enter Policy Type"
                variant="outlined"
                fullWidth
                name="policyTypes"
                value={formik.values.policyTypes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.policyTypes && Boolean(formik.errors.policyTypes)}
                helperText={formik.touched.policyTypes && formik.errors.policyTypes}
                sx={{ marginTop: '16px', marginBottom: '16px' }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPolicyType;
