

import axios from "axios";
import { toast } from "react-toastify";
import { constant } from '../constant'


const getToken = () => {
    let token = localStorage.getItem('token');
    if (!token) {
        token = generateDefaultToken();
        localStorage.setItem('token', token);
    }
    return token;
};

const generateDefaultToken = () => {
    const randomPart = Math.random().toString(36).substr(2, 10);
    const defaultToken = `Bearer ${randomPart}`;
    return defaultToken;
};


export const apiget = async (path) => {

    try {

        const response = await axios.get(constant.baseUrl + path, {
            headers: {
                'Authorization': getToken()
            }

        });

        // eslint-disable-next-line dot-notation
        if (response.data.token && response.data.token !== null) {
            // eslint-disable-next-line dot-notation
            localStorage.setItem('token', response.data.token);
        }
        if (response && response.status === 200) {
            toast.success(response.data.message);
        }
        return response;
    } catch (error) {
        if (error && error.response) {
            if (error && error.response && error.response.status === 400) {
                if (error.response.data.message) {
                    toast.error(error.response.data.message)
                    console.log(error)
                }
            }
        }
        throw error;
    }
}

export const apipost = async (path, data) => {
 
    try {
        const response = await axios.post(constant.baseUrl + path, data, {
            headers: {
                'Authorization': getToken()
            }
        });
        if (response.data.token && response.data.token !== null) {
            localStorage.setItem('token', response?.data?.token);
        }

        if (response && response.status === 200) {
            toast.success(response.data.message);
        }
        return response;
    } catch (error) {
        if (error && error.response) {
            if (error && error.response.data && error.response.status === 401) {
                if (error.response.data.message) {
                    toast.error(error.response.data.message)
                }
            }
        }
        throw error;
    }

}

export const apiput = async (path, data) => {

    try {
        const response = await axios.put(constant.baseUrl + path, data, {
            headers: {
                'Authorization': getToken()
            }
        });

        if (response.data.token && response.data.token !== null) {
            localStorage.setItem('token', response.data.token);
        }
        if (response && response.status === 200) {
            toast.success(response.data.message);
        }
        return response;
    } catch (error) {
        if (error && error.response) {
            if (error && error.response && error.response.status === 400) {
                if (error.response.data.message) {
                    toast.error(error.response.data.message)
                }
            }
        }
        throw error;
    }

}

export const apidelete = async (path) => {

    try {

        const response = await axios.delete(constant.baseUrl + path, {
            headers: {
                'Authorization': getToken()
            }
        });
        if (response.data.token && response.data.token !== null) {
            localStorage.setItem('token', response.data.token);
        }
        if (response && response.status === 200) {
            toast.success(response.data.message);
        }

        return response;
    } catch (error) {
        if (error && error.response) {
            if (error && error.response && error.response.status === 400) {
                if (error.response.data.message) {
                    toast.error(error.response.data.message)
                }
            }
        }
        throw error;
    }

}

export const deleteManyApi = async (path, data) => {

    try {
        const response = await axios.post(constant.baseUrl + path, data, {
            headers: {
                'Authorization': getToken()
            }
        });
        if (response.data.token && response.data.token !== null) {
            localStorage.setItem('token', response?.data?.token);
        }

        if (response && response.status === 200) {
            toast.success(response.data.message);
        }
        return response;
    } catch (error) {
        if (error && error.response) {
            if (error && error.response.data && error.response.status === 401) {
                if (error.response.data.message) {
                    toast.error(error.response.data.message)
                }
            }
        }
        throw error;
    }

}

export const checkEmail = async ({ email, otp }) => {
    try {
        const response = await axios.get(`${constant.baseUrl}user/check-email?email=${email}&otp=${otp}`, {
            headers: {
                'Authorization': getToken(),
                'Content-Type': 'application/json'
            }
        });

        return response.data.success; // Assuming the backend sends { exists: true/false }
    } catch (error) {
        console.error('Error checking email and OTP existence:', error);
        return false; // Return false in case of error
    }
};
















export const apiSearch = async (path) => {

    try {

        const response = await axios.get(constant.baseUrl + path, {
            headers: {
                'Authorization': getToken()
            }
        });
        // eslint-disable-next-line dot-notation
        if (response.data.token && response.data.token !== null) {
            // eslint-disable-next-line dot-notation
            localStorage.setItem('token', response.data.token);
        }
        if (response && response.status === 200) {
            toast.success(response.data.message);
        }
        return response;
    } catch (error) {
        if (error && error.response) {
            if (error && error.response && error.response.status === 400) {
                if (error.response.data.message) {
                    toast.error(error.response.data.message)
                    console.log(error)
                }
            }
        }
        throw error;
    }
}