import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaRegCheckCircle } from "react-icons/fa";
import { TextField, Stack } from '@mui/material';
import { useFormik } from 'formik'
import * as yup from "yup";
import '../Auth.css'
import Signupbac from '../../../../Images/Auth/sign-up-background-banner.png'
import logo from '../../../../Images/Auth/logo.png'
import signupBanner from '../../../../Images/Auth/sign-pages-banner.png'

import { apipost } from '../../../../service/api'


const UserSignup = () => {


    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLogin] = useState(false);

    const initialValues = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phone: "",

    };

    // validationSchema
    const validationSchema = yup.object({
        firstName: yup.string().required("first name is required"),
        lastName: yup.string().required("last name is required"),
        emailAddress: yup.string().email('Invalid email').required("Email is required"),
        phone: yup.string().required("phone is required"),

    });

    const Adddata = async (values) => {
        setIsLogin(true);
        const data = values;

        const result = await apipost('user/register', data);

        if (result && result.status === 201) {
            // localStorage.setItem('user', JSON.stringify(result.data.user));
            // localStorage.setItem('user_id', result.data.user._id);
            // if (result.data.user.role) {
            //     localStorage.setItem('userRole', result.data.user.role);
            // }
            navigate('/login');
        }
        // else {
        //     navigate('/login');
        // }

        setIsLogin(false);
    };

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await Adddata(values);
        },
    });

    return (
        <div className="sign-up-pages">
            <img className="sign-up_bac_img" src={Signupbac} alt="" />
            <div className="container">
                <div className="login-pages-logo">
                    <img src={logo} alt='' />
                </div>
                <div className="sign-up-pages-d-f">
                    <div className="sing-up-pages-banner">
                        <img src={signupBanner} alt='' />
                    </div>
                    <div className="sign-up-pages-form">
                        <h2>Hello!</h2>
                        <h6>Sign Up</h6>


                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={1} mb={2}>
                                <div className="form-floating mb-4">
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        placeholder="First Name"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        fullWidth

                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                    />
                                </div>

                                <div className="form-floating mb-4">
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        placeholder="Last Name"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        fullWidth

                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                    />
                                </div>

                                <div className="form-floating mb-3">
                                    <TextField
                                        id="emailAddress"
                                        name="emailAddress"
                                        label="Email Address"
                                        placeholder="name@example.com"
                                        type="email"
                                        value={formik.values.emailAddress}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        fullWidth

                                        error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                        helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                                    />
                                </div>

                                <div className="form-floating mb-4">
                                    <TextField
                                        id="phone"
                                        name="phone"
                                        label="Phone"
                                        placeholder="Enter phone number"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        fullWidth

                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helperText={formik.touched.phone && formik.errors.phone}
                                    />
                                </div>

                                <div className="sing-terms-condition">
                                    <p><FaRegCheckCircle /> Hereby, I agree to the <span>Terms & Condition</span> and <span>Privacy Policy</span></p>
                                </div>

                                <div className="sing-up-btnn">
                                    <button type='submit'>Register</button>
                                </div>

                                <div className="sing-up-already-account">
                                    <h5>Already have an account? <Link to="/login">Login now</Link></h5>
                                </div>
                            </Stack>
                        </form>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default UserSignup