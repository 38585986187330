
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    Box,
} from '@mui/material';
// components

import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

// sections
// mock

import TableStyle from '../../components/TableStyle';
import { apiget, deleteManyApi ,apidelete} from '../../service/api';
import DeleteModel from '../../components/Deletemodle'
import AddPaymentSource from './Add';
import EditPaymentSource from './Edit';


// ----------------------------------------------------------------------

function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {

        setOpendelete(false)
    }


    const handleOpenDelete = async () => {

        setOpendelete(true)
    }

    const deleteManyPaymentSource = async (data) => {
        await deleteManyApi('payment-source/deletemany', data)
        fetchdata()
        handleCloseDelete();
    }

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyPaymentSource} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}


const PaymentSource = () => {

    const [paymentSource, setPaymentSource] = useState([]);
    const [open, setOpen] = useState(false);
    const [userAction, setUserAction] = useState(null);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState('');


    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")


    const deleteFile = async (id) => {
        const result = await apidelete(`payment-source/delete/${id}`)
        setUserAction(result)
    }


    const columns = [
        {
            field: "paymentSource",
            headerName: "Payment Source",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",

        },
        {
            field: "action",
            headerName: "Action",
            flex: 0,
            minWidth: 150, 
            maxWidth: 200,
            renderCell: (params) => {

                const handleOpenEditDialog = async (data) => {
                    setOpen(true);
                    setEditId(data);

                };
                return (
                    <>
                        <Stack direction={"row"} spacing={1} >
                            <Button
                                variant='text'
                                size='small'
                                color='primary'
                                onClick={() => handleOpenEditDialog(params?.row?._id)}
                            >
                                <EditIcon />
                            </Button>
                            <Button variant='outlined' size='small' color='error' onClick={() => deleteFile(params.row._id)}>Delete</Button>
                        </Stack>
                    </>
                );
            }
        },
    ];


    const fetchdata = async () => {
        const result = await apiget('payment-source/list')
        
        if (result && result.status === 200) {
            setPaymentSource(result?.data);
        }

    }

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };

    useEffect(() => {
        fetchdata();
    }, [userAction])
    return (
        <>
            <EditPaymentSource fetchdata={fetchdata} setOpen={setOpen} open={open} editId={editId} />
            <Container>
                <TableStyle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4">
                            Payment Source
                        </Typography>
                        <Button >
                            <AddPaymentSource fetchdata={fetchdata} />
                        </Button>
                    </Stack>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={paymentSource}
                                columns={columns}
                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                            />
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default PaymentSource