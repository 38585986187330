import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import './OurTeam.css'
import { apiget } from '../../../../service/api';
import { constant } from '../../../../constant';

const OurTeam = () => {
    const [ourTeamData, setOurTeamData] = useState([]);

    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };
    const fetchTeamData = async () => {
        const result = await apiget('team/list');
        setOurTeamData(result?.data?.result)
    }


    useEffect(() => {
        fetchTeamData();
    }, [])

    return (
        <div className='our-team-container'>
            <div className='our-team-wrapper container'>
                <div className='our-team-heading'>
                    <h2>Meet Our Team</h2>
                    <p>Meet the talented individuals who make our company great. Dedicated, passionate, and innovative,
                        our team works together to deliver exceptional results and drive our mission forward. Get to know us better!</p>
                </div>
                {ourTeamData && ourTeamData.length > 6 ? (
                    <div className="slider-container">
                        <Slider {...settings}>
                            {ourTeamData.map((item, index) => (
                                <div key={index}>
                                    <div className='main-single-slide'>
                                        <div className='our-team-img'>
                                            <img src={`${constant.baseUrl}uploads/ourTeam/${item.file}`} alt='' />
                                        </div>
                                        <div className='our-team-bottom-text'>
                                            <h3>{item.fullName}</h3>
                                            <p>{item.position}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                ) : (
                    <div className="row justify-content-center">
                        {ourTeamData.map((item, index) => (
                            <div className="col-sm-12 col-md-3 text-center" key={index}>
                                <div className='main-single-slide'>
                                    <div className='our-team-img'>
                                        <img src={`${constant.baseUrl}uploads/ourTeam/${item.file}`} alt='' />
                                    </div>
                                    <div className='our-team-bottom-text'>
                                        <h3>{item.fullName}</h3>
                                        <p>{item.position}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>


    )
}

export default OurTeam