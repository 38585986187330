import React, { useEffect, useState } from 'react'
import './BlogDetail.css'
import { useParams } from 'react-router-dom'
import BlogDetailImg from '../../../../Images/Blog/Feature.png'
import BlogAuthorImg from '../../../../Images/Blog/Author.png'
import BlogDetailMiddle from './BlogDetailMiddle/BlogDetailMiddle'
import RelatedArticles from './RelatedArticles/RelatedArticles'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import { apiget } from '../../../../service/api'
import { constant } from '../../../../constant'

const BlogDetail = () => {

    const {id}= useParams();
    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")
    const [blogDetail,setBlogDetail]=useState([]);
   
    const date = new Date(blogDetail.createdOn);
    const day = date.getDate(); // Get the day (1-31)
    const month = date.toLocaleString('default', { month: 'long' }); // Get the month name
    const year = date.getFullYear();
    
    const blogAuthorData = [
        {
            tag: <img src={BlogAuthorImg} alt='' />,
            class: "jordan_mirchev_banner"
        },
        {
            tag: <h5>By</h5>,
            class: "jordan_mirchev_text"
        },
        {
            tag: <h5>Jordan Mirchev</h5>,
            class: "jordan_mirchev_text"
        },
        {
            tag: <h5>|</h5>,
            class: "jordan_mirchev_text"
        },
        {
            tag: <h5>Published on</h5>,
            class: "jordan_mirchev_text"
        },
        {
            tag: <h5>{month} {day}, {year}</h5>,
            class: "jordan_mirchev_text"
        },
        {
            tag: <h5>|</h5>,
            class: "jordan_mirchev_text"
        },
        {
            tag: <h5>2 min read</h5>,
            class: "jordan_mirchev_text"
        }
    ];

    const fetchBlogDetail  = async () => {

        const result = await apiget(`blog-management/view/${id}`)
        if (result && result.status === 200) {
          
            setBlogDetail(result?.data?.blogs);
        }

    }

   useEffect(()=>{
    fetchBlogDetail();
   },[])


    return (
        <>

            <div className="my">
                <div className='container'>
                    <Navbar />
                </div>
                <div className="consectetur_adipiscing">
                    <div className="container">
                        <h3>{blogDetail.title}</h3>
                    </div>
                </div>
                <div className="jordan_mirchev_background">
                    <div className="container">
                        <div className="jordan_mirchev">
                            {
                                blogAuthorData.map((item, index) => (
                                    <div key={index} className={item.class}>
                                        {item.tag}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="feature_banner">
                    <div className="container">
                        <img src={`${constant.baseUrl}uploads/blogs/${blogDetail.file}`} alt='' />
                    </div>
                </div>
                <BlogDetailMiddle content={blogDetail.content} />
                <RelatedArticles />
            </div>
            <Footer />

        </>
    )
}

export default BlogDetail