/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel, Rating } from "@mui/material";
import { apiput, apiget } from "../../service/api";

const Edit = ({ open, handleClose, setUserAction, id, editTestimonialData, singleTestimonialData }) => {
    const TestimonialData = singleTestimonialData;


    const initialValues = {
        // file: TestimonialData?.file || "",
        // name: TestimonialData?.name || "",
        title: TestimonialData?.title || "",
        content: TestimonialData?.content || "",
        rating: TestimonialData?.rating || 0,
        status: TestimonialData?.status || 'On',
        createdBy: TestimonialData?.createdBy || "",

    };

    const validationSchema = yup.object({
        // file: yup.string(),
        // name: yup.string().required("name is required"),
        title: yup.string().required("Title is required"),
        content: yup.string().required("Content is required"),
        rating: yup.string().required("Rating is required"),
        status: yup.string(),
        createdBy: yup.string(),
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });

    const handleChange = (event) => {
        formik.handleChange(event);
    };


    const fileUpload = async (values) => {
        try {

            const result = await apiput(`testimonial/edit/${id}`, values);
            setUserAction(result);
            if (result && result.status === 200) {
                formik.resetForm();

                handleClose();
                // toast.success(result.data.message)
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                onClose={()=>{
                    handleClose();
                    formik.resetForm();
                }}
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={()=>{
                                handleClose();
                                formik.resetForm();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Upload Image</FormLabel>
                                <TextField
                                    id="file"
                                    name="file"
                                    size="small"
                                    maxRows={10}
                                    fullWidth
                                    type="file"

                                    multiple
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => {
                                        formik.setFieldValue("file", event.currentTarget.files[0]);
                                    }}
                                    error={
                                        formik.touched.file &&
                                        Boolean(formik.errors.file)
                                    }
                                    helperText={
                                        formik.touched.file && formik.errors.file
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Name</FormLabel>
                                <TextField
                                    id="name"
                                    name="name"
                                    size="small"
                                    fullWidth
                                    value={formik.values.name}
                                    onChange={handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    aria-label="name"
                                />
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Title</FormLabel>
                                <TextField
                                    id="title"
                                    name="title"
                                    size="small"
                                    fullWidth
                                    value={formik.values.title}
                                    onChange={handleChange}
                                    error={
                                        formik.touched.title && Boolean(formik.errors.title)
                                    }
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel component="legend">Rating</FormLabel>
                                <Rating
                                    id="rating"
                                    name="rating"
                                    value={formik.values.rating}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue('rating', newValue);
                                    }}
                                    size="large"
                                    aria-label="rating"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Content</FormLabel>
                                <TextField
                                    id="content"
                                    name="content"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    value={formik.values.content}
                                    onChange={handleChange}
                                    error={
                                        formik.touched.content && Boolean(formik.errors.content)
                                    }
                                    helperText={formik.touched.content && formik.errors.content}
                                />
                            </Grid>
                        </Grid>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        style={{ textTransform: "capitalize" }}
                        onClick={formik.handleSubmit}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm();
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Edit;
