/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box, Grid } from '@mui/material';
// components
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from '@mui/icons-material/Edit';
import AttachmentIcon from '@mui/icons-material/Attachment';

// sections
import AddDocument from './Add'
import Edit from './Edit';
import { apidelete, apiget, apiput, deleteManyApi } from '../../service/api';
import TableStyleTwo from '../../components/TableStyleTwo';
import DeleteModel from '../../components/Deletemodle'

// ----------------------------------------------------------------------
const userRole = localStorage.getItem('userRole');
const FamilyTree = ({ rows, toggleVisibilityContact, isVisibleContact, setUserAction, _id }) => {

    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [contactId, setContactId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false)
    const [familyTreeData, setFamilyTreeData] = useState([]);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);





    const fetchFamilyEdit = async (rowId) => {
        setContactId(rowId)
        const result = await apiget(`contact-relation/view/${rowId}`);
        setFamilyTreeData(result?.data)
    }

    const deleteMany = async (deleteId) => {
        const result = await deleteManyApi('contact-relation/deletemany', deleteId)
        if (result && result.status === 200) {
            setUserAction(result)
        }
    }


    const columns = [
        {
            field: "firstName",
            headerName: "FirstName",
            flex: 1,
            minWidth: 100,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {

                const firstName = () => {
                    fetchFamilyEdit(params.row._id);
                    handleOpenEdit();
                }
                return (
                    <Box onClick={firstName}>
                        {params.value}
                    </Box>
                )
            }
        },
        {
            field: "lastName",
            headerName: "LastName",
            flex: 1,
            minWidth: 100
        },
        {
            field: "gender",
            headerName: "Gender",
            flex: 1,
            minWidth: 100
        },
        {
            field: "emailAddress",
            headerName: "Email Address",
            flex: 1,
            minWidth: 150
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            flex: 1,
            minWidth: 150
        },
        {
            field: "relation",
            headerName: "Relation",
            flex: 1,
            minWidth: 100
        },
        {
            field: "pan",
            headerName: "Pan Number",
            flex: 1,
            minWidth: 150
        },
        {
            field: "aadhar",
            headerName: "Aadhar Number",
            flex: 1,
            minWidth: 150
        },
        {
            field: "dateOfBirth",
            headerName: "DOB",
            flex: 1,
            minWidth: 120
        },
        {
            field: "dateOfAnniversary",
            headerName: "Anniversry Date",
            flex: 1,
            minWidth: 120
        },
        userRole === 'admin' && {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (

                <Stack direction={"row"} key={params.row._id} spacing={2} justifyContent={'center'}>
                    <DeleteIcon variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='error' onClick={(e) => {
                        e.preventDefault();
                        handleOpenDelete();
                    }} />

                    <DeleteModel opendelete={openDelete} handleClosedelete={handleCloseDelete} deletedata={deleteMany} id={[params.row._id]} />
                    <EditIcon variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='primary' onClick={(e) => {
                        e.preventDefault();
                        fetchFamilyEdit(params.row._id);
                        handleOpenEdit();
                    }} />
                </Stack>

            )
        },
    ];


    return (
        <>
            {/* Add Document Model */}
            <AddDocument open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} />
            <Edit open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} rowId={contactId} data={familyTreeData} fetchFamilyEdit={fetchFamilyEdit} />

            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityContact}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisibleContact ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5"> Family Tree</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisibleContact && userRole === 'admin' && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Add Family
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisibleContact &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                        />
                    </Box>
                </TableStyleTwo>

            }
        </>
    );
}

export default FamilyTree