/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel, Rating, FormControl, Select, MenuItem } from "@mui/material";
import { apiput, apiget } from "../../service/api";

const Edit = ({ open, handleClose, setUserAction, id, RankingData }) => {



    const initialValues = {
        rank: RankingData?.rank || "",
        to: RankingData?.to || "",
        from: RankingData?.from || "",
        createdBy: RankingData?.createdBy || "",
    };

    const validationSchema = yup.object({
        rank: yup.string().required("Rank Name is required"),
        to: yup.number().required("Amount to is required"),
        from: yup.number().required("Amount From required"),
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
        validate: values => {
            const errors = {};

            if (values.from < 0) {
                errors.from = "Amount From must be a non-negative number";
            }
            if (values.to < 0) {
                errors.to = "Amount To must be a non-negative number";
            }

            // Ensure 'from' is not greater than 'to'
            if (values.from && values.to && values.from > values.to) {
                errors.from = "Amount From must be less than or equal to Amount To";
                errors.to = "Amount To must be greater than or equal to Amount From";
            }

            return errors;
        },
    });


    const fileUpload = async (values) => {
        try {
            const data = new FormData()
            data.append("rank", values.rank)
            data.append("to", values.to)
            data.append("from", values.from)
            data.append("createdBy", values.createdBy)
            const result = await apiput(`ranking/edit/${id}`, values);
            setUserAction(result);

            if (result && result.status === 200) {
                formik.resetForm();
                handleClose();
                // toast.success(result.data.message)
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Rank Name</FormLabel>
                                <TextField
                                    id="rank"
                                    name="rank"
                                    size="small"
                                    fullWidth
                                    value={formik.values.rank}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.rank && Boolean(formik.errors.rank)
                                    }
                                    helperText={formik.touched.rank && formik.errors.rank}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Amount From</FormLabel>
                                <TextField
                                    id="from"
                                    name="from"
                                    size="small"
                                    fullWidth
                                    value={formik.values.from}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.from && Boolean(formik.errors.from)
                                    }
                                    helperText={formik.touched.from && formik.errors.from}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Amount To</FormLabel>
                                <TextField
                                    id="to"
                                    name="to"
                                    size="small"
                                    fullWidth
                                    value={formik.values.to}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.to && Boolean(formik.errors.to)
                                    }
                                    helperText={formik.touched.to && formik.errors.to}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ textTransform: "capitalize" }}
                            >
                                Save
                            </Button>
                            <Button
                                type="reset"
                                variant="outlined"
                                style={{ textTransform: "capitalize" }}
                                color="error"
                                onClick={() => {
                                    formik.resetForm();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Edit;
