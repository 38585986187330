import React from 'react'
import './Achievement.css'
import Navbar from '../Navbar/Navbar'
import AchievementSliderMiddle from './AchievementSliderMiddle/AchievementSliderMiddle'
import AchievementBottomSlider from './AchievementBottomSlider/AchievementBottomSlider'
import Footer from '../Footer/Footer'


const Achievement = () => {
    return (
        <>
            <div className="achievements_background">
                <div className='container'>
                    <Navbar />
                    <div className="achievements_text">
                        <h3>Awards &</h3>
                        <h4>Achievements</h4>
                        <p>Achievements are a testament to our unwavering commitment to excellence and our relentless pursuit of
                            innovation and customer satisfaction. As we continue to grow and evolve, we remain dedicated to
                            serving our customers with integrity, reliability, and excellence in everything we do.</p>
                    </div>
                </div>
            </div>
            <div className='achievement-slide-container'>
                <AchievementSliderMiddle />
            </div>
            <AchievementBottomSlider/>
            <Footer/>

        </>
    )
}

export default Achievement