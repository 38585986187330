// import React, { useEffect, useState } from 'react'
// import '../Home.css'
// import { useFormik } from "formik";
// import * as yup from "yup";
// import { MdPolicy } from "react-icons/md";
// import { FaUser, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
// import getFreeImg from '../../../../Images/Home/get-free-estimate.png'
// import getfreeImgBac from '../../../../Images/Home/get-free-estimate-bac.png'
// import { apiget, apipost } from '../../../../service/api';


// const EnquiryForm = () => {
//     const [policyTypes, setPolicyTypes] = useState([]);

//     const userid = localStorage.getItem('user_id')

//     const validationSchema = yup.object({
//         fullName: yup.string().required("fullname is required"),
//         phone: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid').required('Phone number is required'),
//         email: yup.string().email('Invalid email').required('Email is required'),
//         insurance: yup.string().required("Insurance Type is required"),
//     });

//     // -----------   initialValues
//     const initialValues = {
//         fullName: "",
//         phone: "",
//         email: "",
//         insurance: "",
//         createdBy: userid
//     };

//     const fileUpload = async (values) => {

//         // try {
//         //     const data = new FormData()
//         //     data.append("fullName", values.fullName)
//         //     data.append("phone", values.phone)
//         //     data.append("email", values.email)
//         //     data.append("insurance", values.insurance)
//         //     data.append("createdBy", values.createdBy)

//         //     const result = await apipost(`enquiry/add`, data)

//         //     if (result && result.status === 200) {
//         //         console.log('Form submitted successfully:', result.data);
//         //         formik.resetForm();
//         //     } else {
//         //         throw new Error('Failed to submit form');
//         //     }
//         // } catch (error) {
//         //     console.error('Error submitting form:', error);
//         // }
//     };


//     const formik = useFormik({
//         initialValues,
//         validationSchema,
//         onSubmit: async (values) => {
//             console.log('Submitting form:', values);
//             await fileUpload(values);
//         },
//     });


//     const fetchPolicyType = async () => {
//         try {
//             const result = await apiget(`policy-types/list`);
//             if (result && result.status === 200) {
//                 setPolicyTypes(result?.data);
//             } else {
//                 throw new Error('Failed to fetch policy types');
//             }
//         } catch (error) {
//             console.error('Error fetching policy types:', error);

//         }
//     };



//     useEffect(() => {
//         fetchPolicyType();
//     }, []);

//     return (
//         <div className="get_free_estimate">
//             <div className="container">
//                 <div className="get_free_estimate_d_f">
//                     <div className="get_estimate_post">
//                         <div className="get-bac-banner-4">
//                             <img src={getfreeImgBac} alt="" />
//                         </div>
//                         <div className="get-bac-banner-top-5">
//                             <img src={getFreeImg} alt="" />
//                         </div>
//                     </div>

//                     <div className="get_free_estimate_text">
//                         <div className='row'>
//                             <div className='col-md-11 offset-md-1'>
//                                 <h3>GET A FREE ESTIMATE</h3>
//                                 <h4>Get an insurance quote to get started</h4>
//                             </div>
//                         </div>
//                         <form onSubmit={formik.handleSubmit} className="row" >

//                             <div className="col-md-5 offset-md-1">
//                                 <div className="inputWithIcon">
//                                     <FaUser />
//                                     <input type="text" placeholder="Full Name"
//                                         value={formik.values.fullName} id="fullName"
//                                         onChange={formik.handleChange} name="fullName" />
//                                 </div>
//                             </div>
//                             <div className="col-md-5">
//                                 <div className="inputWithIcon">
//                                     <FaEnvelope />
//                                     <input type="text" placeholder="Email Address"
//                                         value={formik.values.email} id="email"
//                                         onChange={formik.handleChange} name="email"
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-5 offset-md-1">
//                                 <div className="inputWithIcon">
//                                     <FaPhoneAlt />
//                                     <input type="text" placeholder="Phone"
//                                         value={formik.values.phone} id="phone"
//                                         onChange={formik.handleChange} name="phone"
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-5">
//                                 <div className="input-group">
//                                     <span className="input-group-text">
//                                         <MdPolicy />
//                                     </span>
//                                     <select
//                                         className="form-select"
//                                         aria-label="Type of Insurance"
//                                         value={formik.values.insurance}
//                                         onChange={formik.handleChange}
//                                         name="insurance"
//                                     >
//                                         <option value="" disabled selected>Type of Insurance</option>
//                                         {policyTypes &&
//                                             policyTypes.length > 0 &&
//                                             policyTypes.map((item, index) => (
//                                                 <option key={index} value={item.policyTypes}>
//                                                     {item.policyTypes}
//                                                 </option>
//                                             ))}
//                                     </select>
//                                 </div>
//                             </div>
//                             <div className='row'>
//                                 <div className="Get_Quote col-md-10 offset-md-1">
//                                     <button type="submit" onClick={formik.handleSubmit} >Get Quote</button>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>

//                 </div>
//             </div>
//         </div>
//     )
// }

// export default EnquiryForm


import React, { useEffect, useState } from 'react'
import '../Home.css'
import { useFormik } from "formik";
import * as yup from "yup";
import { MdPolicy } from "react-icons/md";
import { FaUser, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import getFreeImg from '../../../../Images/Home/get-free-estimate.png'
import getfreeImgBac from '../../../../Images/Home/get-free-estimate-bac.png'
import { apiget, apipost } from '../../../../service/api';

const EnquiryForm = () => {
    const [policyTypes, setPolicyTypes] = useState([]);

    const userid = localStorage.getItem('user_id');

    const validationSchema = yup.object({
        fullName: yup.string().required("Full name is required"),
        phone: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid').required('Phone number is required'),
        email: yup.string().email('Invalid email').required('Email is required'),
        insurance: yup.string().required("Insurance Type is required"),
    });

    const initialValues = {
        fullName: "",
        phone: "",
        email: "",
        insurance: "",
        createdBy: userid
    };

    const fileUpload = async (values) => {
        try {
           

            const result = await apipost(`enquiry/add`, values);
            
            if (result && result.status === 200) {
                console.log('Form submitted successfully:', result.data);
                formik.resetForm(); // Reset form after successful submission
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            console.log('Submitting form:', values);
            await fileUpload(values);
        },
    });

    const fetchPolicyType = async () => {
        try {
            const result = await apiget(`policy-types/list`);
            if (result && result.status === 200) {
                setPolicyTypes(result?.data);
            } else {
                throw new Error('Failed to fetch policy types');
            }
        } catch (error) {
            console.error('Error fetching policy types:', error);
        }
    };

    useEffect(() => {
        fetchPolicyType();
    }, []);

    return (
        <div className="get_free_estimate">
            <div className="container">
                <div className="get_free_estimate_d_f">
                    <div className="get_estimate_post">
                        <div className="get-bac-banner-4">
                            <img src={getfreeImgBac} alt="" />
                        </div>
                        <div className="get-bac-banner-top-5">
                            <img src={getFreeImg} alt="" />
                        </div>
                    </div>

                    <div className="get_free_estimate_text">
                        <div className='row'>
                            <div className='col-md-11 offset-md-1'>
                                <h3>GET A FREE ESTIMATE</h3>
                                <h4>Get an insurance quote to get started</h4>
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} className="row" >
                            <div className="col-md-5 offset-md-1">
                                <div className="inputWithIcon">
                                    <FaUser />
                                    <input type="text" placeholder="Full Name"
                                        value={formik.values.fullName} id="fullName"
                                        onChange={formik.handleChange} name="fullName" />
                                    {formik.touched.fullName && formik.errors.fullName ? (
                                        <div className="error">{formik.errors.fullName}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="inputWithIcon">
                                    <FaEnvelope />
                                    <input type="text" placeholder="Email Address"
                                        value={formik.values.email} id="email"
                                        onChange={formik.handleChange} name="email" />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="error">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <div className="inputWithIcon">
                                    <FaPhoneAlt />
                                    <input type="text" placeholder="Phone"
                                        value={formik.values.phone} id="phone"
                                        onChange={formik.handleChange} name="phone" />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div className="error">{formik.errors.phone}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <MdPolicy />
                                    </span>
                                    <select
                                        className="form-select"
                                        aria-label="Type of Insurance"
                                        value={formik.values.insurance}
                                        onChange={formik.handleChange}
                                        name="insurance"
                                    >
                                        <option value="" disabled>Type of Insurance</option>
                                        {policyTypes &&
                                            policyTypes.length > 0 &&
                                            policyTypes.map((item, index) => (
                                                <option key={index} value={item.policyTypes}>
                                                    {item.policyTypes}
                                                </option>
                                            ))}
                                    </select>
                                    {formik.touched.insurance && formik.errors.insurance ? (
                                        <div className="error">{formik.errors.insurance}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="Get_Quote col-md-10 offset-md-1">
                                    <button type="submit">Get Quote</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default EnquiryForm;
