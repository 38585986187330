import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
// components
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import Iconify from '../../components/iconify';
import AddAchievements from './Add'
import { apidelete, apiget, deleteManyApi } from '../../service/api';
import TableStyle from '../../components/TableStyle';
import DeleteModel from '../../components/Deletemodle'
import constant from '../../constant'
import EditAchievements from './Edit'
import View from '../HelpCentreManagement/view'


function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyContact = async (data) => {
        await deleteManyApi('achievement-management/deletemany', data)
        fetchdata()
        handleCloseDelete();
    }

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}

const Achievements = () => {
    const [userAction, setUserAction] = useState(null);
    const [AchievementsList, setAchievementsList] = useState([]);
    const [AchievementsData, setAchievementsData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState("");

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")

    const handleOpenAdd = () => {
        setOpenAdd(true)
    }
    const handleOpenView = () => {
        setOpenView(true)
    }
    const handleCloseAdd = () => {
        setOpenAdd(false)
    }
    const handleCloseEdit = () => {
        setOpenEdit(false);
    }

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };


    // file delete api
    const deleteFile = async (id) => {
        const result = await apidelete(`achievement-management/delete/${id}`)
        setUserAction(result)
    }
    const editAchievementsData = async (id) => {

        try {
            const result = await apiget(`achievement-management/view/${id}`);

            if (result && result.status === 200) {

                setAchievementsData(result?.data?.achievements)
            }
        } catch (error) {
            console.error("Error fetching Achievements data:", error);
        }
    };

    const columns = [
        {
            field: "file",
            headerName: "File",
            flex: 1,
        },

        {
            field: "title",
            headerName: "Title",
            flex: 1,

        },
        {
            field: "content",
            headerName: "Content",
            flex: 1,

        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 150,
            maxWidth: 150,
            renderCell: (params) => {

                const handleOpenEditDialog = async (data) => {

                    setOpenEdit(true);
                    editAchievementsData(data);
                    setEditId(data)
                }

                return (

                    <Stack direction={"row"} spacing={3}>
                        <EditIcon
                            variant='text'
                            sx={{ cursor: 'pointer' }}
                            size='small'
                            color='primary'
                            onClick={() => handleOpenEditDialog(params?.row?._id)}
                        />
                        <DeleteIcon variant='outlined' size='small' color='error'
                            sx={{ cursor: 'pointer' }}
                            onClick={() => deleteFile(params.row._id)} />

                        <Visibility variant='outlined' size='small' color='primary'
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                handleOpenView();
                                editAchievementsData(params.row._id);
                            }} />
                    </Stack>

                );
            }
        },

    ];

    // fetch Achievementss list
    const fetchdata = async () => {
        const result = await apiget(userRole === "admin" ? `achievement-management/list` : `achievement-management/list/?createdBy=${userid}`)
        if (result && result.status === 200) {
            setAchievementsList(result?.data?.result)
        }
    }




    useEffect(() => {
        fetchdata();
    }, [userAction])
    return (
        <>
            {/* Add Achievements Model */}
            <AddAchievements open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
            <EditAchievements open={openEdit} singleAchievementsData={AchievementsData} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} />
            <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={AchievementsData} header="Achievement" imageFolder={"achievements"} />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Achievements
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                        Achievements
                    </Button>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={AchievementsList}
                                columns={columns}
                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                            />
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default Achievements