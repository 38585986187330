import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { FaGoogle ,FaFacebook } from "react-icons/fa";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import './Auth.css';
import logbac from '../../../Images/Auth/login-background-banner.png';
import logo from '../../../Images/Auth/logo.png';
import logoPageBanner from '../../../Images/Auth/login-pages-banner.png';
import { apipost, checkEmail } from '../../../service/api';

const UserLogin = () => {
    const navigate = useNavigate();
    const [loginOtp, setLoginOtp] = useState(null);
    const [isOTP, setIsOTP] = useState(false);
    const [isSendOTPDisabled, setIsSendOTPDisabled] = useState(false);

    const initialValues = {
        emailAddress: '',
        otp: '',
    };

    const validationSchema = yup.object({
        emailAddress: yup.string().email('Invalid email').required('email is required'),
        otp: yup.string().length(4, 'OTP must be 4 characters').required('OTP is required'), // Validate OTP field
    });

    const generateOTP = () => {
        return Math.floor(1000 + Math.random() * 9000).toString();
    };


    const checkEmailExists = async (email, otp) => {
        const response = await checkEmail({ email, otp });
        return response;
    };

    const handleSendOTP = async () => {
        setIsSendOTPDisabled(true);
        const email = formik.values.emailAddress;

        const otp = generateOTP();
        const exists = await checkEmailExists(email, otp);

        if (exists) {

            setIsOTP(true);
            setLoginOtp(otp)
            toast.success('Otp send to your registered email');

            setTimeout(() => {
                setIsOTP(true);
                setIsSendOTPDisabled(false);
            }, 5 * 60 * 1000);
        } else {
            toast.error('Email does not exist please enter correct email.');
            setIsSendOTPDisabled(false);
        }
    };

    const Adddata = async (values) => {
        const data = values;


        if (loginOtp === formik.values.otp) {
            try {
                const result = await apipost('user/user-login', data);

                if (result && result.status === 200 && result.data && result.data.user) {
                    localStorage.setItem('user', JSON.stringify(result.data.user));
                    localStorage.setItem('user_id', result.data.user._id);
                    if (result.data.user.role) {
                        localStorage.setItem('userRole', result.data.user.role);
                    }
                    navigate('/dashboard/policy');
                } else {
                    throw new Error('Login failed. Please try again.');
                }
            } catch (error) {
                toast.error(error.message);
            }
        } else {
            toast.error('Invalid OTP. Please enter the correct OTP.');
        }
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await Adddata(values);
        },
    });

    return (
        <div className="login-pages">
            <img className="login_bac_img" src={logbac} alt="" />
            <div className="login-main-container">
                <div className="login-pages-logo">
                    <img src={logo} alt="" />
                </div>
                <div className="login-pages-d-f">
                    <div className="login-pages-banner">
                        <img src={logoPageBanner} alt="" />
                    </div>
                    <div className="login-pages-form">
                        <h2>Login</h2>
                        <p>Enter your email and OTP <br />to sign in to your account</p>
                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={3} mb={2}>
                                {
                                    !isOTP ? 
                                    <div className="form-floating  mb-4">

                                        <TextField
                                            name="emailAddress"
                                            label="Email"
                                            value={formik.values.emailAddress}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            error={
                                                formik.touched.emailAddress &&
                                                Boolean(formik.errors.emailAddress)
                                            }
                                            helperText={
                                                formik.touched.emailAddress && formik.errors.emailAddress
                                            }
                                        />
                                        {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                            <div className="error-message">{formik.errors.emailAddress}</div>
                                        ) : null}
                                    </div> :
                                        <div className="form-floating  mb-4">
                                        
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                id="otp"
                                                name="otp"
                                                label="Enter OTP"
                                                value={formik.values.otp}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.otp && Boolean(formik.errors.otp)}
                                                helperText={formik.touched.otp && formik.errors.otp}
                                            />
                                            {formik.touched.otp && formik.errors.otp ? (
                                                <div className="error-message">{formik.errors.otp}</div>
                                            ) : null}
                                        </div>

                                }


                                <div className="Login-btnn">
                                    {isOTP ? <button type='submit'>Login</button>
                                        : <button type="button"
                                            onClick={handleSendOTP}
                                            disabled={isSendOTPDisabled}
                                        >Send OTP</button>
                                    }
                                </div>
                            </Stack>
                        </form>
                        <div className="login-pages-sign-with">
                            <div className="sign-in-with-text">
                                <a href="">Or Sign in with</a>
                            </div>
                            <div className="sign-in-with-icen">
                                <a href=""><FaGoogle /></a>
                                <a href=""><FaFacebook /></a>
                            </div>
                            <p>Don’t have an account? <Link className="login-sing-up" to="/signup">Sign up</Link> now</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserLogin;
