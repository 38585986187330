import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../Navbar/Navbar'
import '../Insurance_common_css.css'
import homeBac1 from '../../../../Images/Insurance/home-insurance-bac-banner-one.png'
import homeBac2 from '../../../../Images/Insurance/home-insurance-bac-banner-two.png'
import homeDsgn from '../../../../Images/Insurance/home-design.png'
import Services from '../Insurance_Components/Services'
import CustomerServices from '../Insurance_Components/CustomerServices'
import InsurancePlan from '../Insurance_Components/InsurancePlan'
import Footer from '../../Footer/Footer'
import { apiget } from '../../../../service/api';
import AccordianComponent from '../../HelpCentre/AccordianComponent'


const HomeInsurance = () => {
   
    const [propertyPlans, setPropertyPlans] = useState([]);
    const [servicePlans, setServicePlans] = useState([]);
    const [faq, setFaq] = useState([]);


    const fetchPropertyPlans = async () => {
        const result = await apiget('health-insurance-plans/list');
        if (result && result.status === 200) {
            const filteredPlans = result.data.result.filter(plan =>
                plan.page && plan.page.toLowerCase().includes("property")
            );
            setPropertyPlans(filteredPlans);
        }
    }


    const fetchFaq = async () => {
        try {
            const result = await apiget('article/list');
            if (result && result.status === 200) {
                const filteredPlans = result.data.result.filter(plan =>
                    plan.page && plan.page.toLowerCase().includes("property")
                );
                setFaq(filteredPlans);
            }
        } catch (error) {
            console.error("Error fetching service plans:", error);
        }
    }

    const fetchServicePlans = async () => {
        try {
            const result = await apiget('service-plans/list');
            if (result && result.status === 200) {
                const filteredPlans = result.data.result.filter(plan =>
                    plan.page && plan.page.toLowerCase().includes("property")
                );
                setServicePlans(filteredPlans);
            }
        } catch (error) {
            console.error("Error fetching service plans:", error);
        }
    }

    useEffect(() => {
        fetchFaq();
        fetchServicePlans();
        fetchPropertyPlans();
    }, []);

    return (
        <>
            <div className="home-insurance-background-banner">
                <div className="container">
                    <Navbar />
                    <div className="health-Insurance-home-banner-text">
                        <h3>A Life Full Of Care</h3>
                        <h2>Protect Your Dream House with Simple Home Insurance</h2>
                        <p>Welcome to the world of insurance made easy. Compare, Contrast, and confidently select the plan that
                            suits your lifestyle.</p>
                        <div className="health-Insurance-home-banner-btnn">
                            <Link to="/signup">Get Started</Link>
                        </div>
                    </div>
                </div>
            </div>
            <CustomerServices />
            <div className="home-insurance-bac-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="home-insurance-bac-titel-2">
                                <p>Home insurance is not just a precautionary measure; it's a shield against the uncertainties
                                    that life can throw at your most prized possession your home. Whether it's a cozy
                                    apartment in the heart of the city, a quaint suburban house, or a sprawling countryside
                                    estate, your home represents much more than just bricks and mortar; it's a sanctuary, a
                                    refuge, and a symbol of security. However, safeguarding your haven against potential risks
                                    is paramount, and that's where home insurance steps in as your steadfast ally.</p>
                                <p>At its core, home insurance provides financial protection against unforeseen damages to your
                                    property and its contents. These damages can arise from a myriad of perils, ranging from
                                    natural disasters like earthquakes, floods, and wildfires to man-made mishaps such as theft,
                                    vandalism, or accidental fires. While we all hope for the best, it's prudent to prepare for
                                    the worst, and that's where comprehensive home insurance coverage comes into play.</p>
                                <p>We understand that every home and homeowner is unique, which is why we offer customizable
                                    insurance solutions to fit your specific needs and budget. Our experienced agents will work
                                    closely with you to assess your risks and determine the right coverage options for your
                                    home.</p>
                                <p>At its core, home insurance provides financial protection against unforeseen damages to your
                                    property and its contents. These damages can arise from a myriad of perils, ranging from
                                    natural disasters like earthquakes, floods, and wildfires to man-made mishaps such as theft,
                                    vandalism, or accidental fires. While we all hope for the best, it's prudent to prepare for
                                    the worst, and that's where comprehensive home insurance coverage comes into play.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="home-insurance-bac-banner-2">
                                <img src={homeBac1} alt="Home Insurance Banner One" />
                                <img src={homeBac2} alt="Home Insurance Banner Two" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Services data={servicePlans} />
            <div className="right-plans-protect">
                <div className="container">
                    <h2>We Have the Right Plans to Protect Your Vehicle from All Kinds of Risk</h2>
                </div>
            </div>

            <InsurancePlan data={propertyPlans} />
            <div className="zero-documents">
                <div className="container">
                    <div className="seamless-process-1">
                        <div className="zero-documents-apply-text">
                            <h3>Health Insurance</h3>
                            <h2>Zero Documents required for our seamless process</h2>
                            <h4>T&C Apply*</h4>
                        </div>
                        <div className="zero-documents-apply-banner">
                            <img src={homeDsgn} alt="Home Design" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="help_popular_articles">
                <div className="container">
                    <h6 className="faq-choose-health-insurance">FAQ</h6>
                    <AccordianComponent data={faq}/>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HomeInsurance