/* eslint-disable react/prop-types */
import { Box, Card, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import Palette from '../../theme/palette'


// eslint-disable-next-line arrow-body-style
const Overview = ({ data }) => {
  return (
    <>
      <Card style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
        <Box p={3}>
          <Grid container display="flex" spacing={4}>
            <Grid item xs={12} sm={6}>
              {/* <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                <Typography variant="body1">Policy number :</Typography>
                <Typography variant="body2" color={Palette.grey[600]}>{data?.policyNumber ? data?.policyNumber : "--"}</Typography>
              </Grid> */}
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                <Typography variant="body1">Policy type :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.policyType ? data?.policyType : "--"}</Typography>
              </Grid>
              {data?.insuranceType && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                <Typography variant="body1">Insurance type :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.insuranceType ? data?.insuranceType : "--"}</Typography>
              </Grid>}
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                <Typography variant="body1">Policy Company :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.policyCompanyName ? `${data?.policyCompanyName} (${data?.policySource ? data?.policySource : '--'})` : "--"}</Typography>
              </Grid>

              {!data?.policyType?.toLowerCase().includes('vehicle') &&
                !data?.policyType?.toLowerCase().includes('accidental') &&
                !data?.policyType?.toLowerCase().includes('life') &&
                !data?.policyType?.toLowerCase().includes('hospi') &&
                !data?.policyType?.toLowerCase().includes('home') &&
                !data?.policyType?.toLowerCase().includes('shop') &&
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                  <Typography variant="body1">Deductibles :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]} >{data?.deductibles ? data?.deductibles : "--"}</Typography>
                </Grid>}
              {!data?.policyType?.toLowerCase().includes('life') &&
                !data?.policyType?.toLowerCase().includes('hospi') &&
                !data?.policyType?.toLowerCase().includes('home') &&
                !data?.policyType?.toLowerCase().includes('top up') &&
                !data?.policyType?.toLowerCase().includes('accidental') &&
                !data?.policyType?.toLowerCase().includes('shop') && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Dental OPD  :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]} >{data?.dentalOPD ? data?.dentalOPD : "--"}</Typography>
                </Grid>}
              {!data?.policyType?.toLowerCase().includes('vehicle') &&
                !data?.policyType?.toLowerCase().includes('accidental') &&
                !data?.policyType?.toLowerCase().includes('life') &&
                !data?.policyType?.toLowerCase().includes('hospi') &&
                !data?.policyType?.toLowerCase().includes('shop') &&
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Bonus  :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]} >{data?.bonus ? data?.bonus : "--"}</Typography>
                </Grid>}
              {data?.premiumStartDate && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Premium start date :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >
                  {
                    data?.premiumStartDate ? dayjs(data?.premiumStartDate).format("DD/MM/YYYY") : "--"
                  }
                </Typography>
              </Grid>}
              {data?.premiumEndDate && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Premium End date :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >
                  {
                    data?.premiumEndDate ? dayjs(data?.premiumEndDate).format("DD/MM/YYYY") : "--"
                  }
                </Typography>
              </Grid>}
              {data?.premiumTenure && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Premium Term Tenure (In Years)  :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.premiumTenure ? data?.premiumTenure : "--"}</Typography>
              </Grid>}

            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Policy start date :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >
                  {
                    data?.policyStartDate ? dayjs(data?.policyStartDate).format("DD/MM/YYYY") : "--"
                  }
                </Typography>
              </Grid>
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Policy end date :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >
                  {
                    data?.policyEndDate ? dayjs(data?.policyEndDate).format("DD/MM/YYYY") : "--"
                  }
                </Typography>
              </Grid>
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Policy status :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.policyStatus ? data?.policyStatus : "--"}</Typography>
              </Grid>
              {data?.coverageAmounts && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Coverage Amounts  :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.coverageAmounts ? data?.coverageAmounts : "--"}</Typography>
              </Grid>}

              {/*  life insurnce filed ]
                */}
              {data?.lifeCover && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Life Coverage Amounts  :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.lifeCover ? data?.lifeCover : "--"}</Typography>
              </Grid>}

              {data?.mediclaimCover && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Mediclaim  Coverage Amounts  :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.mediclaimCover ? data?.mediclaimCover : "--"}</Typography>
              </Grid>}

              {data?.criticareCover && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Criti Care  Coverage Amounts  :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.criticareCover ? data?.criticareCover : "--"}</Typography>
              </Grid>}

              {data?.accidentalCover && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Accidental  Coverage Amounts  :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.accidentalCover ? data?.accidentalCover : "--"}</Typography>
              </Grid>}

              {/*  life insuren end   */}

              {data?.OPD && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">OPD  :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.OPD ? data?.OPD : "--"}</Typography>
              </Grid>}

              {!data?.policyType?.toLowerCase().includes('life') && <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">General OPD  :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} >{data?.generalOPD ? data?.generalOPD : "--"}</Typography>
              </Grid>}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  )
}

export default Overview
