import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { apiget } from '../../../../service/api';
import { constant } from '../../../../constant';


const AchievementSliderMiddle = () => {
    const [achievement, setAchievement] = useState([]);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImageIndex, setLightboxImageIndex] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
         autoplay: true,
        slidesToShow: 4,
        arrows: false,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    const fetchAchievementData = async () => {
        const result = await apiget(`achievement-management/list`);

        if (result && result.status === 200) {
            setAchievement(result?.data?.result);
        }
    };

    useEffect(() => {
        fetchAchievementData();
    }, []);

    const openLightbox = (index) => {
        setLightboxImageIndex(index);
        setLightboxOpen(true);
    };

    return (
        <div className="container">
            <div className="carousel-gallery">
                <div className="slider-container">
                    <Slider {...settings}>
                        {achievement.map((item, index) => (
                            <div key={index} className="slick-swipe-slide">
                                <div className="image-container" onMouseEnter={() => openLightbox(index)}>
                                    <img className='image' src={`${constant.baseUrl}uploads/achievements/${item.file}`} alt="Achievement" />
                                    <div className="overlay">
                                        <em className="mdi mdi-magnify-plus" />
                                    </div>
                                </div>
                                <div className="swiper-slide-titel">
                                    <h3>{item.title}</h3>
                                    <p>{item.content}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            {lightboxOpen && (
                <Lightbox
                    mainSrc={`${constant.baseUrl}uploads/achievements/${achievement[lightboxImageIndex]?.file}`}
                    onCloseRequest={() => setLightboxOpen(false)}
                />
            )}
        </div>
    );
};

export default AchievementSliderMiddle;
