import React, { useEffect, useState } from 'react';
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    Box,
} from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../../../../components/iconify'

import TableStyle from '../../../../components/TableStyle';
import { apiget, deleteManyApi, apidelete, apipost } from '../../../../service/api';
import DeleteModel from '../../../../components/Deletemodle';
import AddPolicyCompany from './Add';
import EditPolicyCompany from './Edit';

// Custom Toolbar Component
function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);

    const handleCloseDelete = () => {
        setOpendelete(false);
    };

    const handleOpenDelete = () => {
        setOpendelete(true);
    };

    const deleteManyPolicyCompany = async (data) => {
        await deleteManyApi('policy-company/deletemany', data);
        fetchdata();
        handleCloseDelete();
    };

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && (
                <Button
                    variant="text"
                    sx={{ textTransform: 'capitalize' }}
                    startIcon={<DeleteOutline />}
                    onClick={handleOpenDelete}
                >
                    Delete
                </Button>
            )}
            <DeleteModel
                opendelete={opendelete}
                handleClosedelete={handleCloseDelete}
                deletedata={deleteManyPolicyCompany}
                id={selectedRowIds}
            />
        </GridToolbarContainer>
    );
}

// Main Component
const PolicyCompany = () => {
    const [policyCompany, setPolicyCompany] = useState([]);
    const [open, setOpen] = useState(false);
    const [userAction, setUserAction] = useState(null);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState('');
    const [deleteId, setDeleteID] = useState(null);
    const [opendelete, setOpendelete] = useState(false);
    const [openAdd,setOpenAdd]=useState(false);

    const handleCloseDelete = () => {
        setOpendelete(false);
    };

    const handleOpenDelete = () => {
        setOpendelete(true);
    };

    
const handleOpenAdd=()=>{
    setOpenAdd(true);
}

    const deletePolicyCompany = async (deleteId) => {
        const result = await apidelete(`policy-company/delete/${deleteId}`);
      
        fetchdata();
        handleCloseDelete();
    };

    const updatePolicyCompanyOrder = async (data) => {
        const result = await apipost('policy-company/update-order', { policyCompany: data });
        if (result && result.status === 200) {
           fetchdata();
        }
    };

    const columns = [
        {
            field: 'drag',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                <Draggable draggableId={`${params.row._id}`} index={params.row.index}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Iconify icon="mdi:drag" />
                        </div>
                    )}
                </Draggable>
            ),
        },
        {
            field: 'policyCompany',
            headerName: 'Policy Company',
            flex: 1,
            cellClassName: 'name-column--cell name-column--cell--capitalize',
        },
        {
            field: 'sourceName',
            headerName: 'Source',
            flex: 1,
            cellClassName: 'name-column--cell name-column--cell--capitalize',
        },
        {
            field: 'companyPortalUrl',
            headerName: 'Company Portal link',
            flex: 1,
            cellClassName: 'name-column--cell name-column--cell--capitalize',
        },
        {
            field: 'action',
            headerName: 'Action',
            headerAlign: 'center',
            flex: 1,
            maxWidth: 200,
            minWidth: 100,
            renderCell: (params) => {
                const handleOpenEditDialog = (data) => {
                    setOpen(true);
                    setEditId(data);
                };

                return (
                    <Stack direction={"row"}>
                        <Button
                            variant='text'
                            size='small'
                            color='primary'
                            onClick={() => handleOpenEditDialog(params?.row?._id)}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            variant='text'
                            size='small'
                            color='error'
                            onClick={() => {
                                handleOpenDelete();
                                setDeleteID(params.row._id);
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </Stack>
                );
            }
        },
        
    ];

    const fetchdata = async () => {
        const result = await apiget('policy-company/list');
        if (result && result.status === 200) {
            const sortedPolicyTypes = result.data.sort((a, b) => a.order - b.order);
           
            setPolicyCompany(sortedPolicyTypes);
           
        }
    };

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedRows = Array.from(policyCompany);
        const [removed] = reorderedRows.splice(result.source.index, 1);
        reorderedRows.splice(result.destination.index, 0, removed);

        setPolicyCompany(reorderedRows);

        // Prepare data to send to the backend
        const orderData = reorderedRows.map((row, index) => ({
            _id: row._id,
            order: index + 1,
        }));

        // Update the order in the backend
        await updatePolicyCompanyOrder(orderData);
    };

    useEffect(() => {
        fetchdata();
    }, [userAction]);

    return (
        <>
            <DeleteModel
                opendelete={opendelete}
                handleClosedelete={handleCloseDelete}
                deletedata={deletePolicyCompany}
                id={deleteId}
            />
            <EditPolicyCompany
                fetchdata={fetchdata}
                setOpen={setOpen}
                open={open}
                editId={editId}
            />
             <AddPolicyCompany open={openAdd} setOpen={setOpenAdd} fetchdata={fetchdata} />
            <Container>
                <TableStyle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4">
                            Policy Company
                        </Typography>
                        <Button onClick={handleOpenAdd} variant='contained'  >
                          <AddIcon/>    Add Policy Company
                        </Button>
                    </Stack>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{ height: '100%', overflow: 'hidden' }}
                                        >
                                            <DataGrid
                                                rows={policyCompany.map((row, index) => ({
                                                    ...row,
                                                    index,
                                                }))}
                                                columns={columns}
                                                components={{
                                                    Toolbar: () =>
                                                        CustomToolbar({
                                                            selectedRowIds,
                                                            fetchdata,
                                                        }),
                                                }}
                                                checkboxSelection
                                                onRowSelectionModelChange={handleSelectionChange}
                                                rowSelectionModel={selectedRowIds}
                                                getRowId={(row) => row._id}
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
};

export default PolicyCompany;
