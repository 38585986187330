import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { FaSearch, FaUsers, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import './HelpCentre.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';
import { apiget } from '../../../service/api';
import { constant } from '../../../constant';
import AccordianComponent from './AccordianComponent';


const HelpCentre = () => {

  const [data, setData] = useState([]);
  const [helpCenterData, setHelpCenterData] = useState([]);

  const fetchArticleData = async () => {
    const result = await apiget('article/list');
    
    if (result && result.status === 200) {
      setData(result?.data?.result)
    }
  }

  const fetchHelpCenterData = async () => {
    const result = await apiget('help-center/list');

    if (result && result.status === 200) {
      setHelpCenterData(result?.data?.result);
    }
  }




  useEffect(() => {
    fetchArticleData();
    fetchHelpCenterData();
  }, []);

  return (
    <>
      <div className='help-centre-main-container'>
        <div className="help_center_background">
          <div className="container">
            <Navbar />
            <div className="help_center_text">
              <h2>Hello!, How We Can Help You?</h2>
            </div>

            <div className="input-box">
              <input type="text" placeholder="Search here..." />
              <button className="button"><FaSearch /></button>
            </div>
          </div>
        </div>
        <div className="insurance_help">
          <div className="container">
            <div className="row">

              {helpCenterData && helpCenterData.length >0 && helpCenterData.map((item,index) => (
                <div className="col-md-4" key={index}>
                  <div className="icen_help_bac">
                    <Link href="">
                      <img src={`${constant.baseUrl}uploads/helpCenter/${item.file}`} alt='' />
                    </Link>
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


        <div className="help_popular_articles">
          <div className="container">
            <h2>Popular Articles</h2>
            <AccordianComponent data={data} />
          </div>
        </div>

        <div className="help_still_question">
          <div className="container">
            <h3>Still have a question?</h3>
            <p>If you cannot find answers in our FAQ, you can always contact us. We will answer to you shortly.</p>
          </div>
        </div>

        <div className="help_still_call">
          <div className="container">
            <div className="question_help">
              <div className="still_help">
                <Link href=""><FaPhoneAlt /></Link>
                <h4>Call us at</h4>
                <p>+91 9314234442</p>
              </div>
              <div className="still_help">
                <Link href=""><FaEnvelope /></Link>
                <h4>Mail us at</h4>
                <p>advisor@vikasmahipal.com</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default HelpCentre