import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../Insurance_common_css.css'
import adv1 from '../../../../Images/Insurance/Advantages-title-banner-1.png'
import adv2 from '../../../../Images/Insurance/Advantages-title-banner.png'
import indiviPlan from '../../../../Images/Insurance/Individual Plan-banner.png'
import ZeroDoc from '../../../../Images/Insurance/zero-documents-apply-banner.png'
import Navbar from '../../Navbar/Navbar'
import Services from '../Insurance_Components/Services';
import CustomerServices from '../Insurance_Components/CustomerServices';
import Footer from '../../Footer/Footer'
import { constant } from '../../../../constant'
import { apiget } from '../../../../service/api'
import AccordianComponent from '../../HelpCentre/AccordianComponent'


const HealthInsurance = () => {

    const [healthInsurancePlans, setHealthInsurancePlans] = useState([]);
    const [servicePlans, setServicePlans] = useState([]);
    const [faq, setFaq] = useState([]);


    const fetchHealthPlans = async () => {
        const result = await apiget('health-insurance-plans/list');
        if (result && result.status === 200) {
            const filteredPlans = result.data.result.filter(plan =>
                plan.page && plan.page.toLowerCase().includes("health")
            );
            setHealthInsurancePlans(filteredPlans);
        }
    }
    const fetchFaq = async () => {
        try {
            const result = await apiget('article/list');
            if (result && result.status === 200) {
                const filteredPlans = result.data.result.filter(plan =>
                    plan.page && plan.page.toLowerCase().includes("health")
                );
                setFaq(filteredPlans);
            }
        } catch (error) {
            console.error("Error fetching service plans:", error);
        }
    }


    const fetchServicePlans = async () => {
        try {
            const result = await apiget('service-plans/list');
            if (result && result.status === 200) {
                const filteredPlans = result.data.result.filter(plan =>
                    plan.page && plan.page.toLowerCase().includes("health")
                );
                setServicePlans(filteredPlans);
            }
        } catch (error) {
            console.error("Error fetching service plans:", error);
        }
    }

    useEffect(() => {
        fetchHealthPlans();
        fetchServicePlans();
        fetchFaq();
    }, []);

    return (

        <>
            <div className="health-Insurance-background">
                <div className="container">
                    <Navbar />
                    <div className="health-Insurance-home-banner-text">
                        <h3>A Life Full Of Care</h3>
                        <h2>From mind to body, we’ve got your holistic health covered.</h2>
                        <p>Welcome to the world of insurance made easy. Compare, Contrast, and confidently select the plan that
                            suits your lifestyle.</p>
                        <div className="health-Insurance-home-banner-btnn">
                            <Link to="/signup">Get Started</Link>
                        </div>
                    </div>
                </div>
            </div>


            <CustomerServices />

            <Services data={servicePlans} />
            <div className="Advantages-title">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="Advantages-title-text">
                                <h2>Advantages of Health Insurance</h2>
                                <p>Health insurance offers a myriad of advantages that are essential for individuals and
                                    families
                                    alike. Firstly, it provides financial protection against unexpected medical expenses,
                                    shielding
                                    policyholders from the potentially crippling costs associated with healthcare services.
                                    Whether
                                    it's a routine doctor's visit, emergency room treatment, or major surgery, health insurance
                                    ensures that individuals can access necessary medical care without fear of being burdened by
                                    exorbitant bills. This financial security is particularly crucial in times of illness or
                                    injury
                                    when the focus should be on recovery rather than worrying about how to pay for healthcare.
                                </p>
                                <p>Secondly, health insurance promotes preventive care and encourages proactive health
                                    management.
                                    Many insurance plans cover preventive services such as vaccinations, screenings, and
                                    wellness
                                    exams at little to no cost to the policyholder.</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="Advantages-title-banner">
                                <img src={adv2} alt="Banner 1" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="Advantages-title-banner">
                                <img src={adv1} alt="Banner 2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Perfect-Health-Insurance-Plan">
                <div className="container">
                    <div className="Perfect-Health-Insurance-Plan-text">
                        <h2>The Perfect Health Insurance Plan</h2>
                        <p>Lorem ipsum dolor sit amet consectetur. Eget commodo sit phasellus vel. Aliquet ac viverra arcu
                            adipiscing tempor fermentum massa.</p>
                    </div>
                </div>
            </div>

            <div className="individual-plan-family-plan-2">
                <div className="container">
                    <div className="row">
                        {healthInsurancePlans && healthInsurancePlans.length > 0 && healthInsurancePlans.map((item, index) => (
                            <div className="col-md-6" key={index}>
                                <div className="individual-plan-1">
                                    <div className="individual-plan-1-banner">

                                        <img src={`${constant.baseUrl}uploads/healthInsurancePlans/${item.file}`} alt={""} />
                                    </div>
                                    <div className="individual-plan-1-text">
                                        <h3>{item.title}</h3>
                                        <p>
                                            {item.content.length > 206
                                                ? `${item.content.substring(0, 206)}...`
                                                : item.content}
                                        </p>
                                        <div className="individual-plan-btn-1">
                                            <a href="">Know More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="zero-documents">
                <div className="container">
                    <div className="seamless-process-1">
                        <div className="zero-documents-apply-text">
                            <h3>Health Insurance</h3>
                            <h2>Zero Documents required for our seamless process</h2>
                            <h4>T&C Apply*</h4>
                        </div>
                        <div className="zero-documents-apply-banner">
                            <img src={ZeroDoc} alt="Zero Documents" />
                        </div>
                    </div>
                </div>

                <div className="help_popular_articles">
                    <div className="container">
                        <h6 className="faq-choose-health-insurance">FAQ</h6>
                        <AccordianComponent data={faq} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HealthInsurance