/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TextField, MenuItem, Select, FormLabel, FormControl, FormHelperText } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import Palette from '../../theme/palette'
import { apiput } from "../../service/api";

const Edit = ({ open, handleClose, fetchRecievedPayment, id, data, method,type }) => {
 


    const initialValues = {
        paymentMethod: data?.paymentMethod || "",
        insuredPersonName: data?.insuredPersonName || '',
        date: data?.date || '',
        amount: data?.amount || '',
        desc: data?.desc || '',
        type:data?.type || '',
    };

    const validationSchema = yup.object({
        paymentMethod: yup.string().when('type', {
            is: (val) => val?.toLowerCase().includes('payment'),
            then: (schema) => schema.required("Payment Method is required"),

        }),
        date: yup.string().when('type', {
            is: (val) => val?.toLowerCase().includes('payment'),
            then: (schema) => schema.required("Date is required"),

        }),
        amount: yup.number().required('required'),
        desc: yup.string(),
        type: yup.string(),
       
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });




    const fileUpload = async (values) => {
        try {

            const result = await apiput(`recieved-payment/edit/${id}`, values);
           
            if (result && result.status === 200) {
                fetchRecievedPayment();
                formik.resetForm();
                handleClose();
                // toast.success(result.data.message)
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >


                            {type === 'payment' && <> <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Payment Method</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="paymentMethod"
                                        name="paymentMethod"
                                        size='small'
                                        fullWidth
                                        value={formik.values.paymentMethod}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.paymentMethod &&
                                            Boolean(formik.errors.paymentMethod)
                                        }
                                        helpertext={
                                            formik.touched.paymentMethod && formik.errors.paymentMethod
                                        }
                                    >
                                        {method && method.length > 0 && method.map((option, index) => (

                                            <MenuItem key={index} value={option.paymentMethod}>
                                                {option.paymentMethod}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.paymentMethod && formik.errors.paymentMethod}</FormHelperText>
                                </FormControl>
                            </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Date of Payment</FormLabel>
                                    <TextField
                                        id="date"
                                        name="date"
                                        type="date"
                                        size="small"
                                        fullWidth
                                        value={formik.values.date}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.date && Boolean(formik.errors.date)
                                        }
                                        helperText={formik.touched.date && formik.errors.date}
                                    />
                                </Grid> </>}
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>{type === 'payment' ? "Amount" : 'Discount'}</FormLabel>
                                <TextField
                                    id="amount"
                                    name="amount"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    value={formik.values.amount}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        if (/^\d*$/.test(value)) {
                                            formik.setFieldValue('amount', value);
                                        }
                                    }}
                                    error={
                                        formik.touched.amount && Boolean(formik.errors.amount)
                                    }
                                    helperText={formik.touched.amount && formik.errors.amount}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Description</FormLabel>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    type="text"
                                    rows={5}
                                    multiline
                                    size="small"
                                    fullWidth
                                    value={formik.values.desc}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.desc && Boolean(formik.errors.desc)
                                    }
                                    helperText={formik.touched.desc && formik.errors.desc}
                                />
                            </Grid>

                        </Grid>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ textTransform: "capitalize" }}
                            >
                                Save
                            </Button>
                            <Button
                                type="reset"
                                variant="outlined"
                                style={{ textTransform: "capitalize" }}
                                color="error"
                                onClick={() => {
                                    formik.resetForm();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Edit;
