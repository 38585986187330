import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
// components
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import { apidelete, apiget, deleteManyApi } from '../../service/api';
import TableStyle from '../../components/TableStyle';
import DeleteModel from '../../components/Deletemodle'
import { constant } from '../../constant';



function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyContact = async (data) => {
       
        await deleteManyApi('enquiry/deletemany', data)
        fetchdata()
        handleCloseDelete();
    }

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}

const Enquiry = () => {
    const [userAction, setUserAction] = useState(null);
    const [enquiryData, setEnquiryData] = useState([]);
    
    const [selectedRowIds, setSelectedRowIds] = useState([]);

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")


    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };




    const columns = [
        {
            field: "fullName",
            headerName: "First Name",
            flex: 1,
        },

        {
            field: "phone",
            headerName: "Phone",
            flex: 1,

        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,

        },
        {
            field: "message",
            headerName: "Message",
            flex: 1,

        },
        {
            field: "insurance",
            headerName: "Insurance",
            flex: 1,

        },

        {
            field: "createdOn",
            headerName: "CreateOn",
            flex: 1,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toDateString();
            },

        },


    ];

    // fetch Blogs list
    const fetchdata = async () => {
        const result = await apiget(userRole === "admin" ? `enquiry/list` : `enquiry/list/?createdBy=${userid}`)
        if (result && result.status === 200) {
            setEnquiryData(result?.data?.result)
        }
    }


    useEffect(() => {
        fetchdata();
    }, [userAction])
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Enquiry
                    </Typography>

                </Stack>
                <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={enquiryData}
                                columns={columns}
                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                            />
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default Enquiry