

import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { apipost } from '../../../../service/api';


const AddPoliCompany = ({ fetchdata, open, setOpen }) => {
  const [userAction, setUserAction] = useState(null);

  const validationSchema = yup.object({
    policyCompany: yup.string().required('Policy Company Name is required'),
    sourceName: yup.string().required('Source Name is required'),
    companyPortalUrl: yup.string().url('Enter a valid URL'),
  });

  const formik = useFormik({
    initialValues: {
      policyCompany: '',
      sourceName: '',
      companyPortalUrl: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const result = await apipost('policy-company/add', values);
      if (result && result.status === 200) {
        fetchdata();
        setUserAction(result);
        formik.resetForm();
        handleClose();
      }
    },
  });

  useEffect(() => {
    if (userAction) {
      fetchdata();
    }
  }, [userAction]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle variant="h5">Add Policy Company Details</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Enter Policy Company Name"
                variant="outlined"
                fullWidth
                name="policyCompany"
                value={formik.values.policyCompany}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.policyCompany && Boolean(formik.errors.policyCompany)}
                helperText={formik.touched.policyCompany && formik.errors.policyCompany}
                sx={{ marginTop: '10px', marginBottom: '10px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Source Name"
                variant="outlined"
                fullWidth
                name="sourceName"
                value={formik.values.sourceName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.sourceName && Boolean(formik.errors.sourceName)}
                helperText={formik.touched.sourceName && formik.errors.sourceName}
                sx={{ marginTop: '10px', marginBottom: '10px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Portal Link"
                variant="outlined"
                fullWidth
                name="companyPortalUrl"
                value={formik.values.companyPortalUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.companyPortalUrl && Boolean(formik.errors.companyPortalUrl)}
                helperText={formik.touched.companyPortalUrl && formik.errors.companyPortalUrl}
                sx={{ marginTop: '10px', marginBottom: '10px' }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ marginTop: '20px' }}
          onClick={formik.handleSubmit}
        >
          Add 
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPoliCompany;

