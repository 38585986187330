/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel, FormControl, FormHelperText, Select, MenuItem } from "@mui/material";
import { apiput } from "../../service/api";

const Edit = ({ open, handleClose, setUserAction, id, singleReferralSourceData }) => {
    const ReferralData = singleReferralSourceData;


    const initialValues = {
        referralSource: ReferralData?.referralSource || "",
        sourceType: ReferralData?.sourceType || "",
        createdBy: ReferralData?.createdBy || "",
    };

    const validationSchema = yup.object({
        referralSource: yup.string().required("Referral Source is required"),
        sourceType: yup.string().required("Source Type is required"),
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });




    const fileUpload = async (values) => {
        try {

            const result = await apiput(`referral-source/edit/${id}`, values);
            setUserAction(result);

            if (result && result.status === 200) {
                formik.resetForm();
                handleClose();

            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Referral Source</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={() => {
                                handleClose();
                                formik.resetForm();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Referral Source<Typography component={'span'} color={'error'} >*</Typography></FormLabel>
                                <TextField
                                    id="referralSource"
                                    name="referralSource"
                                    size="small"
                                    fullWidth
                                    value={formik.values.referralSource}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.referralSource && Boolean(formik.errors.referralSource)
                                    }
                                    helperText={formik.touched.referralSource && formik.errors.referralSource}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>
                                    Referral Source Type<Typography component={'span'} color={'error'} >*</Typography>
                                </FormLabel>
                                <FormControl fullWidth error={formik.touched.sourceType && Boolean(formik.errors.sourceType)}>

                                    <Select
                                        labelId="sourceType"
                                        id="sourceType"
                                        name="sourceType"
                                        size="small"
                                        value={formik.values.sourceType}
                                        onChange={formik.handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="Lead">Lead</MenuItem>
                                        <MenuItem value="Contact">Contact</MenuItem>
                                    </Select>
                                    {formik.touched.sourceType && formik.errors.sourceType && (
                                        <FormHelperText>{formik.errors.sourceType}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                        </Grid>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ textTransform: "capitalize" }}
                            >
                                Save
                            </Button>
                            <Button
                                type="reset"
                                variant="outlined"
                                style={{ textTransform: "capitalize" }}
                                color="error"
                                onClick={() => {
                                    formik.resetForm();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Edit;
