import React from 'react'
import './Contact.css'
import { useFormik } from "formik";
import * as yup from "yup";
import { FaYoutube, FaInstagram, FaFacebookF, FaLinkedinIn, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';
import { apipost } from '../../../service/api';


const Contact = () => {
    const userid = localStorage.getItem('user_id')

    const validationSchema = yup.object({
        fullName: yup.string().required("fullName is required"),
        phone: yup.string().required("phone is required"),
        email: yup.string().email('Invalid email').required('Email is required'),
        message: yup.string()
    });

    // -----------   initialValues
    const initialValues = {
        fullName: "",
        phone: "",
        email: "",
        message: "",
        createdBy: userid
    };

    const fileUpload = async (values) => {
      
        const data = new FormData()
        data.append("fullName", values.fullName)
        data.append("phone", values.phone)
        data.append("email", values.email)
        data.append("message", values.message)
        data.append("createdBy", values.createdBy)

        const result = await apipost(`enquiry/add`, values)

        if (result && result.status === 200) {
            formik.resetForm();

        }
    }


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {

            fileUpload(values)
        },
    });


    return (<>
        <div className="contact_us_background">
            <div className="container">
                <Navbar />
                <div className="contact_us_text">
                    <h2>Contact Us</h2>
                    <p>Lorem ipsum dolor sit amet consectetur. Eget sit gravida amet nunc dapibus ac.</p>
                </div>

                <div className="contact_us_icen">
                    <Link><FaYoutube /></Link>
                    <Link><FaInstagram /></Link>
                    <Link><FaFacebookF /></Link>'
                    <Link><FaLinkedinIn /></Link>'
                </div>
            </div>
        </div>
        <div className="contact_touch">
            <div className="container">
                <h3>Get In Touch</h3>
            </div>
        </div>

        <div className="contact_input_box">
            <div className="container">
                <div className="contact_input_box_bac">
                    <form className="row g-3 cont_mt" onSubmit={formik.handleSubmit}>
                        <div className="col-md-5">
                            <label htmlFor="fullName" className="form-label">Full Name
                                <input type="text" className="form-control" id="fullName" value={formik.values.fullName}
                                    onChange={formik.handleChange} name="fullName" />
                            </label>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="phone" className="form-label">Phone
                                <input type="text" className="form-control" id="phone" value={formik.values.phone}
                                    onChange={formik.handleChange} name="phone" />
                            </label>
                        </div>
                        <div className="col-md-10">
                            <label htmlFor="emailAddress" className="form-label">Email Address
                                <input type="email" className="form-control" id="email" value={formik.values.email}
                                    onChange={formik.handleChange} name="email" />
                            </label>
                        </div>
                        <div className="col-md-10">
                            <label htmlFor="message" className="form-label">Message
                                <textarea className="form-control" id="message" value={formik.values.message}
                                    onChange={formik.handleChange} name="message" rows="3" />
                            </label>
                        </div>
                    </form>
                    <div className="contact_button">
                        <Link onClick={formik.handleSubmit} >Get In Touch <i className="fa fa-arrow-right" /></Link>
                    </div>
                </div>
            </div>
        </div>




        <div className="contact_corporate_office">
            <div className="container">
                <div className="cont_last">
                    <div className="contact_last_icen">
                        <Link to=""><FaPhoneAlt /></Link>
                        <h6>Call us toll free (24*7)</h6>
                        <p>+91 9314234442</p>
                    </div>
                    <div className="contact_last_icen">
                        <Link to=""><FaEnvelope /></Link>
                        <h6>Email us at</h6>
                        <p>advisor@vikasmahipal.com</p>
                    </div>
                    {/* <div className="contact_last_icen">
                        <Link to=""><FaEnvelope /></Link>
                        <h6>SMS Liberate</h6>
                        <p>561616</p>
                    </div> */}
                    <div className="contact_last_icen">
                        <Link to=""><FaMapMarkerAlt /></Link>
                        <h6>Corporate Office</h6>
                        <p>Roshan Lal Mahipal, L-102, Mahima Elite, Swej Farm, New Sanganer Road, Jaipur, Rajasthan, 302019</p>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

    </>
    )
}

export default Contact