import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import Logo from '../../../Images/Navbar/logo.png'

const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole");
const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer_background">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer_title">
                                <img src={Logo} alt='' />
                                    <p>We are more than just an insurance company – we are your dedicated partners in protecting
                                        what matters most to you.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer_title">
                                <h2>Insurance</h2>
                                <li><Link to="">Health Insurance</Link></li>
                                <li><Link to="">Critical Illness</Link></li>
                                <li><Link to="">Vehicle Insurance</Link></li>
                                <li><Link to="">Life Insurance</Link></li>
                                <li><Link to="">Home Insurance</Link></li>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer_title">
                                <h2>Insurance</h2>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/achievements">Achievements</Link></li>
                                <li><Link to="">Careers</Link></li>
                                <li><Link to="/achievements">Achievements</Link></li>
                                <li><Link to="">Testimonials</Link></li>
                                <li><Link to={userRole==='admin'?"/dashboard/policy":"/login-admin"}>Agent</Link></li>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer_title">
                                <h2>Contact</h2>
                                <li><Link to="">+91 9314234442</Link></li>
                                <li><Link to="">advisor@vikasmahipal.com</Link></li>
                                {/* <li><Link to="">Admin@vikasmahipal.com</Link></li> */}
                                <div className="footer_icen">
                                    <Link to=""><FaInstagram/></Link>
                                    <Link to=""><FaFacebook/></Link>
                                    <Link to=""><FaTwitter/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                    <div className="footer_last">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="footer_last_title">
                                    <p>Copyright © 2024</p>
                                    <Link to="">Vikasmahipal.com</Link>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="footer_last_title1">
                                    <span>All Rights Reserved | <Link to="">Privacy Policy</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Footer