import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
} from '@mui/material';
import { apiget, apipost } from '../../service/api';
import Iconify from '../../components/iconify';


const AddPaymentSource = ({ fetchdata }) => {

  const [open, setOpen] = useState(false);
  const [paymentSource, setPaymentSource] = useState([]);

  const [newPaymentSource, setNewPaymentSource] = useState('');

  const fetchPaymentSource = async () => {
    const result = await apiget('payment-source/list');
    if (result && result.status === 200) {
      setPaymentSource(result.data);
    }
  };

  useEffect(() => {

    fetchPaymentSource();

  }, []);

  const handleAddPaymentSource = async (newSource) => {
    const result = await apipost('payment-source/add', { paymentSource: newSource });
    if (result && result.status === 201) {
      fetchPaymentSource();
      setNewPaymentSource('');
    }
  };



  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />} >Add PaymentSource  </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle variant="h5">Payment Source </DialogTitle>
        <DialogContent dividers>
          

          <TextField
            label="Enter Source Name "
            variant="outlined"
            fullWidth
            value={newPaymentSource}
            onChange={(e) => setNewPaymentSource(e.target.value)}
            sx={{ marginBottom: '16px' }}
          />
          <Button variant="contained" onClick={() => {
            handleAddPaymentSource(newPaymentSource);
            handleClose();
            fetchdata();
          }}>
            Add
          </Button>
        </DialogContent>

      </Dialog>
    </>
  );
};

export default AddPaymentSource;
