import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-GB'; // Import the locale for formatting

function CustomDatePicker() {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    console.log('Selected Date:', date);
    setSelectedDate(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
      <DatePicker
        label="Select Date"
        value={selectedDate}
        onChange={handleDateChange}
        inputFormat="dd/MM/yy" // Adjusted date format here
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
