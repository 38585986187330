/* eslint-disable react/prop-types */
import * as React from 'react';
import dayjs from "dayjs";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FormControl, FormHelperText, FormLabel, Checkbox, Grid, MenuItem, Select, TextField, Autocomplete, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from 'formik';
import * as yup from "yup";
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState, useCallback } from 'react';
import { apiget, apipost } from '../../service/api';
import Palette from '../../theme/palette';
import AddPolicyCompany from './PolicyEditor/PolicyCompanies/Add'
import AddPolicyType from './PolicyEditor/PolicyType/Add';
import AddPaymentMethod from '../PaymentMethod/Add'



const Add = (props) => {


    const [policyTypes, setPolicyTypes] = useState([]);
    const [policyCompany, setPolicyCompany] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [relationToTheInsured, setRelationToTheInsured] = useState([]);
    const { open, handleClose, setUserAction, _id, data, allPolicyData } = props
    const [paymentSource, setPaymentSource] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [contactList, setContactList] = useState([]);
    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole");
    const [openAddPolicyCompany, setOpenAddPolicyCompany] = useState(false);
    const [openAddPolicyType, setOpenAddPolicyType] = useState(false);
    const [openAddPaymentMethod, setOpenPaymentMethod] = useState(false);

    const handleOpenAddPolicyCompany = () => {
        setOpenAddPolicyCompany(true);
    }

    const handleOpenAddPolicyType = () => {
        setOpenAddPolicyType(true);
    }
    const handleOpenAddPaymentMethod = () => {
        setOpenPaymentMethod(true);
    }

    const validationSchema = yup.object().shape({
        policyType: yup.string().required('Policy Type is required'),
        OPD: yup.string(),
        dentalOPD: yup.string(),
        generalOPD: yup.string(),
        claimStatus: yup.string().required('Claim Status is required'),
        riskLocation: yup.string().when('policyType', {
            is: (val) => val?.toLowerCase().includes('shop') || val?.toLowerCase().includes('home'),
            then: (schema) => schema.required('Risk Location is required '),

        }),
        ncv: yup.string().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto')),
            then: (schema) => schema.required('NCV is required '),

        }),
        idp: yup.string().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto')),
            then: (schema) => schema.required('IDP is required '),

        }),
        paymentPeriod: yup.string(),
        insuranceType: yup.string().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto')),
            then: (schema) => schema.required('Insurance Type is required '),

        }),
        // life fields
        premiumStartDate: yup.date().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('life')),
            then: (schema) => schema.required('Premuim Start Date is required '),

        }),
        premiumEndDate: yup.date().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('life')),
            then: (schema) => schema.required('Premuim End Date is required '),

        }),
        premiumTenure: yup.string().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('life')),
            then: (schema) => schema.required('Premuim Tenure is required '),

        }),
        lifeCover: yup.string().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('life')),
            then: (schema) => schema.required('Life Coverage Amount is required '),

        }),
        accidentalCover: yup.string().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('life')),
            then: (schema) => schema.required('Accidental Coverage Amount is required '),
        }),
        criticareCover: yup.string().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('life')),
            then: (schema) => schema.required('Criticare Illness Coverage Amount is required '),
        }),
        mediclaimCover: yup.string().when('policyType', {
            is: (val) => (val?.toLowerCase().includes('life')),
            then: (schema) => schema.required('Criticare Illness Coverage Amount is required '),
        }),


        paymentMethod: yup.string().required('payment method is required'),
        policyRemarks: yup.string(),
        hospitalName: yup.string(),
        // habit: yup.string(),
        // alcoholQuantity: yup.string(),
        // smokingQuantity: yup.string(),
        // tobaccoQuantity: yup.string(),
        // paymentSource: yup.string(),
        others: yup.string(),
        additionalInsureds: yup.array().of(
            yup.object().shape({
                additionalInsuredPersonName: yup.string(),
                additionalInsuredDateOfBirth: yup.date(),
                additionalRelationshipToTheInsured: yup.string(),
                additionalPhoneNumber: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid'),
                additionalEmailAddress: yup.string().email('Invalid email'),
                additionalInsuredPersonHabbit: yup.string(),
                alcoholQuantity: yup.string(),
                smokingQuantity: yup.string(),
                tobaccoQuantity: yup.string(),
                others: yup.string(),
                bonus: yup.string(),
                coverageAmounts: yup.string(),

            })
        ),
        policyCompanyName: yup.string().required('Policy Company is required'),
        policyStartDate: yup.date().required('Policy Start Date is required'),
        policyEndDate: yup.date().required('Policy End Date is required'),
        policyStatus: yup.string().required('Policy Status is required'),

        coverageAmounts: yup.string().when('policyType', {
            is: (val) => !(val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto') || val?.toLowerCase().includes('accidental') || val?.toLowerCase().includes('life')),
            then: (schema) => schema.required('Coverage amount is required '),

        }),
        deductibles: yup.string().when('policyType', {
            is: (val) => !val?.toLowerCase().includes('accidental') &&
                !val?.toLowerCase().includes('mediclaim') &&
                !val?.toLowerCase().includes('life') &&
                !val?.toLowerCase().includes('hospi') &&
                !val?.toLowerCase().includes('shop') &&
                !val?.toLowerCase().includes('home') &&
                !val?.toLowerCase().includes('criti care'),

            then: (schema) => schema.required('deductibles is required '),
        }),
        bonus: yup.string().when('policyType', {
            is: (val) => !val?.toLowerCase().includes('vehicle') &&
                !val?.toLowerCase().includes('auto') &&
                !val?.toLowerCase().includes('life') &&
                !val?.toLowerCase().includes('hospi') &&
                !val?.toLowerCase().includes('shop') &&
                !val?.toLowerCase().includes('accidental'),
            then: (schema) => schema.required('Bonus is required '),

        }),
        insuredPersonName: yup.string().when('policyType', {
            is: (val) => !(val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto')),
            then: (schema) => schema.required('Person Name is required '),

        }),
        insuredPersonDateOfBirth: yup.date(),
        relationshipToTheInsured: yup.string(),
        // relationshipToTheInsured: yup.string().when('policyType', {
        //     is: (val) => !(val?.toLowerCase().includes('vehicle') ||
        //         val?.toLowerCase().includes('shop') ||
        //         val?.toLowerCase().includes('home') ||
        //         val?.toLowerCase().includes('auto')),
        //     then: (schema) => schema.required('Relationship To The Insured is required '),

        // }),
        phoneNumber: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid'),
        emailAddress: yup.string().email('Invalid email'),


        additionalPhoneNumber: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid'),
        additionalEmailAddress: yup.string().email('Invalid email'),
        insuredPersonNameAadhaar: yup.string()
            .matches(/^\d{12}$/, 'Aadhaar number must be 12 digits'),
        insuredPersonNamePan: yup.string()
            .test('format', 'PAN number must be 10 characters (5 letters, 4 digits, 1 letter)', value => {
                // Only test if the value is provided
                if (value) {
                    return /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);
                }
                return true; // Pass validation if no value is provided
            }),

        // Conditionally required vehicle insurance fields
        policyHolder: yup.string().when('policyType', {
            is: (val) => val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto'),
            then: (schema) => schema.required('Policy Holder Name is required'),
        }),
        vehicleMake: yup.string().when('policyType', {
            is: (val) => val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto'),
            then: (schema) => schema.required('Vehicle Make is required'),
        }),
        vehicleType: yup.string().when('policyType', {
            is: (val) => val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto'),
            then: (schema) => schema.required('Vehicle Type is required'),
        }),
        vehicleNumber: yup.string().when('policyType', {
            is: (val) => val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto'),
            then: (schema) => schema.required('Vehicle Number is required'),
        }),
        vehicleModel: yup.string().when('policyType', {
            is: (val) => val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto'),
            then: (schema) => schema.required('Vehicle Model is required'),
        }),
        yearOfManufacture: yup.number().integer().when('policyType', {
            is: (val) => val?.toLowerCase().includes('vehicle') || val?.toLowerCase().includes('auto'),
            then: (schema) => schema.required('Year of Manufacture is required'),
        }),

    });




    // -----------   initialValues
    const initialValues = {
        policyType: "",
        OPD: "",
        policySource: "",
        dentalOPD: "0",
        generalOPD: '0',
        hospitalName: "",
        // habit: data?.habit || "",
        paymentPeriod: "",
        claimStatus: "",
        ncv: "",
        idp: "",
        riskLocation: '',
        // alcoholQuantity: data?.alcoholQuantity || "",
        // smokingQuantity: data?.smokingQuantity || "",
        // tobaccoQuantity: data?.tobaccoQuantity || "",
        policyCompanyName: "",
        policyStartDate: "",
        policyEndDate: "",
        policyStatus: "",
        coverageAmounts: "",
        deductibles: "0",
        bonus: "0",
        others: "",
        policyHolder: "",
        policyRemarks: "",
        additionalInsureds: [],
        insuredPersonName: "",
        insuredPersonDateOfBirth: "",
        phoneNumber: "",
        emailAddress: "",
        relationshipToTheInsured: "",
        premiumAmount: "",
        paymentSource: "",
        insuredPersonNameAadhaar: "",
        insuredPersonNamePan: "",
        FrequencyOfPremiumPayments: "",
        createdBy: userid,
        contact_id: _id,
        assigned_agent: userid,
        paymentMethod: "",
        // Vehicle insurance fieldsfpan
        insuranceType: "",
        vehicleMake: "",
        vehicleType: "",
        vehicleNumber: "",
        vehicleModel: "",
        yearOfManufacture: "",
        //    life fields
        premiumStartDate: "",
        premiumEndDate: "",
        premiumTenure: "",
        lifeCover: "0",
        mediclaimCover: "0",
        criticareCover: "0",
        accidentalCover: "0",

    };


    const handleStartDateChange = (event) => {
        const selectedDate = new Date(event.target.value);
        setStartDate(selectedDate);

        const newEndDate = new Date(selectedDate);
        newEndDate.setDate(newEndDate.getDate() - 1);
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        setEndDate(newEndDate);
        formik.setFieldValue('policyStartDate', event.target.value);
        formik.setFieldValue('policyEndDate', newEndDate.toISOString().split('T')[0]); // Format date as YYYY-MM-DD
    };


    const handlePremiumStartDateChange = (event) => {
        const selectedDate = new Date(event.target.value);
       
        const newEndDate = new Date(selectedDate);
        newEndDate.setDate(newEndDate.getDate() - 1);
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        formik.setFieldValue('premiumStartDate', event.target.value);
        formik.setFieldValue('premiumEndDate', newEndDate.toISOString().split('T')[0]); // Format date as YYYY-MM-DD
    };

    const handlePolicyTypeChange = (event) => {
        const { value } = event.target;
        formik.setFieldValue('policyType', value)
        // Reset OPD value if policy type is changed to something other than "Health Insurance"
        if (value !== "Health Insurance") {
            formik.setFieldValue("OPD", "");
        }
    }




    const handleTextareaChange = (event) => {
        const inputValue = event.target.value;
        const words = inputValue.trim().split(/\s+/);
        if (words.length <= 90) {
            formik.handleChange(event); // Update the Formik state if within the word limit
        } else {
            // Truncate the value to 90 words if it exceeds the limit
            const truncatedValue = words.slice(0, 90).join(' ');
            formik.setFieldValue('policyRemarks', truncatedValue);
        }
    };

    const handleAddAdditionalInsured = () => {
        formik.setValues({
            ...formik.values,
            additionalInsureds: [
                ...formik.values.additionalInsureds,
                {
                    additionalInsuredPersonName: '',
                    additionalInsuredDateOfBirth: '',
                    additionalRelationshipToTheInsured: '',
                    additionalPhoneNumber: '',
                    additionalEmailAddress: '',
                    additionalInsuredPersonHabbit: '',
                    alcoholQuantity: '',
                    smokingQuantity: '',
                    tobaccoQuantity: '',
                    others: '',
                    bonus: "0",
                    coverageAmounts: '',
                }
            ]
        });
    };

    const handleRemoveAdditionalInsured = (index) => {
        const updatedInsureds = formik.values.additionalInsureds.filter((_, i) => i !== index);
        formik.setValues({
            ...formik.values,
            additionalInsureds: updatedInsureds
        });
    };


    // add policy api


    const addPolicy = async (values) => {
        const data = values
        const result = await apipost('policy/add', data)

        setUserAction(result)

        if (result && result.status === 201) {
            toast.success(result.data.message)
            formik.resetForm();
            handleClose();
        }
    }

    const handleCheckboxChange = (habit) => {
        const { values, setFieldValue } = formik;
        const currentHabits = values.habit || '';

        let updatedHabits;
        if (currentHabits.includes(habit)) {
            updatedHabits = currentHabits
                .split(',')
                .filter((item) => item.trim() !== habit)
                .join(',')
                .trim();
        } else {
            updatedHabits = currentHabits ? `${currentHabits},${habit}` : habit;
        }

        setFieldValue('habit', updatedHabits);
    };




    const updateHabits = (index, habit) => {
        const currentHabits = formik.values.additionalInsureds[index].additionalInsuredPersonHabbit
            .split(", ")
            .filter((item) => item !== "");
        const updatedHabits = currentHabits.includes(habit)
            ? currentHabits.filter((item) => item !== habit)
            : [...currentHabits, habit];
        return updatedHabits.join(", ");
    };



    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,

        onSubmit: async (values) => {

            try {
                await addPolicy(values);


            } catch (error) {
                console.error('Error submitting form:', error);

            }

        }
    });




    const fetchdata = async () => {
        const result = await apiget(`contact/list`)
        if (result && result.status === 200) {
            setContactList(result?.data?.result)
        }
    }

    const fetchPolicyType = async () => {
        const result = await apiget('policy-types/list');
        if (result && result.status === 200) {
            const sortedPolicyTypes = result.data.sort((a, b) => a.order - b.order);
            setPolicyTypes(sortedPolicyTypes);
        }
    };

    const fetchPolicyCompany = async () => {
        const result = await apiget('policy-company/list');

        if (result && result.status === 200) {
            const sortedPolicyCompany = result.data.sort((a, b) => a.order - b.order);
            setPolicyCompany(sortedPolicyCompany);
        }
    };




    const [showAdditionalOtherField, setShowAdditionalOtherField] = useState(
        new Array(formik.values.additionalInsureds.length).fill(false)
    );

    const additonalHandleRelationChange = (index, event) => {
        const selectedRelation = event.target.value;
        const updatedShowOtherField = [...showAdditionalOtherField];
        updatedShowOtherField[index] = selectedRelation === "Others";
        setShowAdditionalOtherField(updatedShowOtherField);
        formik.setFieldValue(
            `additionalInsureds[${index}].additionalRelationshipToTheInsured`,
            selectedRelation
        );
        if (selectedRelation !== "Others") {
            formik.setFieldValue(`additionalInsureds[${index}].others`, "");
        }
    };
    const fetchPaymentSource = async () => {
        const result = await apiget('payment-source/list');
        if (result && result.status === 200) {
            setPaymentSource(result?.data)
        }
    }
    const fetchPaymentMethod = async () => {
        const result = await apiget('payment-method/list');
        if (result && result.status === 200) {
            const filterPaymentMethod = result?.data?.result.filter(item => item.type.toLowerCase().includes('payment pay'));

            setPaymentMethod(filterPaymentMethod)
        }
    }

    const handleInputChange = (fieldName, value) => {
        // Ensure the value is not less than 1

        const parsedValue = parseInt(value, 10);
        // Specify radix 10 (decimal)
        if (Number.isNaN(parsedValue) || parsedValue < 0) {
            formik.setFieldValue(fieldName, ''); // Reset the field or handle as needed
        } else {
            formik.setFieldValue(fieldName, parsedValue.toString()); // Store as string
        }
    };

    const setCompanyNameAndSource = (company, source) => {
        formik.setFieldValue('policyCompanyName', company);
        formik.setFieldValue('policySource', source);
    }

    const fetchRelationShip = async () => {
        const result = await apiget('relationship/list');

        if (result && result.status === 200) {
            const sortedRelationShip = result?.data?.result.sort((a, b) => a.order - b.order);
            setRelationToTheInsured(sortedRelationShip)
        }
    }


    useEffect(() => {
        fetchdata();
        fetchRelationShip();
        fetchPolicyType();
        fetchPolicyCompany();
        fetchPaymentSource();
        fetchPaymentMethod();
    }, [])


    const [options, setOptions] = useState([]);
    const [selectedId, setSelectedId] = useState(null);



    useEffect(() => {

        const newOptions = (allPolicyData || []).map((policy) => ({
            label: `${policy.firstName} ${policy.lastName}`,
            aadhar: policy.aadhar || '',
            pan: policy.pan || '',
            dateOfBirth: policy.dateOfBirth || '',
            phoneNumber: policy.phoneNumber || '',
            emailAddress: policy.emailAddress || '',
        }));
        setOptions(newOptions);

    }, [allPolicyData]);


    const handleAutocompletePolicyHolder = async (event, newValue) => {

        if (newValue) {

            formik.setValues({
                ...formik.values,
                policyHolder: newValue?.label ? newValue?.label : '',

            });
        }
    };

    const handleAutocompleteChange = async (event, newValue) => {

        if (newValue) {

            formik.setValues({
                ...formik.values,
                insuredPersonName: newValue?.label ? newValue?.label : '',
                insuredPersonDateOfBirth: newValue?.dateOfBirth || '',
                relationshipToTheInsured: newValue?.relation || '',
                others: newValue?.others || '',
                insuredPersonNameAadhaar: newValue?.aadhar || '',
                insuredPersonNamePan: newValue?.pan || '',
                phoneNumber: newValue?.phoneNumber || '',
                emailAddress: newValue?.emailAddress || ''

            });
        } else {
            setSelectedId(null);
        }
    };

    const handleAutocompleteChangee = useCallback(
        async (index, newValue) => {
            // Update specific fields in the Formik state
            formik.setFieldValue(`additionalInsureds[${index}].additionalInsuredPersonName`, newValue?.label || '');
            formik.setFieldValue(`additionalInsureds[${index}].additionalEmailAddress`, newValue?.emailAddress || '');
            formik.setFieldValue(`additionalInsureds[${index}].additionalPhoneNumber`, newValue?.phoneNumber || '');
            formik.setFieldValue(`additionalInsureds[${index}].additionalRelationshipToTheInsured`, newValue?.relation || '');
            formik.setFieldValue(`additionalInsureds[${index}].others`, newValue?.others || '');
            formik.setFieldValue(`additionalInsureds[${index}].additionalInsuredDateOfBirth`, newValue?.dateOfBirth || '');
        },
        [formik] // Ensure formik is included in the dependencies array
    );






    return (
        <>
            <AddPaymentMethod open={openAddPaymentMethod} setOpen={setOpenPaymentMethod} setUserAction={setUserAction} fetchdata={fetchPaymentMethod} />
            <AddPolicyCompany open={openAddPolicyCompany} setOpen={setOpenAddPolicyCompany} fetchdata={fetchPolicyCompany} />
            <AddPolicyType open={openAddPolicyType} setOpen={setOpenAddPolicyType} fetchdata={fetchPolicyType} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">Add New </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography style={{ marginBottom: "15px" }} variant="h6">
                                Policy Details
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >


                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Policy type</FormLabel>
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="policyType"
                                                name="policyType"
                                                size='small'
                                                fullWidth
                                                value={formik.values.policyType}
                                                onChange={handlePolicyTypeChange}
                                                error={
                                                    formik.touched.policyType &&
                                                    Boolean(formik.errors.policyType)
                                                }
                                                helpertext={
                                                    formik.touched.policyType && formik.errors.policyType
                                                }
                                            >
                                                {policyTypes.map((option, index) => (

                                                    <MenuItem key={index} value={option.policyTypes}>
                                                        {option.policyTypes}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Button color="secondary"
                                                variant="contained"
                                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px", fontSize: "22px", marginLeft: "10px" }} onClick={handleOpenAddPolicyType} >
                                                <AddIcon />
                                            </Button>
                                        </Stack>
                                        <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.policyType && formik.errors.policyType}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Policy Company Name</FormLabel>
                                        <Stack direction={'row'} alignItems={'center'} >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="policyCompanyName"
                                                name="policyCompanyName"
                                                size='small'
                                                fullWidth
                                                value={formik.values.policyCompanyName}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.policyCompanyName &&
                                                    Boolean(formik.errors.policyCompanyName)
                                                }
                                                helpertext={
                                                    formik.touched.policyCompanyName && formik.errors.policyCompanyName
                                                }
                                            >
                                                {policyCompany.map((option, index) => (
                                                    <MenuItem key={index} value={`${option.policyCompany}`} onClick={() => setCompanyNameAndSource(option.policyCompany, option.sourceName)}  >
                                                        {`${option.policyCompany} (${option.sourceName})`}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px", fontSize: "22px", marginLeft: "10px" }}
                                                onClick={handleOpenAddPolicyCompany}
                                            >+</Button>
                                        </Stack>
                                        <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.policyType && formik.errors.policyType}</FormHelperText>
                                    </FormControl>

                                </Grid>
                                {formik.values.policyType && (formik.values.policyType.toLowerCase().includes('vehicle') || formik.values.policyType.toLowerCase().includes('auto')) && (


                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormLabel>Policy Holder</FormLabel>
                                        <Autocomplete
                                            freeSolo
                                            options={options}

                                            getOptionLabel={(option) => option.label || ''}
                                            onChange={handleAutocompletePolicyHolder}
                                            renderInput={(params) => (

                                                <TextField
                                                    {...params}
                                                    id="policyHolder"
                                                    name="policyHolder"
                                                    size='small'
                                                    fullWidth
                                                    value={formik.values.policyHolder}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.policyHolder &&
                                                        Boolean(formik.errors.policyHolder)
                                                    }
                                                    helperText={
                                                        formik.touched.policyHolder && formik.errors.policyHolder
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                               {formik.values.policyType.toLowerCase().includes('life') && <Grid item xs={12} sm={6}>
                                    <FormLabel>Premuim Start Date</FormLabel>
                                    <TextField
                                        id="premiumStartDate"
                                        type="date"
                                        fullWidth
                                        value={formik.values.premiumStartDate}
                                        onChange={handlePremiumStartDateChange}
                                        error={formik.touched.premiumStartDate && Boolean(formik.errors.premiumStartDate)}
                                        helperText={formik.touched.premiumStartDate && formik.errors.premiumStartDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>}
                               {formik.values.policyType.toLowerCase().includes('life') &&  <Grid item xs={12} sm={6}>
                                    <FormLabel>Premium End Date</FormLabel>
                                    <TextField
                                        id="premiumEndDate"
                                        type="date"
                                        fullWidth
                                        value={formik.values.premiumEndDate}
                                        onChange={formik.handleChange}
                                        error={formik.touched.premiumEndDate && Boolean(formik.errors.premiumEndDate)}
                                        helperText={formik.touched.premiumEndDate && formik.errors.premiumEndDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>}
                                {formik.values.policyType.toLowerCase().includes('life') && <Grid item xs={12} sm={12} md={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>Premium Tenure (in Years)</FormLabel>
                                        <Select
                                            labelId="policyDuration-label"
                                            id="premiumTenure"
                                            name="premiumTenure"
                                            size="small"
                                            fullWidth
                                            value={formik.values.premiumTenure}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.premiumTenure && Boolean(formik.errors.premiumTenure)
                                            }
                                        >
                                            {/* Generate numbers from 1 to 50 */}
                                            {Array.from({ length: 50 }, (_, i) => i + 1).map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText style={{ color: Palette.error.main }}>
                                            {formik.touched.premiumTenure && formik.errors.premiumTenure}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>}
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Policy Start Date</FormLabel>
                                    <TextField
                                        id="policyStartDate"
                                        type="date"
                                        fullWidth
                                        value={formik.values.policyStartDate}
                                        onChange={handleStartDateChange}
                                        error={formik.touched.policyStartDate && Boolean(formik.errors.policyStartDate)}
                                        helperText={formik.touched.policyStartDate && formik.errors.policyStartDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Policy End Date</FormLabel>
                                    <TextField
                                        id="policyEndDate"
                                        type="date"
                                        fullWidth
                                        value={formik.values.policyEndDate}
                                        onChange={formik.handleChange}
                                        error={formik.touched.policyEndDate && Boolean(formik.errors.policyEndDate)}
                                        helperText={formik.touched.policyEndDate && formik.errors.policyEndDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Policy status</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="policyStatus"
                                            name="policyStatus"
                                            size='small'
                                            fullWidth
                                            value={formik.values.policyStatus}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.policyStatus &&
                                                Boolean(formik.errors.policyStatus)
                                            }
                                            helpertext={
                                                formik.touched.policyStatus && formik.errors.policyStatus
                                            }
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="InActive">InActive </MenuItem>
                                            <MenuItem value="Canceled">Canceled </MenuItem>
                                        </Select>
                                        <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.policyStatus && formik.errors.policyStatus}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                {formik.values.policyType === "Health Insurance" ?

                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>OPD</FormLabel>
                                        <TextField
                                            type='text'
                                            id="OPD"
                                            name="OPD"
                                            size='small'
                                            fullWidth
                                            value={formik.values.OPD}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.OPD &&
                                                Boolean(formik.errors.OPD)
                                            }
                                            helpertext={
                                                formik.touched.OPD && formik.errors.OPD
                                            }
                                        />

                                    </Grid> : ''}
                                {formik.values.policyType.toLowerCase().includes('mediclaim')
                                    && !formik.values.policyType.toLowerCase().includes('top up')

                                    && <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>General OPD</FormLabel>
                                        <TextField
                                            type='text'
                                            id="generalOPD"
                                            name="generalOPD"
                                            size='small'
                                            fullWidth
                                            value={formik.values.generalOPD}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.generalOPD &&
                                                Boolean(formik.errors.generalOPD)
                                            }
                                            helpertext={
                                                formik.touched.generalOPD && formik.errors.generalOPD
                                            }
                                        />

                                    </Grid>}
                                {formik.values.policyType && !formik.values.policyType.toLowerCase().includes('vehicle') &&
                                    !formik.values.policyType.toLowerCase().includes('auto') &&
                                    !formik.values.policyType.toLowerCase().includes('accidental') ?

                                    !formik.values.policyType.toLowerCase().includes('top up') &&
                                    !formik.values.policyType.toLowerCase().includes('criti care') &&
                                    !formik.values.policyType.toLowerCase().includes('life') &&
                                    !formik.values.policyType.toLowerCase().includes('hospi') &&
                                    !formik.values.policyType.toLowerCase().includes('shop') &&
                                    !formik.values.policyType.toLowerCase().includes('home') &&
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>Dental OPD</FormLabel>
                                        <TextField
                                            id="dentalOPD"
                                            name="dentalOPD"
                                            size='small'
                                            fullWidth
                                            value={formik.values.dentalOPD}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.dentalOPD &&
                                                Boolean(formik.errors.dentalOPD)
                                            }
                                            helpertext={
                                                formik.touched.dentalOPD && formik.errors.dentalOPD
                                            }
                                        />
                                    </Grid> :
                                    formik.values.policyType.toLowerCase().includes('vehicle') && <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>Insurance Type</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="insuranceType"
                                            name="insuranceType"
                                            size="small"
                                            fullWidth
                                            value={formik.values.insuranceType}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.insuranceType &&
                                                Boolean(formik.errors.insuranceType)
                                            }
                                            helpertext={
                                                formik.touched.insuranceType && formik.errors.insuranceType
                                            }
                                        >

                                            <MenuItem value="comprehensive">Comprehensive</MenuItem>
                                            <MenuItem value="third party">Third Party</MenuItem>
                                            <MenuItem value="own damage">Own Damage</MenuItem>

                                        </Select>

                                    </Grid>
                                }
                            </Grid>
                            {formik.values.policyType && (!formik.values.policyType.toLowerCase().includes('accidental')) && (<>
                                <Typography
                                    style={{ marginBottom: "15px", marginTop: "15px" }}
                                    variant="h6"
                                >
                                    Policy Coverage Details
                                </Typography>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                                >
                                    {formik.values.policyType &&
                                        !formik.values.policyType.toLowerCase().includes('vehicle') &&
                                        !formik.values.policyType.toLowerCase().includes('auto') ?
                                        <>
                                            {!formik.values.policyType.toLowerCase().includes('life') && <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Coverage Amounts</FormLabel>
                                                <TextField
                                                    id="coverageAmounts"
                                                    name="coverageAmounts"
                                                    size='small'
                                                    type='number'
                                                    fullWidth
                                                    value={formik.values.coverageAmounts}
                                                    onChange={e => handleInputChange('coverageAmounts', e.target.value)}
                                                    error={
                                                        formik.touched.coverageAmounts &&
                                                        Boolean(formik.errors.coverageAmounts)
                                                    }
                                                    helpertext={
                                                        formik.touched.coverageAmounts && formik.errors.coverageAmounts
                                                    }
                                                />
                                            </Grid>}

                                            {/* life insurnace fields */}

                                            {formik.values.policyType.toLowerCase().includes('life') && <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Life Coverage Amount</FormLabel>
                                                <TextField
                                                    id="lifeCover"
                                                    name="lifeCover"
                                                    size='small'
                                                    type='number'
                                                    fullWidth
                                                    value={formik.values.lifeCover}
                                                    onChange={e => handleInputChange('lifeCover', e.target.value)}
                                                    error={
                                                        formik.touched.lifeCover &&
                                                        Boolean(formik.errors.lifeCover)
                                                    }
                                                    helpertext={
                                                        formik.touched.lifeCover && formik.errors.lifeCover
                                                    }
                                                />
                                            </Grid>}
                                            {formik.values.policyType.toLowerCase().includes('life') && <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Accidental Coverage Amount</FormLabel>
                                                <TextField
                                                    id="accidentalCover"
                                                    name="accidentalCover"
                                                    size='small'
                                                    type='number'
                                                    fullWidth
                                                    value={formik.values.accidentalCover}
                                                    onChange={e => handleInputChange('accidentalCover', e.target.value)}
                                                    error={
                                                        formik.touched.accidentalCover &&
                                                        Boolean(formik.errors.accidentalCover)
                                                    }
                                                    helpertext={
                                                        formik.touched.accidentalCover && formik.errors.accidentalCover
                                                    }
                                                />
                                            </Grid>}
                                            {formik.values.policyType.toLowerCase().includes('life') && <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Citi Care Coverage Amount</FormLabel>
                                                <TextField
                                                    id="criticareCover"
                                                    name="criticareCover"
                                                    size='small'
                                                    type='number'
                                                    fullWidth
                                                    value={formik.values.criticareCover}
                                                    onChange={e => handleInputChange('criticareCover', e.target.value)}
                                                    error={
                                                        formik.touched.criticareCover &&
                                                        Boolean(formik.errors.criticareCover)
                                                    }
                                                    helpertext={
                                                        formik.touched.criticareCover && formik.errors.criticareCover
                                                    }
                                                />
                                            </Grid>}
                                            {formik.values.policyType.toLowerCase().includes('life') && <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Mediclaim Coverage Amount</FormLabel>
                                                <TextField
                                                    id="mediclaimCover"
                                                    name="mediclaimCover"
                                                    size='small'
                                                    type='number'
                                                    fullWidth
                                                    value={formik.values.mediclaimCover}
                                                    onChange={e => handleInputChange('mediclaimCover', e.target.value)}
                                                    error={
                                                        formik.touched.mediclaimCover &&
                                                        Boolean(formik.errors.mediclaimCover)
                                                    }
                                                    helpertext={
                                                        formik.touched.mediclaimCover && formik.errors.mediclaimCover
                                                    }
                                                />
                                            </Grid>}

                                            {/* life insurenc ends */}


                                            {!(formik.values?.policyType?.toLowerCase().includes('mediclaim') ||
                                                formik.values?.policyType?.toLowerCase().includes('criti care') ||
                                                formik.values.policyType.toLowerCase().includes('life') ||
                                                formik.values.policyType.toLowerCase().includes('hospi') ||
                                                formik.values.policyType.toLowerCase().includes('shop') ||
                                                formik.values.policyType.toLowerCase().includes('home')
                                            ) ||
                                                formik.values?.policyType?.toLowerCase().includes('top up') ? (
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <FormLabel>Deductibles</FormLabel>
                                                    <TextField
                                                        id="deductibles"
                                                        name="deductibles"
                                                        size='small'
                                                        type='number'
                                                        fullWidth
                                                        value={formik.values.deductibles}
                                                        onChange={formik.handleChange}
                                                        error={
                                                            formik.touched.deductibles &&
                                                            Boolean(formik.errors.deductibles)
                                                        }
                                                        helpertext={
                                                            formik.touched.deductibles && formik.errors.deductibles
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}

                                            {!formik.values.policyType.toLowerCase().includes('life') &&
                                                !formik.values.policyType.toLowerCase().includes('hospi') &&
                                                !formik.values.policyType.toLowerCase().includes('shop') &&
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <FormLabel>Bonus</FormLabel>
                                                    <TextField
                                                        id="bonus"
                                                        name="bonus"
                                                        size='small'
                                                        type='number'
                                                        fullWidth
                                                        value={formik.values.bonus}
                                                        onChange={formik.handleChange}
                                                        error={
                                                            formik.touched.bonus &&
                                                            Boolean(formik.errors.bonus)
                                                        }
                                                        helpertext={
                                                            formik.touched.bonus && formik.errors.bonus
                                                        }
                                                    />
                                                </Grid>}
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>IDV Value</FormLabel>
                                                <TextField
                                                    id="idp"
                                                    name="idp"
                                                    size='small'
                                                    type='number'
                                                    fullWidth
                                                    value={formik.values.idp}
                                                    onChange={e => handleInputChange('idp', e.target.value)}
                                                    error={
                                                        formik.touched.idp &&
                                                        Boolean(formik.errors.idp)
                                                    }
                                                    helpertext={
                                                        formik.touched.idp && formik.errors.idp
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>NCB Value</FormLabel>
                                                <TextField
                                                    id="ncv"
                                                    name="ncv"
                                                    size='small'
                                                    type='number'
                                                    fullWidth
                                                    value={formik.values.ncv}
                                                    onChange={e => handleInputChange('ncv', e.target.value)}
                                                    error={
                                                        formik.touched.ncv &&
                                                        Boolean(formik.errors.ncv)
                                                    }
                                                    helpertext={
                                                        formik.touched.ncv && formik.errors.ncv
                                                    }
                                                />
                                            </Grid>
                                        </>

                                    }


                                </Grid>
                            </>)}
                            {formik.values.policyType && !formik.values.policyType.toLowerCase().includes('vehicle') && !formik.values.policyType.toLowerCase().includes('auto') && (<>
                                <Typography
                                    style={{ marginBottom: "15px", marginTop: "15px" }}
                                    variant="h6"
                                >
                                    Proposer Details
                                </Typography>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                                >
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>Proposer Name</FormLabel>
                                        <Autocomplete
                                            freeSolo
                                            options={options}

                                            getOptionLabel={(option) => option.label || ''}
                                            onChange={handleAutocompleteChange}
                                            renderInput={(params) => (

                                                <TextField
                                                    {...params}
                                                    id="insuredPersonName"
                                                    name="insuredPersonName"
                                                    size='small'
                                                    fullWidth
                                                    value={formik.values.insuredPersonName}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.insuredPersonName &&
                                                        Boolean(formik.errors.insuredPersonName)
                                                    }
                                                    helperText={
                                                        formik.touched.insuredPersonName && formik.errors.insuredPersonName
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>Date Of Birth</FormLabel>
                                        <TextField
                                            name='insuredPersonDateOfBirth'
                                            type='date'
                                            size='small'

                                            fullWidth
                                            value={dayjs(formik.values.insuredPersonDateOfBirth).format('YYYY-MM-DD')}
                                            onChange={formik.handleChange}
                                            error={formik.touched.insuredPersonDateOfBirth && Boolean(formik.errors.insuredPersonDateOfBirth)}
                                            helpertext={formik.touched.insuredPersonDateOfBirth && formik.errors.insuredPersonDateOfBirth}
                                            placeholder='dd/mm/yyyy'
                                        />
                                    </Grid>
                                    {!formik.values.policyType.toLowerCase().includes('home') &&
                                        !formik.values.policyType.toLowerCase().includes('shop') &&
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormLabel>Relationship to the insured</FormLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="relationshipToTheInsured"
                                                name="relationshipToTheInsured"
                                                size="small"
                                                fullWidth
                                                value={formik.values.relationshipToTheInsured}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.relationshipToTheInsured &&
                                                    Boolean(formik.errors.relationshipToTheInsured)
                                                }
                                                helpertext={
                                                    formik.touched.relationshipToTheInsured && formik.errors.relationshipToTheInsured
                                                }
                                            >
                                                {relationToTheInsured && relationToTheInsured.map((item, index) => (
                                                    <MenuItem key={index} value={item.relation}>
                                                        {item.relation}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.values.relationshipToTheInsured.toLowerCase().includes('others') &&

                                                <FormControl fullWidth style={{ marginTop: "20px" }}>
                                                    <TextField
                                                        id="others"
                                                        name="others"
                                                        label="Others"
                                                        value={formik.values.others}
                                                        onChange={formik.handleChange}
                                                        variant="outlined"
                                                        size="small"
                                                        error={formik.touched.others && Boolean(formik.errors.others)}
                                                        helperText={formik.touched.others && formik.errors.others}
                                                    />
                                                </FormControl>
                                            }
                                        </Grid>}
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>Aadhaar</FormLabel>
                                        <TextField
                                            id="insuredPersonNameAadhaar"
                                            name='insuredPersonNameAadhaar'
                                            size='small'
                                            type='number'
                                            fullWidth
                                            value={formik.values.insuredPersonNameAadhaar}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*$/.test(value) && value.length <= 12) {
                                                    formik.setFieldValue('insuredPersonNameAadhaar', value);
                                                }
                                            }}
                                            error={Boolean(formik.errors.insuredPersonNameAadhaar && formik.touched.insuredPersonNameAadhaar)}
                                            helpertext={formik.touched.insuredPersonNameAadhaar && formik.errors.insuredPersonNameAadhaar}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>PAN Card</FormLabel>
                                        <TextField
                                            id="insuredPersonNamePan"
                                            name='insuredPersonNamePan'
                                            size='small'
                                            type='text'
                                            fullWidth
                                            inputProps={{ maxLength: 10 }}
                                            value={formik.values.insuredPersonNamePan}
                                            onChange={(e) => {
                                                const value = e.target.value.toUpperCase();
                                                if (value.length <= 10 && /^[A-Z]{0,5}[0-9]{0,4}[A-Z]{0,1}$/.test(value)) {
                                                    formik.setFieldValue('insuredPersonNamePan', value);
                                                }
                                            }}
                                            error={Boolean(formik.errors.insuredPersonNamePan && formik.touched.insuredPersonNamePan)}
                                            helpertext={formik.touched.insuredPersonNamePan && formik.errors.insuredPersonNamePan}
                                        />
                                    </Grid>


                                </Grid>
                                <Typography
                                    style={{ marginBottom: "15px", marginTop: "15px" }}
                                    variant="h6"
                                >
                                    Proposer Contact information
                                </Typography>
                                <Grid
                                    container
                                    rowSpacing={3}
                                    columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                                >
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>Phone Number</FormLabel>
                                        <TextField
                                            id=""
                                            name="phoneNumber"
                                            type="number"
                                            size='small'
                                            fullWidth
                                            value={formik.values.phoneNumber}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                // Allow only numeric values and limit to 10 digits
                                                if (/^\d{0,10}$/.test(value)) {
                                                    formik.setFieldValue('phoneNumber', value);
                                                }
                                            }}
                                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                            helpertext={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormLabel>Email</FormLabel>
                                        <TextField
                                            id="emailAddress"
                                            name="emailAddress"
                                            type="email"
                                            size='small'
                                            fullWidth
                                            value={formik.values.emailAddress}
                                            onChange={formik.handleChange}
                                            error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                            helpertext={formik.touched.emailAddress && formik.errors.emailAddress}
                                        />
                                    </Grid>
                                    {(formik.values.policyType.toLowerCase().includes('shop') ||
                                        formik.values.policyType.toLowerCase().includes('home')) &&
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormLabel>Risk Location</FormLabel>
                                            <TextField
                                                id="riskLocation"
                                                name="riskLocation"
                                                type="text"
                                                size='small'
                                                multiline
                                                rows={5}
                                                fullWidth
                                                value={formik.values.riskLocation}
                                                onChange={formik.handleChange}
                                                error={formik.touched.riskLocation && Boolean(formik.errors.riskLocation)}
                                                helpertext={formik.touched.riskLocation && formik.errors.riskLocation}
                                            />
                                        </Grid>}


                                </Grid>
                                {/* <Typography
                                    style={{ marginBottom: "15px", marginTop: "15px" }}
                                    variant="h6"
                                >
                                    Habit
                                </Typography>
                                <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 5, md: 4 }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <Checkbox
                                                    id="alcohol"
                                                    name="habit"
                                                    checked={formik.values.habit.split(',').includes("Alcohol")}
                                                    onChange={() => handleCheckboxChange("Alcohol")}
                                                />
                                                <FormLabel>Alcohol</FormLabel>
                                                {formik.values.habit.split(',').includes("Alcohol") && (
                                                    <TextField
                                                        id="alcoholQuantity"
                                                        name="alcoholQuantity"
                                                        label="Quantity"
                                                        fullWidth
                                                        value={formik.values.alcoholQuantity}
                                                        onChange={formik.handleChange}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <Checkbox
                                                    id="smoking"
                                                    name="habit"
                                                    checked={formik.values.habit.split(',').includes("Smoking")}
                                                    onChange={() => handleCheckboxChange("Smoking")}
                                                />
                                                <FormLabel>Smoking</FormLabel>
                                                {formik.values.habit.split(',').includes("Smoking") && (
                                                    <TextField
                                                        id="smokingQuantity"
                                                        name="smokingQuantity"
                                                        label="Quantity"
                                                        fullWidth
                                                        value={formik.values.smokingQuantity}
                                                        onChange={formik.handleChange}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <Checkbox
                                                    id="tobacco"
                                                    name="habit"
                                                    checked={formik.values.habit.split(',').includes("Tobacco")}
                                                    onChange={() => handleCheckboxChange("Tobacco")}
                                                />
                                                <FormLabel>Tobacco</FormLabel>
                                                {formik.values.habit.split(',').includes("Tobacco") && (
                                                    <TextField
                                                        id="tobaccoQuantity"
                                                        name="tobaccoQuantity"
                                                        label="Quantity"
                                                        fullWidth
                                                        value={formik.values.tobaccoQuantity}
                                                        onChange={formik.handleChange}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Typography
                                    // style={{ marginBottom: "15px", marginTop: "15px" }}
                                    mb={2}
                                    mt={5}
                                    variant="h6"
                                >
                                    Insured Details
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px", fontSize: "22px", marginLeft: "10px" }}
                                        onClick={handleAddAdditionalInsured}
                                    >+</Button>
                                </Typography>
                                {formik.values.additionalInsureds && formik.values.additionalInsureds.map((insured, index) => (
                                    <div key={index}>
                                        <Typography style={{ marginBottom: "15px", marginTop: "15px" }} variant="h6">
                                            Insured Person {index + 1}
                                        </Typography>
                                        <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 5, md: 4 }}>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Person Name</FormLabel>
                                                <Autocomplete
                                                    freeSolo
                                                    options={options}
                                                    getOptionLabel={(option) => option.label || ''}
                                                    value={options.find(option => option.label === insured.additionalInsuredPersonName) || { label: insured.additionalInsuredPersonName }}
                                                    onChange={(event, newValue) => handleAutocompleteChangee(index, newValue)}
                                                    onInputChange={(event, newInputValue) => {
                                                        formik.setFieldValue(`additionalInsureds[${index}].additionalInsuredPersonName`, newInputValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            id={`additionalInsuredPersonName-${index}`}
                                                            name={`additionalInsureds[${index}].additionalInsuredPersonName`}
                                                            size="small"
                                                            fullWidth
                                                            error={
                                                                formik.touched.additionalInsureds?.[index]?.additionalInsuredPersonName &&
                                                                Boolean(formik.errors.additionalInsureds?.[index]?.additionalInsuredPersonName)
                                                            }
                                                            helperText={
                                                                formik.touched.additionalInsureds?.[index]?.additionalInsuredPersonName &&
                                                                formik.errors.additionalInsureds?.[index]?.additionalInsuredPersonName
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>


                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Date Of Birth</FormLabel>
                                                <TextField
                                                    id={`additionalInsuredDateOfBirth-${index}`}
                                                    name={`additionalInsureds[${index}].additionalInsuredDateOfBirth`}
                                                    type="date"
                                                    size="small"

                                                    fullWidth
                                                    value={dayjs(insured.additionalInsuredDateOfBirth).format('YYYY-MM-DD')}
                                                    onChange={formik.handleChange}
                                                    placeholder='dd/mm/yyyy'
                                                />
                                            </Grid>



                                            <Grid item xs={12} sm={6} md={6} key={index}>
                                                <FormLabel>Relationship to the insured</FormLabel>
                                                <Select
                                                    id={`additionalRelationshipToTheInsured-${index}`}
                                                    name={`additionalInsureds[${index}].additionalRelationshipToTheInsured`}
                                                    size="small"
                                                    fullWidth
                                                    value={insured.additionalRelationshipToTheInsured}
                                                    onChange={(event) => additonalHandleRelationChange(index, event)}
                                                >
                                                    {relationToTheInsured && relationToTheInsured.map((item, index) => (
                                                        <MenuItem key={index} value={item.relation}>
                                                            {item.relation}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            {showAdditionalOtherField[index] &&
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextField
                                                        id={`others-${index}`}
                                                        name={`additionalInsureds[${index}].others`}
                                                        label="Others"
                                                        type='text'
                                                        fullWidth
                                                        value={formik.values.additionalInsureds[index].others}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                            }



                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Phone Number</FormLabel>
                                                <TextField
                                                    id={`additionalPhoneNumber-${index}`}
                                                    name={`additionalInsureds[${index}].additionalPhoneNumber`}
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    value={insured.additionalPhoneNumber}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        // Allow only numeric values and limit to 10 digits
                                                        if (/^\d{0,10}$/.test(value)) {
                                                            formik.setFieldValue(`additionalInsureds[${index}].additionalPhoneNumber`, value);
                                                        }
                                                    }}
                                                    error={
                                                        formik.touched.additionalInsureds?.[index]?.additionalPhoneNumber &&
                                                        Boolean(formik.errors.additionalInsureds?.[index]?.additionalPhoneNumber)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormLabel>Email</FormLabel>
                                                <TextField
                                                    id={`additionalEmailAddress-${index}`}
                                                    name={`additionalInsureds[${index}].additionalEmailAddress`}
                                                    type="email"
                                                    size="small"
                                                    fullWidth
                                                    value={insured.additionalEmailAddress}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            {formik.values.policyType && formik.values.policyType.toLowerCase().includes('accidental') && (<>

                                                <Grid item xs={11} sm={5} md={5}>
                                                    <FormLabel>Coverage Amount</FormLabel>
                                                    <TextField
                                                        id={`coverageAmounts-${index}`}
                                                        name={`additionalInsureds[${index}].coverageAmounts`}
                                                        type="email"
                                                        size="small"
                                                        fullWidth
                                                        value={insured.coverageAmounts}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={11} sm={5} md={5}>
                                                    <FormLabel>Bonus</FormLabel>
                                                    <TextField
                                                        id={`bonus-${index}`}
                                                        name={`additionalInsureds[${index}].bonus`}
                                                        type="email"
                                                        size="small"
                                                        fullWidth
                                                        value={insured.bonus}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                            </>)}
                                            <Grid item xs={12} sm={12} md={12}>
                                                <FormLabel>Habit</FormLabel>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Checkbox
                                                            id={`alcohol-${index}`}
                                                            name={`additionalInsureds[${index}].additionalInsuredPersonHabbit`}
                                                            checked={formik.values.additionalInsureds[index].additionalInsuredPersonHabbit.includes("Alcohol")}
                                                            onChange={() => {
                                                                const habit = "Alcohol";
                                                                const updatedHabits = updateHabits(index, habit);
                                                                formik.setFieldValue(`additionalInsureds[${index}].additionalInsuredPersonHabbit`, updatedHabits);
                                                                handleCheckboxChange(updatedHabits, habit, index); // Call function to handle quantity field visibility
                                                            }}
                                                        />
                                                        <FormLabel>Alcohol</FormLabel>
                                                        {formik.values.additionalInsureds[index].additionalInsuredPersonHabbit.includes("Alcohol") && (
                                                            <TextField
                                                                id={`alcoholQuantity-${index}`}
                                                                name={`additionalInsureds[${index}].alcoholQuantity`}
                                                                label="Quantity"
                                                                fullWidth
                                                                value={formik.values.additionalInsureds[index].alcoholQuantity}
                                                                onChange={formik.handleChange}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Checkbox
                                                            id={`smoking-${index}`}
                                                            name={`additionalInsureds[${index}].additionalInsuredPersonHabbit`}
                                                            checked={formik.values.additionalInsureds[index].additionalInsuredPersonHabbit.includes("Smoking")}
                                                            onChange={() => {
                                                                const habit = "Smoking";
                                                                const updatedHabits = updateHabits(index, habit);
                                                                formik.setFieldValue(`additionalInsureds[${index}].additionalInsuredPersonHabbit`, updatedHabits);
                                                                handleCheckboxChange(updatedHabits, habit, index); // Call function to handle quantity field visibility
                                                            }}
                                                        />
                                                        <FormLabel>Smoking</FormLabel>
                                                        {formik.values.additionalInsureds[index].additionalInsuredPersonHabbit.includes("Smoking") && (
                                                            <TextField
                                                                id={`smokingQuantity-${index}`}
                                                                name={`additionalInsureds[${index}].smokingQuantity`}
                                                                label="Quantity"
                                                                fullWidth
                                                                value={formik.values.additionalInsureds[index].smokingQuantity}
                                                                onChange={formik.handleChange}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Checkbox
                                                            id={`tobacco-${index}`}
                                                            name={`additionalInsureds[${index}].additionalInsuredPersonHabbit`}
                                                            checked={formik.values.additionalInsureds[index].additionalInsuredPersonHabbit.includes("Tobacco")}
                                                            onChange={() => {
                                                                const habit = "Tobacco";
                                                                const updatedHabits = updateHabits(index, habit);
                                                                formik.setFieldValue(`additionalInsureds[${index}].additionalInsuredPersonHabbit`, updatedHabits);
                                                                handleCheckboxChange(updatedHabits, habit, index); // Call function to handle quantity field visibility
                                                            }}
                                                        />
                                                        <FormLabel>Tobacco</FormLabel>
                                                        {formik.values.additionalInsureds[index].additionalInsuredPersonHabbit.includes("Tobacco") && (
                                                            <TextField
                                                                id={`tobaccoQuantity-${index}`}
                                                                name={`additionalInsureds[${index}].tobaccoQuantity`}
                                                                label="Quantity"
                                                                fullWidth
                                                                value={formik.values.additionalInsureds[index].tobaccoQuantity}
                                                                onChange={formik.handleChange}
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12}>
                                                <Button
                                                    color="error"
                                                    variant="contained"
                                                    onClick={() => handleRemoveAdditionalInsured(index)}
                                                    sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px", fontSize: "25px" }}
                                                >
                                                    -
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}
                            </>)}

                            {formik.values.policyType && (formik.values.policyType.toLowerCase().includes('vehicle') || formik.values.policyType.toLowerCase().includes('auto')) && (
                                <>
                                    <Typography style={{ marginBottom: "15px", marginTop: "15px" }} variant="h6">
                                        Vehicle Insurance Details
                                    </Typography>
                                    <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 5, md: 4 }}>


                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormLabel>Vehicle Type</FormLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="vehicleType"
                                                name="vehicleType"
                                                size="small"
                                                fullWidth
                                                value={formik.values.vehicleType}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.vehicleType &&
                                                    Boolean(formik.errors.vehicleType)
                                                }
                                                helpertext={
                                                    formik.touched.vehicleType && formik.errors.vehicleType
                                                }
                                            >

                                                <MenuItem value="Two Wheeler">Two Wheeler</MenuItem>
                                                <MenuItem value="Three Wheeler">Three Wheeler</MenuItem>
                                                <MenuItem value="Four Wheeler">Four Wheeler</MenuItem>

                                            </Select>

                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormLabel>Year of Manufacture</FormLabel>
                                            <TextField
                                                name='yearOfManufacture'
                                                size='small'
                                                type='number'

                                                fullWidth
                                                value={formik.values.yearOfManufacture}
                                                onChange={e => handleInputChange('yearOfManufacture', e.target.value)}
                                                error={formik.touched.yearOfManufacture && Boolean(formik.errors.yearOfManufacture)}

                                                helperText={formik.touched.yearOfManufacture && formik.errors.yearOfManufacture}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormLabel>Vehicle Number</FormLabel>
                                            <TextField
                                                name='vehicleNumber'
                                                size='small'
                                                type='text'
                                                fullWidth
                                                value={formik.values.vehicleNumber}
                                                onChange={formik.handleChange}
                                                error={formik.touched.vehicleNumber && Boolean(formik.errors.vehicleNumber)}
                                                helperText={formik.touched.vehicleNumber && formik.errors.vehicleNumber}
                                                inputProps={{ style: { textTransform: 'uppercase' } }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormLabel>Vehicle Make</FormLabel>
                                            <TextField
                                                name='vehicleMake'
                                                size='small'
                                                fullWidth
                                                value={formik.values.vehicleMake}
                                                onChange={formik.handleChange}
                                                error={formik.touched.vehicleMake && Boolean(formik.errors.vehicleMake)}
                                                helperText={formik.touched.vehicleMake && formik.errors.vehicleMake}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormLabel>Vehicle Model</FormLabel>
                                            <TextField
                                                name='vehicleModel'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                value={formik.values.vehicleModel}
                                                onChange={formik.handleChange}
                                                error={formik.touched.vehicleModel && Boolean(formik.errors.vehicleModel)}
                                                helperText={formik.touched.vehicleModel && formik.errors.vehicleModel}
                                            />
                                        </Grid>



                                    </Grid>
                                </>
                            )}


                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Policy Premiums and Payments
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Premium Amount</FormLabel>
                                    <TextField
                                        id="premiumAmount"
                                        name="premiumAmount"
                                        type="number"
                                        size='small'
                                        fullWidth
                                        value={formik.values.premiumAmount}
                                        onChange={e => handleInputChange('premiumAmount', e.target.value)}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Payment Method</FormLabel>
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="paymentMethod"
                                                name="paymentMethod"
                                                size='small'
                                                fullWidth
                                                value={formik.values.paymentMethod}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.paymentMethod &&
                                                    Boolean(formik.errors.paymentMethod)
                                                }
                                                helpertext={
                                                    formik.touched.paymentMethod && formik.errors.paymentMethod
                                                }
                                            >
                                                {paymentMethod.map((option, index) => (

                                                    <MenuItem key={index} value={option.paymentMethod ?? ''}>
                                                        {option.paymentMethod}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Button color="secondary"
                                                variant="contained"
                                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px", fontSize: "22px", marginLeft: "10px" }} onClick={handleOpenAddPaymentMethod} >
                                                <AddIcon />
                                            </Button>
                                        </Stack>
                                        <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.paymentMethod && formik.errors.paymentMethod}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Payment Period</FormLabel>
                                    <TextField
                                        id="paymentPeriod"
                                        name="paymentPeriod"
                                        type="number"
                                        size='small'
                                        fullWidth
                                        value={formik.values.paymentPeriod}
                                        onChange={e => handleInputChange('paymentPeriod', e.target.value)}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Premium Payments</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="FrequencyOfPremiumPayments"
                                            name="FrequencyOfPremiumPayments"
                                            label=""
                                            size='small'
                                            value={formik.values.FrequencyOfPremiumPayments}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value="Monthly">Monthly</MenuItem>
                                            <MenuItem value="Annually">Annually </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Payment Source</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="paymentSource"
                                            name="paymentSource"
                                            label=""
                                            size='small'
                                            value={formik.values.paymentSource}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value="client">Client</MenuItem>
                                            {paymentSource && paymentSource.length > 0 && paymentSource.map((item, index) => (
                                                <MenuItem key={index} value={item.paymentSource}>{item.paymentSource}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Claim Status</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="claimStatus"
                                            name="claimStatus"
                                            label=""
                                            size='small'
                                            value={formik.values.claimStatus}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value="Yes">Yes</MenuItem>
                                            <MenuItem value="No">No </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>

                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Policy Remarks
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={12} md={12}>

                                    <textarea
                                        id="policyRemarks"
                                        name="policyRemarks"
                                        aria-label="policy remarks"
                                        placeholder="Enter policy remarks (maximum 90 words)..."
                                        value={formik.values.policyRemarks}
                                        onChange={handleTextareaChange}
                                        rows={9}
                                        style={{ resize: "none", width: "100%" }}

                                    />

                                </Grid>

                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        formik.handleSubmit()
                        console.log('submit')
                    }} variant='contained' color='primary'>Save</Button>
                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancel</Button>
                </DialogActions>
            </Dialog>
        </ >
    );
}

export default Add