/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TextField, MenuItem, Select, FormLabel, FormControl } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiput, apiget } from "../../../service/api";

const Edit = ({ open, handleClose, setUserAction, id, singleArticleData }) => {
    const ArticleData = singleArticleData;


    const initialValues = {
        title: ArticleData?.title || "",
        content: ArticleData?.content || "",
        page: ArticleData?.page || "",
        createdBy: ArticleData?.createdBy || "",
    };

    const validationSchema = yup.object({
        title: yup.string().required("title is required"),
        content: yup.string().required("content is required"),
        page: yup.string().required("page is required"),

    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values);
        },
    });




    const fileUpload = async (values) => {
        try {
            const data = new FormData()
            data.append("title", values.title)
            data.append("content", values.content)
            data.append("page", values.page)
            data.append("createdBy", values.createdBy)


            const result = await apiput(`service-plans/edit/${id}`, values);
            setUserAction(result);

            if (result && result.status === 200) {
                formik.resetForm();
                handleClose();
                // toast.success(result.data.message)
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit Data</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data">
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Title</FormLabel>
                                <TextField
                                    id="title"
                                    name="title"
                                    size="small"
                                    fullWidth
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.title && Boolean(formik.errors.title)
                                    }
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <FormLabel>Page</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="page"
                                        name="page"
                                        label=""
                                        size='small'
                                        value={formik.values.page}
                                        onChange={formik.handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="Health">Health</MenuItem>
                                        <MenuItem value="Vehicle">Vehicle </MenuItem>
                                        <MenuItem value="Property">Property </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Content</FormLabel>
                                <TextField
                                    id="content"
                                    name="content"
                                    size="small"
                                    rows={5}
                                    multiline
                                    fullWidth
                                    value={formik.values.content}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.content && Boolean(formik.errors.content)
                                    }
                                    helperText={formik.touched.content && formik.errors.content}
                                />
                            </Grid>

                        </Grid>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default Edit;
