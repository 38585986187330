import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import Navbar from '../Navbar/Navbar'
import Bharti from '../../../Images/Home/bharti.png'
import Max from '../../../Images/Home/max.png'
import kotak from '../../../Images/Home/kotak.png'
import Bajaj from '../../../Images/Home/bajaj.png'
import TrustedAbout from '../../../Images/Home/trusted-about.png'
import SaveMoney from '../../../Images/Home/Save-your-money.png'
import MobileBanking from '../../../Images/Home/Mobile-banking.png'
import Insurance from '../../../Images/Home/Protective-Insurance.png'
import TrustedPart from '../../../Images/Home/Trusted-partners.png'
import InsuranceSlider from './InsuranceSlider/InsuranceSlider'
import Consultation from './Consultaion/Consultation'
import EnquiryForm from './EnquiryForm/EnquiryForm'
import Testimonials from './Testimonials/Testimonials'
import HomeVlog from './HomeVlog/HomeVlog'
import Footer from '../Footer/Footer'
import { apiget } from '../../../service/api'
import { constant } from '../../../constant'
import ListedCompanyDialog from './ListedCompanyDialog'
import OurTeam from './OurTeam/OurTeam'

const Home = () => {

    const [aboutData, setAboutData] = useState([]);
    const [listedCompanyData, setListedCompanyData] = useState([]);
    const [open, setOpen] = useState(false);

    const fetchAboutData = async () => {
        const result = await apiget(`about/list`);
        if (result && result.status === 200) {
            setAboutData(result?.data?.result)
        }
    }
    const fetchListedCompany = async () => {
        const result = await apiget(`listed_company/list`);
        if (result && result.status === 200) {
            setListedCompanyData(result?.data?.result)
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        fetchAboutData();
        fetchListedCompany();
    }, [])
    return (
        <>
            <div className='main-home-container'>

                <div className="home_background">
                    <div className="container">
                        <Navbar />
                        <div className="home_text">
                            <h3>Protecting What</h3>
                            <h4><span>Matters</span> Most</h4>
                            <p>Get the Coverage You Need with Our Reliable Insurance Solutions. Rest easy knowing we've got you
                                covered for life's unexpected twists and turns.</p>
                        </div>
                        <div className="home_request_quote">
                            <div className="home_page_request">
                                <Link to="/login">Request a Quote</Link>
                            </div>
                            <div className="home_page_call">
                                <h6>+91 9314234442</h6>
                                <p>Free Consultation</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="trusted_companies">
                    <div className="container">
                        <div className="trusted_companies_d_f">
                            <div className="group_image">
                                <img src={TrustedAbout} alt='' />
                            </div>
                            <div className="group_logo">
                                <h2>Trusted by More than <span>26+</span> companies</h2>
                                <div className='group_logo_wrapper'>

                                    {
                                        listedCompanyData && listedCompanyData.length > 0 && listedCompanyData.slice(0, 4).map((item, index) => (
                                            <img key={index} src={`${constant.baseUrl}uploads/listedCompany/${item.file}`} alt='' />
                                        ))
                                    }
                                    <Link className='more_listed_company' onClick={handleClickOpen} to="">More...</Link>
                                    {open && <ListedCompanyDialog open={open} setOpen={setOpen} data={listedCompanyData} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home_about">
                    <div className="container">
                        <h6>ABOUT US</h6>
                        <h3>Best insurance for change better future life</h3>
                        <p>We understand that securing the right insurance coverage is not just about protecting your present but
                            also about shaping a better future. Our company is committed to empowering individuals and families to
                            make positive changes in their lives by providing comprehensive insurance solutions that offer peace of
                            mind and financial security. Whether it's safeguarding your home, car, health, or loved ones, we offer
                            customizable policies designed to meet your unique needs and aspirations. </p>
                    </div>
                </div>

                <div className="home_save_your_money">
                    <div className="container">
                        <div className="home_save_your_money_d_f">
                            <div className='row'>
                                {
                                    aboutData && aboutData.length > 0 && aboutData.map((item) => (
                                        <div className='col-md-3 pb-3'>
                                            <div className="home_save_your_money_text">
                                                <img src={`${constant.baseUrl}uploads/aboutUs/${item.file}`} alt='' />
                                                <h5>{item.title}</h5>
                                                <p>{item.content.length > 75 ? `${item.content.substr(0, 75)}...` : item.content}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <InsuranceSlider />
                <Consultation />
                <EnquiryForm />
                <OurTeam />
                <Testimonials />
                <HomeVlog />
                <Footer />
            </div>

        </>
    )
}

export default Home